<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_negativeBalance'])"></div>
        {{$t('fbm_negativeBalance.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
       <div class="custom-row">
         <div class="custom-col ">

           <div class="order-create__section-label section-label"
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
             <div class="admin-edit" @click="editTranslate(['fbm_negativeBalance1', 'fbm_negativeBalance2', 'fbm_negativeBalance3'])"></div>
             {{$t('fbm_negativeBalance.localization_value.value')}}
             {{$t('fbm_negativeBalance1.localization_value.value')}}
             ${{getSiteUserNegativeBalanceLimit}}
             {{$t('fbm_negativeBalance2.localization_value.value')}}
             {{getSiteUserNegativeBalanceDays}}
             {{$t('fbm_negativeBalance3.localization_value.value')}}
           </div>
         </div>
       </div>
      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel'])"></div>
        <span
            class="site-link site-link--alt buy-label__btn-i mr-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>
      </div>
    </template>
  </modal>

</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'

  export default {
    name: "NegativeBalancePopup",
    components: {
      Modal,
    },

  }

</script>

<style scoped>

</style>
