<template>
  <div class="user-card">


    <div class="user-card__img">
      <div class="user-card__img-i">
        <img v-if="user.userAvatar.id"
             :src="`data:${user.userAvatar.mimeType};base64,${user.userAvatar.base64}`" alt="img">
        <img v-else style="background: #ce9e64" src="/img/profile-group/profile-photo-default.svg" alt="img">
      </div>
    </div>
    <div class="user-card__content">
      <div class="user-card__head">
        <div class="user-card__head-info">
          <div class="custom-row">
            <div class="custom-col custom-col--50 mb-0">
              <div class="user-card__head-name cursor-pointer"
                   v-if="Object.keys(FBM.data.User.user).length > 0"
                   @click="userStatistic = true"
              >
                <ValueHelper
                    :value="FBM.data.User.user"
                    :deep="'contacts.0.user_full_name'"
                />
              </div>
            </div>
            <div class="custom-col custom-col--50 mb-0">
              <div class="user-card__head-name text-right pr-4">
                <span style="margin-right: -3px;">#</span><ValueHelper
                    :value="FBM.data.Order.data.id"
                />
              </div>
              <div v-if="FBM.data.sendFromRegion" class="user-card__head-label text-right font-weight-bold pr-4">
                <span class="brick-btn " style="font-size: 18px;"
                      v-bind:class="{
                        'label-circle--green': FBM.data.sendFromRegion === 'MN',
                        'label-circle--violet': FBM.data.sendFromRegion === 'FL',
                        'label-circle--orange': FBM.data.sendFromRegion === 'CA',
                      }"
                >{{FBM.data.sendFromRegion}}</span>
              </div>
            </div>
          </div>

          <div class="user-card__head-row d-flex flex-column">
            <div class="amount-row light mb-1">
              Balance:
              <span class="color-red important font-weight-bold m-0">
                $ {{FBM.data.User.user.balance}}
              </span>
            </div>
            <div class="amount-row light" v-if="getPayoneerBalance">
              Payoneer Balance:
              <span class="color-red important font-weight-bold m-0">
                $ {{getPayoneerBalance}}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="site-link brown text-decoration-underline mt-1">
        <div v-if="isAdmin && FBM.data.Order.data.historyOfChanged"
             class="mb-3"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
          <div class="site-link text-decoration-underline btn-style brown"
               @click="historyOfChangesFunc('fbm', FBM.data.Order.data.historyOfChanged)">
            {{$t('common_CheckHistory.localization_value.value')}}
          </div>
        </div>
      </div>

      <div class="user-card__body">
        <div class="block-table">
          <div class="block-table__row mb-0">
            <div class="block-table__col mb-3">
              <TitleHorizontal
                  class="black mb-0"
                  :value="$t('fbm_SendFromInformation.localization_value.value')"
                  :rightBtn="true"
                  :rightBtnType="'edit'"
                  :rightBtnText="'Edit'"
                  @rightBtnClick="isSendFromDataEditPopup = true"
              />
            </div>
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                {{$t('common_name.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                    :value="FBM.data.sendFrom"
                    :deep="'first_name'"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                {{$t('common_surName.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                    :value="FBM.data.sendFrom"
                    :deep="'last_name'"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                {{$t('profile_companyName.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                    :value="FBM.data.sendFrom"
                    :deep="'company_name'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

<!--      <MainButton-->
<!--          :value="$t('fbm_SendFromInformation.localization_value.value')"-->
<!--          @click.native="isSendFromDataEditPopup = true"-->
<!--      />-->

      <SendFromDataEditPopup
          v-if="isSendFromDataEditPopup"
          :FBM="FBM"
          @close="isSendFromDataEditPopup = false"
      />

      <div class="user-card__body">
        <div class="block-table">
          <div class="block-table__row mb-0">
            <div class="block-table__col mb-3">
              <TitleHorizontal
                  class="black mb-0"
                  :value="'Customer Information'"
                  :rightBtn="true"
                  :rightBtnType="'edit'"
                  :rightBtnText="'Edit'"
                  @rightBtnClick="changeInformationDataPopup()"
              />
            </div>
            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_ContactName'])"></div>
                {{$t('fbm_ContactName.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                        :value="personal.information.personalName"
                />
              </div>
            </div>
            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_CompanyName'])"></div>
                {{$t('fbm_CompanyName.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                        :value="personal.information.personalCompany"
                />
              </div>
            </div>
            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_Email'])"></div>
                {{$t('fbm_Email.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                        :value="personal.information.personalEmail"
                />
              </div>
            </div>

            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_PhoneNumber'])"></div>
                {{$t('fbm_PhoneNumber.localization_value.value')}}
              </div>
              <div class="block-table__content word-break">
                <ValueHelper
                        :value="personal.information.personalPhone"
                />
              </div>
            </div>

            <!--<div class="block-table__col mb-3">-->
              <!--<TitleHorizontal-->
                  <!--class="black mb-0"-->
                  <!--:value="'Order Items'"-->
                  <!--:rightBtn="true"-->
                  <!--:rightBtnType="'edit'"-->
                  <!--:rightBtnText="'Edit'"-->
                  <!--@click.native="changeChangeDataPopup(changeDataItems)"-->
              <!--/>-->
            <!--</div>-->

            <!--<div class="block-table__col mb-3">-->
              <!--<div class="block-table__label">-->
                <!--Item Number-->
              <!--</div>-->
              <!--<div class="block-table__content">-->
                <!--434654745-->
              <!--</div>-->
            <!--</div>-->
            <!--<div class="block-table__col mb-3">-->
              <!--<div class="block-table__label">-->
                <!--Consolidation-->
              <!--</div>-->

              <!--<div class="block-table__content">-->
                <!--th_name_of _the_co...-->
              <!--</div>-->
            <!--</div>-->

            <div class="block-table__col mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fbm_Address'])"></div>
              <TitleHorizontal
                  class="black mb-0"
                  :value="$t('fbm_Address.localization_value.value')"
                  :rightBtn="true"
                  :rightBtnType="'edit'"
                  :rightBtnText="'Edit'"
                  @click.native="changeDeliveryDataPopup()"
              />
            </div>
            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_Address'])"></div>
                {{$t('fbm_Address.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                    :value="deliveryFBM.delivery.address"
                />
              </div>
            </div>
            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_addressSecond'])"></div>
                {{$t('common_addressSecond.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                    :value="deliveryFBM.delivery.address2"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_City'])"></div>
                {{$t('fbm_City.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                        :value="deliveryFBM.delivery.city"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_Region'])"></div>
                {{$t('fbm_Region.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                        :value="deliveryFBM.delivery.region"
                />
                <div v-if="FBM.data.sendFromRegion" class="user-card__head-label text-right font-weight-bold ml-auto">
                  <span class="brick-btn " style="font-size: 18px;white-space: nowrap;position: relative;left: 10px;"
                        v-bind:class="{
                          'label-circle--green': FBM.data.sendFromRegion === 'MN',
                          'label-circle--violet': FBM.data.sendFromRegion === 'FL',
                          'label-circle--orange': FBM.data.sendFromRegion === 'CA',
                        }"
                  >{{FBM.data.sendFromRegion}}</span>
                </div>
              </div>
            </div>
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_ZipCode'])"></div>
                {{$t('fbm_ZipCode.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                        :value="deliveryFBM.delivery.zip"
                />
              </div>
            </div>

            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_Country', 'express_ViewCustomLimits'])"></div>
                {{$t('fbm_Country.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                    :value="deliveryFBM.delivery.country"
                    :deep="'label'"
                />
              </div>
            </div>

            <!--<div class="block-table__col mb-3">-->
              <!--<TitleHorizontal-->
                  <!--class="black mb-0"-->
                  <!--:value="'Packaging'"-->
                  <!--:rightBtn="true"-->
                  <!--:rightBtnType="'edit'"-->
                  <!--:rightBtnText="'Edit'"-->
                  <!--@rightBtnClick="changeChangeDataPopup(true)"-->
              <!--/>-->
            <!--</div>-->

            <!--<div class="block-table__col mb-3">-->
              <!--<div class="block-table__content">-->
                <!--Bag-->
              <!--</div>-->
            <!--</div>-->

            <div class="block-table__col mb-3">
              <TitleHorizontal
                  class="black mb-0"
                  :value="$t('fbm_CommentOptional.localization_value.value')"
                  :rightBtn="true"
                  :rightBtnType="'edit'"
                  :rightBtnText="'Edit'"
                  @rightBtnClick="changeCommentDataPopup()"
              />
            </div>

            <div class="block-table__col mb-3">
              <div class="block-table__content word-break white-space-pre-wrap">
                <ValueHelper
                        :value="FBM.data.comment"
                />
              </div>
            </div>


            <template v-if="!isLabel">
              <div class="block-table__col mb-3">
                <TitleHorizontal
                        class="black mb-0 gift-style"
                        :value="$t('fbm_GiftMessage.localization_value.value')"
                        :rightBtn="true"
                        :rightBtnType="'edit'"
                        :rightBtnText="'Edit'"
                        @rightBtnClick="changeGiftDataPopup()"
                />
              </div>

              <div class="block-table__col mb-3">
                <div class="d-flex align-items-start gift-style">
                  <img v-if="img = getGiftImg"
                       :src="img"
                       alt="img"
                  >
                  <img v-else src="/img/finance-group/list-no-image.svg" alt="img">
                  <div class="block-table__content ml-3 word-break">
                    {{FBM.data.giftMassage}}
                  </div>
                </div>

                <div
                    class="d-flex align-items-end mt-3"
                    v-if="FBM.data.fileGift"
                    v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
                >
                  <div class="admin-edit" @click="editTranslate(['fbm_printGiftMessage'])"></div>
                  <router-link :to="$store.getters.GET_PATHS.getPdfFile + '/' + FBM.data.fileGift.uuid" :target="'_blank'">
                    <MainButton
                        class="order-create__footer-btn-i"
                        :value="$t('fbm_printGiftMessage.localization_value.value')"
                    />
                  </router-link>
                </div>

              </div>
            </template>


            <div class="block-table__col mb-3">
              <TitleHorizontal
                  class="black mb-0"
                  :value="$t('fbm_AdminPrice.localization_value.value')"
                  :rightBtn="true"
                  :rightBtnType="'edit'"
                  :rightBtnText="'Edit'"
                  @rightBtnClick="changeAPIDataPopup"
              />
            </div>

            <!--<div class="block-table__col mb-3">-->
              <!--<div class="d-flex align-items-start">-->
                <!--<img src="/img/trash-group/ship-rush.png" alt="img">-->
                <!--<div class="block-table__content ml-2">-->
                  <!--ShipRush-->
                <!--</div>-->
              <!--</div>-->
            <!--</div>-->
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_OrderPrice'])"></div>
                {{$t('fbm_OrderPrice.localization_value.value')}}
              </div>

              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.orderPrice"
                        :deep="'cost'"
                />
              </div>
            </div>


            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_shippingCost'])"></div>
                {{$t('fbm_shippingCost.localization_value.value')}}
              </div>

              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.shippingCost"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_SkladUsaFee'])"></div>
                {{$t('fbm_SkladUsaFee.localization_value.value')}}
              </div>

              <div class="block-table__content">
                <template v-if="FBM.data.productsExtraFee && FBM.data.productsExtraFee > 0">
                  <ValueHelper
                      :value="FBM.data.productsExtraFee"
                  />
                </template>
                <template v-else>
                  <ValueHelper
                      :value="FBM.data.skladUsaFee"
                  />
                </template>
              </div>
            </div>
            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['invoices_Total'])"></div>
                {{$t('invoices_Total.localization_value.value')}}
              </div>

              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.invoiceTotalAmount"
                        :deep="'total_amount'"
                />
              </div>
            </div>

            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_realCostDelivery'])"></div>
                {{$t('fbm_realCostDelivery.localization_value.value')}}
              </div>

              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.Order.data.orderOBJ"
                        :deep="'orderFbmInstance.order_fbm_delivery_driver_methods.real_shipping_cost'"
                />
              </div>
            </div>


            <div class="block-table__col mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fbm_AdminReturn'])"></div>
              <TitleHorizontal
                  class="black mb-0"
                  :value="$t('fbm_AdminReturn.localization_value.value')"
                  :rightBtn="true"
                  :rightBtnType="'edit'"
                  :rightBtnText="'Edit'"
                  @rightBtnClick="changeConsolidationDataPopup"
              />
            </div>



            <div class="block-table__col block-table__col--50 mb-3" v-if="FBM.data.idReturned">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_SkladUsaFee'])"></div>
                {{$t('fbm_AdminReturnId.localization_value.value')}}
              </div>

              <div class="block-table__content">
                <router-link class="site-link" :to="$store.getters.GET_PATHS.problemsReturnGoodsUrl + '/show/' + FBM.data.idReturned">
                  #{{FBM.data.idReturned}}
                </router-link>
              </div>
            </div>

            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_apc'])"></div>
                {{$t('fbm_apc.localization_value.value')}}
              </div>

              <div v-if="FBM.data.consolidationOrderUnionTrack" class="block-table__content word-break">
                {{FBM.data.consolidationOrderUnionTrack}}
              </div>
              <div v-else-if="FBM.data.consolidationOrderUnionId" class="block-table__content">
                  #{{FBM.data.consolidationOrderUnionId}}
              </div>
              <div v-else class="block-table__content">
                —
              </div>
            </div>

            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_consolidationUnion'])"></div>
                {{$t('fbm_consolidationUnion.localization_value.value')}}
              </div>
              <div v-if="FBM.data.consolidationUnion" class="block-table__content">
                  #{{FBM.data.consolidationUnion.tracking_number}}
              </div>
              <div v-else class="block-table__content">
                —
              </div>
            </div>

            <div class="block-table__col block-table__col--50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_externalId'])"></div>
                {{$t('fbm_externalId.localization_value.value')}}
              </div>

              <div v-if="FBM.data.lotNumber && FBM.data.lotNumber.length > 0" class="block-table__content">
                <TooltipTextHelper
                    :fullTextWidth="130"
                    :text="FBM.data.lotNumber"
                    :paragraph="FBM.data.lotNumber"
                />
              </div>
              <div v-else class="block-table__content">
                —
              </div>
            </div>

            <template v-if="(isLabel || isConsolidation) && FBM.data.consolidation">
              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['fbm_ConsoTrackingNumber'])"></div>
                  {{$t('fbm_ConsoTrackingNumber.localization_value.value')}}
                </div>

                <div class="block-table__content">
                  <ValueHelper
                      :value="FBM.data.consolidation.user_tracking_number"
                  />
                </div>
              </div>

              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['fbm_ConsoID'])"></div>
                  {{$t('fbm_ConsoID.localization_value.value')}}
                </div>

                <div class="block-table__content">
                  <router-link
                      :target="'_blank'"
                      :to="$store.getters.GET_PATHS.ordersConsolidationEditing + '/' +
												getConsolidationTypeByDestinationId(FBM.data.consolidationDestination.id) + '/' + FBM.data.consolidation.id"
                      class="table-link btn-style">
                    #{{ FBM.data.consolidation.id }}
                  </router-link>
                </div>
              </div>

              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['fbm_ConsolidationInfo'])"></div>
                  {{$t('fbm_ConsolidationInfo.localization_value.value')}}
                </div>

                <div class="block-table__content">
                  {{FBM.data.consolidation.consolidation_destination.delivery_service.name}}
                </div>
              </div>
            </template>



            <template v-if="FBM.data.isEngraving ||
            FBM.data.Embroidery.data.isEmbroidery ||
            FBM.data.TShirtPrinting.data.isTShirtPrinting ||
            FBM.data.LeatherStamp.data.isLeatherStamp ||
            FBM.data.UVPrinting.data.isUVPrinting ||
            FBM.data.PhotoPrint.data.isPhotoPrint">
              <div class="block-table__col block-table__col--100 mb-0"></div>
              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['fbm_engravingDownload'])"></div>
                  {{$t('fbm_engravingDownload.localization_value.value')}}
                </div>

                <div class="block-table__content flex-column mt-2">
                  <span class="link-button text-decoration-underline mb-2"
                        v-if="FBM.data.isEngraving"
                        @click="downloadEngravingArray(FBM.data.Engravings, 'dxf', 'Engraving')">{{$t('fbm_EngravingFileName.localization_value.value')}}</span>
                  <span class="link-button text-decoration-underline mb-2"
                        v-if="FBM.data.Embroidery.data.isEmbroidery"
                        @click="downloadEngravingFile(FBM.data.Embroidery.data.File, 'dst')">{{$t('calculatorEngraving_EmbroideryCalculator.localization_value.value')}}</span>
                  <span class="link-button text-decoration-underline mb-2"
                        v-if="FBM.data.TShirtPrinting.data.isTShirtPrinting"
                        @click="downloadEngravingFile(FBM.data.TShirtPrinting.data.File, 'dxf', 'T-shirt printing')">{{$t('calculatorEngraving_TShirtPrinting.localization_value.value')}}</span>
                  <span class="link-button text-decoration-underline mb-2"
                        v-if="FBM.data.LeatherStamp.data.isLeatherStamp"
                        @click="downloadEngravingFile(FBM.data.LeatherStamp.data.File, 'dxf', 'Leather stamp')">{{$t('calculatorEngraving_LeatherStamp.localization_value.value')}}</span>
                  <span class="link-button text-decoration-underline"
                        v-if="FBM.data.PhotoPrint.data.isPhotoPrint"
                        @click="downloadEngravingFile(FBM.data.PhotoPrint.data.File, 'psd', 'Photo print')">{{$t('calculatorEngraving_PhotoPrint.localization_value.value')}}</span>
                  <span class="link-button text-decoration-underline"
                        v-if="FBM.data.UVPrinting.data.isUVPrinting"
                        @click="downloadEngravingFile(FBM.data.UVPrinting.data.File, 'dxf', 'UV printing')">{{$t('calculatorEngraving_UVPrinting.localization_value.value')}}</span>
                </div>
              </div>

              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['fbm_engravingTimeName'])"></div>
                  {{$t('fbm_engravingTimeName.localization_value.value')}}
                </div>

                <div class="block-table__content mt-2"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['fbm_EngravingFile'])"></div>
                  <DefaultCheckbox
                      v-model="FBM.data.engravingAdminCheck"
                      :label="$t('fbm_EngravingFile.localization_value.value')"
                      @click="FBM.data.engravingAdminCheck = !FBM.data.engravingAdminCheck"
                  />
                </div>
              </div>
            </template>

            <div class="block-table__col mb-3"
                 v-if="FBM.data.ProductPhoto.data.downloadFiles.length > 0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fbm_productPhoto'])"></div>
              <TitleHorizontal
                  class="black mb-0"
                  :value="$t('fbm_productPhoto.localization_value.value')"
                  :rightBtn="false"
                  :rightBtnType="'edit'"
                  :rightBtnText="'Edit'"
                  @rightBtnClick="changeConsolidationDataPopup"
              />
            </div>

            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-if="FBM.data.ProductPhoto.data.downloadFiles.length > 0"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_Photo'])"></div>
                {{$t('fbm_Photo.localization_value.value')}}
              </div>

              <div class="block-table__content flex-column">
                <div class="custom-row mt-3"
                     v-if="FBM.data.ProductPhoto.data.downloadFiles.length > 0">
                  <div class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
                       v-for="(item, index) in FBM.data.ProductPhoto.data.downloadFiles"
                       :key="index">
                    <div class="site-document d-flex justify-content-center">
                    <span class="site-document__remove" @click="removeDocument(item)">
                      <CloseIcon/>
                    </span>
                      <div class="site-document__img">
                        <img
                            :src="`data:${item.extension};base64,` + item.base64"
                            @click="showSingle(item)"
                            alt="img"
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(),
                     'toggle-block__details-bottom': FBM.data.ProductPhoto.data.downloadFiles.length > 0}">
                  <div class="admin-edit" @click="editTranslate(['fbm_takePhoto',])"></div>
                  <MainButton
                      class="wfc"
                      :value="$t('fbm_takePhoto.localization_value.value')"
                      @click.native="takePhoto"
                  />
                </div>
              </div>
            </div>



          </div>

        </div>


      </div>

      <!--<ChangePasswordPopup-->
        <!--v-if="isModalChangePasswordPopup"-->
        <!--@close="changeChangePasswordPopup(false)"-->
      <!--/>-->


      <!--<PhonesPopup-->
          <!--v-if="isModalPhonesPopup"-->
          <!--@close="changePhonesPopup(false)"-->
      <!--/>-->

      <ChangeDataPopup
          v-if="isModalChangeAddressPopup"
          :items="changeDataPopup"
          :popupTitle="popupTitle"
          :getRegionsIfUSA="true"
          @save="saveOrderData"
          @close="isModalChangeAddressPopup = false"
      />

      <StatisticPopup
          v-if="userStatistic"
          :userId="FBM.data.User.user.id"
          @close="userStatistic = false"
      />

      <!--<HistoryOfChanges-->
          <!--v-if="isModalHistoryOfChanges"-->
          <!--@close="changeHistoryOfChanges(false)"-->
      <!--/>-->

    </div>

    <Lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="visible = false"
    >
      <template v-slot:toolbar="{ toolbarMethods }">
        <div class="vel-toolbar" prefixcls="vel">
          <div @click="toolbarMethods.zoomIn" class="toobar-btn toolbar-btn__zoomin">
            <svg aria-hidden="true" class="vel-icon icon">
              <use xlink:href="#icon-zoomin"></use>
            </svg>
          </div>
          <div @click="toolbarMethods.zoomOut" class="toobar-btn toolbar-btn__zoomout">
            <svg aria-hidden="true" class="vel-icon icon">
              <use xlink:href="#icon-zoomout"></use>
            </svg>
          </div>
          <div class="toobar-btn toolbar-btn__resize">
            <svg aria-hidden="true" class="vel-icon icon">
              <use xlink:href="#icon-resize"></use>
            </svg>
          </div>
          <div  @click="toolbarMethods.rotateLeft" class="toobar-btn toolbar-btn__rotate">
            <svg aria-hidden="true" class="vel-icon icon">
              <use xlink:href="#icon-rotate-left"></use>
            </svg>
          </div>
          <div @click="toolbarMethods.rotateRight" class="toobar-btn toolbar-btn__rotate">
            <svg aria-hidden="true" class="vel-icon icon">
              <use xlink:href="#icon-rotate-right"></use>
            </svg>
          </div>
          <div @click="openImageInNewTab" class="toobar-btn toolbar-btn__open">
            Open
          </div>
        </div>
      </template>
    </Lightbox>

  </div>
</template>

<script>
  import TitleHorizontal from "../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
  import ChangeDataPopup from "../../../../popups/ChangeDataPopup/ChangeDataPopup";
  import {PersonalInformation} from "../../../../../../globalModels/PersonalInformation";
  import {FBMMixins, FBMMixinsDeliveryData} from "../../../../../../../mixins/FBMMixins/FBMMixins";
  import {Delivery} from "../../../../../../globalModels/Delivery";
  import {Comment} from "../../../../models/Comment";
  import {GiftMassage} from "../../../../models/GiftMassage";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {consolidationMixin} from "@/mixins/consolidationMixins/consolidationMixin";
  import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
  import StatisticPopup from "@/components/coreComponents/TableComponents/TableUserColumn/popups/StatisticPopup/StatisticPopup";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import CloseIcon from '../../../../../../../../public/img/modal-group/close-icon.svg?inline'
  import Lightbox from "vue-easy-lightbox";
  import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import SendFromDataEditPopup
    from "@/components/coreComponents/Popups/SendFromDataEditPopup/SendFromDataEditPopup";

  export default {
    name: "OrderCard",
    components:{
      SendFromDataEditPopup,
      DefaultCheckbox,
      MainButton,
      StatisticPopup,
      TooltipTextHelper,
      ValueHelper,
      TitleHorizontal,
      ChangeDataPopup,
      CloseIcon,
      Lightbox,
    },

    mixins: [FBMMixinsDeliveryData, FBMMixins, consolidationMixin],

    props:{
      FBM: Object,
      user: Object,
      personal: Object,
      deliveryFBM: Object,
    },

    computed: {
      getGiftImg() {
        let img = false

        if(this._.has(this.FBM.data.giftPaper, 'small_image_file')){
          img = 'data:' + this.FBM.data.giftPaper.small_image_file.mime_type + ';base64,' + this.FBM.data.giftPaper.imageBase64
        }

        return img
      },

      getPayoneerBalance() {
        if(this.FBM.data.User?.user?.user_balances && this._.find(this.FBM.data.User.user.user_balances, {payment_system: 'payoneer'})) {
          return this._.find(this.FBM.data.User.user.user_balances, {payment_system: 'payoneer'}).balance
        }
        return false
      },
    },

    data(){
      return{
        isSendFromDataEditPopup: false,
        isModalChangePasswordPopup: false,
        isModalPhonesPopup: false,
        isModalChangeAddressPopup: false,
        isModalHistoryOfChanges: false,

        userStatistic: false,

        changeDataPopup: [],

        typeFieldsChanges: null,
        popupTitle: '',


        /**
         * Fake models for check validation on Change Data Popup
         */
        PI: new PersonalInformation(),
        Delivery: new Delivery(),
        Comment: new Comment(),
        Gift: new GiftMassage(),


        images: -1,
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, // default: 0
      }
    },

    methods: {

      openImageInNewTab() {
        let image = new Image()
        image.src = this._.first(this.imgs).src
        let w = window.open("")
        w.document.write(image.outerHTML)
      },

      removeDocument(item) {
        this.$store.dispatch('removeUserDocument', item.bigImageId).then(() => {
          let files = this.FBM.data.ProductPhoto
          let index = this._.findIndex(files.data.downloadFiles, {id: item.id})
          files.data.downloadFiles.splice(index, 1)
          // let updatedFiles = this._.remove(files.data.downloadFiles, function(n) {
          //   return n.id === item.id
          // })
          // files.setDownloadFiles(updatedFiles)
          this.images = item.id
        })
      },

      showSingle(item) {
        this.$store.dispatch('getFileFromServer', item.bigImageId).then((response) => {
          this.imgs = []
          // let image = new Image();
          // image.src = `data:${item.extension};base64,` + response[item.bigImageId]
          // console.log(image);

          // let src = this.b64toBlob(response[item.bigImageId], item.extension)
          // window.location = URL.createObjectURL(blob);

          this.imgs.push({
            title: 'img',
            src: `data:${item.extension};base64,` + response[item.bigImageId]
            // src: src
          })
          this.visible = true
        })
      },

      b64toBlob(b64Data, contentType = '') {
        const binaryImage = atob(b64Data);
        const blob = new Blob([binaryImage], { type: contentType });
        const imageUrl = URL.createObjectURL(blob);
        console.log(imageUrl);
        return imageUrl
        // const imgElement = document.querySelector('img');
        // imgElement.src = imageUrl;
      },

      changeChangePasswordPopup(val){
        this.isModalChangePasswordPopup = val
      },

      changePhonesPopup(val){
        this.isModalPhonesPopup= val
      },

      changeChangeDataPopup(val){
        this.isModalChangeAddressPopup = true
        this.changeDataPopup = val
      },

      changeHistoryOfChanges(val){
        this.isModalHistoryOfChanges = val
      },

      changeInformationDataPopup(){

        this.PI.setPersonalName(this.personal.information.personalName)
        this.PI.setPersonalPhone(this.personal.information.personalPhone)
        this.PI.setPersonalCompany(this.personal.information.personalCompany)
        this.PI.setPersonalEmail(this.personal.information.personalEmail)

        this.setPopupInformationData()
        this.typeFieldsChanges = 'personal'
        this.popupTitle = 'common_personalChanges'
        this.isModalChangeAddressPopup = true
      },

      changeDeliveryDataPopup(){

        this.Delivery.setAddress(this.deliveryFBM.delivery.address)
        this.Delivery.setAddress2(this.deliveryFBM.delivery.address2)
        this.Delivery.setCity(this.deliveryFBM.delivery.city)
        this.Delivery.setRegion(this.deliveryFBM.delivery.region)
        this.Delivery.setCountry(this.deliveryFBM.delivery.country)
        this.Delivery.setZip(this.deliveryFBM.delivery.zip)

        this.setPopupDeliveryData()
        this.typeFieldsChanges = 'delivery'
        this.popupTitle = 'common_deliveryChanges'
        this.isModalChangeAddressPopup = true
      },

      changeGiftDataPopup(){

        this.Gift.setGiftMassage(this.FBM.getGiftMassage())
        this.Gift.setGiftPaper(this.FBM.data.giftPaper)

        this.setPopupGiftData()
        this.typeFieldsChanges = 'gift'
        this.popupTitle = 'common_giftChanges'
        this.isModalChangeAddressPopup = true
      },

      changeCommentDataPopup(){

        this.Comment.setComment(this.FBM.data.comment)

        this.setPopupCommentData()
        this.typeFieldsChanges = 'comment'
        this.popupTitle = 'common_commentChanges'
        this.isModalChangeAddressPopup = true
      },

      changeAPIDataPopup(){

        this.setPopupAPIData()
        this.typeFieldsChanges = 'api'
        this.popupTitle = 'common_apiChanges'
        this.isModalChangeAddressPopup = true
      },

      changeConsolidationDataPopup(){

        this.setPopupConsolidationData()
        this.typeFieldsChanges = 'consolidation'
        this.popupTitle = 'fbm_apc'
        this.isModalChangeAddressPopup = true
      },

      saveOrderData(data) {
        switch (this.typeFieldsChanges) {
          case 'personal':
            this.personalUpdate(data)
            break

          case 'delivery':
            this.deliveryUpdate(data)
            break

          case 'gift':
            this.giftUpdate(data)
            break

          case 'comment':
            this.commentUpdate(data)
            break

          case 'api':
            this.APIUpdate(data)
            break

          case 'consolidation':
            this.consolidationUpdate(data)
            break
        }
      },



    }
  }
</script>

<style scoped>

  .small-input.default-input-wrap >>> input {
    height: 37px;
  }

  .gift-style >>> .title-horizontal__text {
    color: #ff0c0c;
    font-size: 17px;
  }
  .gift-style .block-table__content {
    color: #146fd3;
    font-weight: bold;
  }
  .gift-style img {
    width: 55px;
  }

  .site-document__img {
    max-width: 75px;
    min-width: 43px;
    height: 55px;
  }

  .site-document__remove {
    right: -10px;
    top: 1px;
  }


  .vel-toolbar {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    overflow: hidden;
    bottom: 8px;
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    opacity: .9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #2d2d2d;
    border-radius: 4px;
    padding: 0;
  }

  .vel-toolbar .toobar-btn {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    cursor: pointer;
    padding: 6px 10px;
    font-size: 20px;
    color: #fff;
    background-color: #2d2d2d;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
  }

  .vel-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }

  .toolbar-btn__open {
    color: white;
    font-size: 18px;
    padding: 6px 10px;
  }

  .user-card__head-label {
    color: #F99746;
  }
</style>
