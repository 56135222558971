<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_CommentOptional'])"></div>
        {{$t('fbm_CommentOptional.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
       <div class="custom-row">
         <div class="custom-col">
           <div class="order-create__section-label section-label"
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
             <div class="admin-edit" @click="editTranslate(['fbm_CommentOptional'])"></div>
             <ValueHelper
                 :value="FBM.data.comment"
             />
           </div>
         </div>
       </div>
      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel'])"></div>
        <span
            class="site-link site-link--alt buy-label__btn-i mr-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>
      </div>
    </template>
  </modal>

</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "NegativeBalancePopup",
    props: {
      FBM: Object,
    },
    components: {
      ValueHelper,
      Modal,
    },

  }

</script>

<style scoped>

</style>
