<template>
  <div class="fragment">

    <div v-if="$store.getters.getOrdersFBMCommonList.total" class="pagination-wrap mt-3">
      <PerPageSelect
          :currentPerPage="$store.getters.getOrdersFBMForPage"
          :cookieName="'perPageFBM'"
          @changePerPage="(perPage) => {$emit('changePerPage', perPage)}"
      />
      <Pagination
          :count="$store.getters.getOrdersFBMCommonList.total"
          :perPage="$store.getters.getOrdersFBMForPage"
          :loading="$store.getters.getOrdersFBMLoading"
          @paginate="(page) => {$emit('paginate', page)}"
      />
    </div>
    <template v-if="$store.getters.getOrdersFBM.length > 0 && !$store.getters.getOrdersFBMLoading">
      <div class="site-table-wrap order-fbm-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'fbm_id',
            'fbm_Created',
            'fbm_Recepient',
            'fbm_PayPalTransactions',
            'fbm_Lots',
            'fbm_Ship',
            'fbm_DHLTracknumber',
            'fbm_TrackingNumber',
            'fbm_ConsoID',
            'fbm_WasSentAt',
            'common_manage',
            'common_edit',
            'common_copy',
            'fbm_getLabelFBM',
            'fbm_reloadLabelFBM',
            'fbm_Return',
            'fbm_PrepaidTotal',
            'fbm_Paid',
            'common_delete',
            'fbm_paidYes',
            'fbm_paidNo',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th></th>
            <th>{{$t('fbm_id.localization_value.value')}}</th>
            <th>{{$t('fbm_Created.localization_value.value')}}</th>
            <th v-if="isAdmin">{{$t('common_user.localization_value.value')}}</th>
            <th>{{$t('fbm_Recepient.localization_value.value')}}</th>
            <th>{{$t('fbm_PayPalTransactions.localization_value.value')}}</th>
            <!--<th>{{$t('fbm_Lots.localization_value.value')}}</th>-->
            <th>{{$t('fbm_Ship.localization_value.value')}}</th>
            <th>{{$t('fbm_TrackingNumber.localization_value.value')}}</th>
            <th v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name].active">{{$t('fbm_DHLTracknumber.localization_value.value')}}</th>
            <th v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name].active">{{$t('fbm_ConsoID.localization_value.value')}}</th>
            <th>{{$t('fbm_WasSentAt.localization_value.value')}}</th>
            <th v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active && (isUserPrepayment || isAdmin)">{{$t('fbm_PrepaidTotal.localization_value.value')}}</th>
            <th v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active && (isUserPrepayment || isAdmin)">{{$t('fbm_Paid.localization_value.value')}}</th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-bind:class="{
                'site-table__draft-row': item.in_draft === 1,
                'site-table__blue-row': item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.id && !item.in_draft,
                'site-table__orange-row': item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.id && !item.in_draft,
                'site-table__violet-row': item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_LABEL.id && !item.in_draft,
              }"
              v-for="(item, index) in $store.getters.getOrdersFBM"

              :key="index">
            <td>
              <DefaultCheckbox
                      class="empty-label"
                      :selectedNow="selectedNow"
                      :dataValue="item.id"
              />
            </td>
            <td>
              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
            'fbm_orderSentInConso',
          ])"></div>

                <div class="d-flex">
                  <router-link
                      v-if="item.return_parcel"
                      :to="`${$store.getters.GET_PATHS.problemsReturnGoodsUrl}${isAdmin ? '/' : '/show/'}${item.return_parcel.id}`"
                      class="d-flex justify-content-center">
                    <TooltipTextHelper>
                      <template slot="text">
                        <ReturnIconSVG class="mr-1 red-ico"/>
                      </template>
                      <template slot="title">
                        <div style="font-size: 14px; font-weight: bold; word-break: initial;">
                          Id #{{item.return_parcel.id}}
                        </div>
                      </template>
                    </TooltipTextHelper>
                  </router-link>
                  <router-link
                      :to="$store.getters.GET_PATHS.ordersFBM + '/show/' + getOrderFBMTypeById(item.order_type_id).name + '/' + item.id"
                      class="d-flex justify-content-center">
                    <TooltipTextHelper>
                      <template slot="text">
                       <span>
                          <LabelCircle v-if="!item.consolidation && item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.id"
                                       :color="'blue'" :value="'C'" class="mr-8"/>
                          <LabelCircle v-if="!item.consolidation && item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.id"
                                       :color="'orange'" :value="'W'" class="mr-8"/>
                          <LabelCircle v-if="!item.consolidation && item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_LABEL.id"
                                       :color="'violet'" :value="'L'" class="mr-8"/>
                          <LabelCircle v-if="item.consolidation"
                                       :color="'brown'" :value="'C'" class="mr-8"/>
                        </span>
                      </template>
                      <template slot="paragraph">
                        <div class="status-tooltip" style="word-break: initial;">
                          <template v-if="!item.consolidation && item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.id" >
                            <LabelCircle :color="'blue'" :value="'C'" class="mr-8"/> Order for Consolidation
                          </template>
                          <template v-if="!item.consolidation && item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.id" >
                            <LabelCircle :color="'orange'" :value="'W'" class="mr-8"/> Order to Warehouse
                          </template>
                          <template v-if="!item.consolidation && item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_LABEL.id" >
                            <LabelCircle :color="'violet'" :value="'L'" class="mr-8"/> Lable
                          </template>
                          <template v-if="item.consolidation">
                            <LabelCircle :color="'brown'" :value="'C'" class="mr-8"/> {{$t('fbm_orderSentInConso.localization_value.value')}}
                          </template>
                        </div>
                      </template>
                    </TooltipTextHelper>
<!--                    <v-popover class="site-tooltip" offset="5" placement="top-left" trigger="hover">-->
<!--                  <span>-->
<!--                    <LabelCircle v-if="!item.consolidation && item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.id"-->
<!--                                 :color="'blue'" :value="'C'" class="mr-8"/>-->
<!--                    <LabelCircle v-if="!item.consolidation && item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.id"-->
<!--                                 :color="'orange'" :value="'W'" class="mr-8"/>-->
<!--                    <LabelCircle v-if="!item.consolidation && item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_LABEL.id"-->
<!--                                 :color="'violet'" :value="'L'" class="mr-8"/>-->
<!--                    <LabelCircle v-if="item.consolidation"-->
<!--                                 :color="'brown'" :value="'C'" class="mr-8"/>-->
<!--                  </span>-->
<!--                      <template slot="popover">-->
<!--                        <div class="status-tooltip">-->
<!--                          <template v-if="!item.consolidation && item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.id" >-->
<!--                            <LabelCircle :color="'blue'" :value="'C'" class="mr-8"/> Order for Consolidation-->
<!--                          </template>-->
<!--                          <template v-if="!item.consolidation && item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.id" >-->
<!--                            <LabelCircle :color="'orange'" :value="'W'" class="mr-8"/> Order to Warehouse-->
<!--                          </template>-->
<!--                          <template v-if="!item.consolidation && item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_LABEL.id" >-->
<!--                            <LabelCircle :color="'violet'" :value="'L'" class="mr-8"/> Lable-->
<!--                          </template>-->
<!--                          <template v-if="item.consolidation">-->
<!--                            <LabelCircle :color="'brown'" :value="'C'" class="mr-8"/> {{$t('fbm_orderSentInConso.localization_value.value')}}-->
<!--                          </template>-->
<!--                        </div>-->
<!--                      </template>-->
<!--                    </v-popover>-->
                    <span class="table-link btn-style">#{{item.id}}</span>
                  </router-link>
                </div>
                <div v-if="isActiveBarcode(item)">{{isActiveBarcode(item)}}</div>
              </div>
            </td>
            <td>{{ item.created_at | moment("DD MMM, YYYY") }}</td>
            <td v-if="isAdmin">
              <TableUserColumn
                      v-if="isAdmin"
                      :item="item"
              />
            </td>
            <td>
              <TooltipTextHelper :fullTextWidth="170">
                <template slot="text">
                  <ValueHelper
                      :value="item.recipient_contact_name"
                  />
                </template>
                <template slot="paragraph">
                  <ValueHelper
                      :value="item.recipient_contact_name"
                  />
                </template>
              </TooltipTextHelper>

            </td>
            <td>
              <ValueHelper
                      :value="item"
                      :deep="'payment_transaction.transaction_id'"
              />
            </td>
            <!--<td>-->
              <!--<ValueHelper-->
                      <!--:value="item.lot_number"-->
              <!--/>-->
            <!--</td>-->
            <td>
              <ValueHelper
                      :value="item.delivery_cost"
              />
            </td>
            <td>
              <div class="table-row">

                <TableTrackingLog
                    v-if="item.delivery_state.delivery_status"
                    class="mt-1"
                    :trackingLogItem="null"
                    :trackingStateEvent="item.delivery_state"
                />



<!--                <v-popover-->
<!--                        v-if="item.tracking_number"-->
<!--                        class="site-tooltip"-->
<!--                        offset="5"-->
<!--                        placement="top-left"-->
<!--                        trigger="hover"-->
<!--                        @click.native="showStatusOrderDetailsPopup"-->
<!--                >-->
<!--                  <span>-->
<!--                    <StatusIcoBtn-->
<!--                            :type="'delivered'"-->
<!--                            class="mr-1"-->
<!--                    />-->
<!--                  </span>-->
<!--                  <template slot="popover">-->
<!--                    <div class="status-tooltip">-->
<!--                      <StatusIcoBtn :type="'delivered'" class="mr-2"/>-->
<!--                      Delivered-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </v-popover>-->

                <ValueHelper
                        :value="item.tracking_number"
                />
              </div>
            </td>
            <td v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name].active">
              <ValueHelper
                  :value="item.consolidation"
                  :deep="'user_tracking_number'"
              />
            </td>

            <td v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name].active">
              <template v-if="item.consolidation !== null">
                <LinkButton
                    class="table-link btn-style"
                    :value="item.consolidation.id"
                    :link="(isAdmin ? $store.getters.GET_PATHS.ordersConsolidationEditing : $store.getters.GET_PATHS.ordersConsolidationDetail) + '/' +
                    getConsolidationTypeByDestinationId(item.consolidation.consolidationDestination.id) + '/' + item.consolidation.id"
                />
              </template>
              <template v-else>—</template>
            </td>
            <td>
              <ValueHelper
                      :value="isStatusComplete(item.status) ? item.delivery_date : ''"
                      :date="true"
                      :dateType="'DD MMM, YYYY'"
              />
            </td>
            <td v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active && (isUserPrepayment || isAdmin)">
              <ValueHelper
                  :value="item.prepay_delivery_cost"
              />
            </td>
            <td v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active && (isUserPrepayment || isAdmin)">
              <div
                  v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                    'fbm_paidYes',
                    'fbm_paidNo',
                  ])"></div>
                <TooltipTextHelper>
                  <template slot="text">
                    <div class="d-flex" v-if="item.prepay === 1">
                      <StatusIcoBtn :type="'active'" class="mr-1" />
                    </div>
                    <StatusIcoBtn :type="'cancel-red'" class="mr-1" v-else/>
                  </template>
                  <template slot="title">
                    <span style="word-break: initial;" v-if="item.prepay === 1">{{$t('fbm_paidYes.localization_value.value')}}</span>
                    <span style="word-break: initial;" v-else>{{$t('fbm_paidNo.localization_value.value')}}</span>
                  </template>
                </TooltipTextHelper>
              </div>

            </td>
            <td>
              <div class="table-right table-manage-list">

                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) "
                               :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item"
                            v-if="!(isCurrentUserRoleUser && isStatusComplete(item.status)) && !(isCurrentUserRoleUser && item.prepay === 1)"
                  >
                    <LinkButton
                            :value="item.in_draft ? $t('common_EditDraft.localization_value.value') : $t('common_edit.localization_value.value')"
                            :type="'edit'"
                            :link="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.order_type_id).name + '/' + item.id"
                    />
                  </template>
                  <template slot="item">
                    <LinkButton
                            :value="$t('common_copy.localization_value.value')"
                            :type="'copy'"
                            @click.native="$emit('copyOrder', item.id)"
                    />
                  </template>
                  <template slot="item" v-if="isAdmin">
                    <LinkButton
                        :value="$t('common_termalPrint.localization_value.value')"
                        :type="'print'"
                        @click.native="$emit('thermalPrint', {id: [item.id]})"
                    />
                  </template>
                  <template slot="item" v-if="(item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_LABEL.id || isAdmin) &&
                                                isStatusComplete(item.status) && item.file_entity.length">
                    <LinkButton
                        :value="$t('fbm_getLabelFBM.localization_value.value')"
                        :type="'dog'"
                        @click.native="$emit('getLabelFBM', item)"
                    />
                  </template>
                  <template slot="item" v-if="(item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_LABEL.id || isAdmin) &&
                                                isStatusComplete(item.status) && item.barcode_label_file">
                    <LinkButton
                        :value="$t('fbm_LabelBarcodeAdmin.localization_value.value')"
                        :type="'dog'"
                        @click.native="$emit('getBarcodeFBM', item)"
                    />
                  </template>
                  <template slot="item" v-if="(item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_LABEL.id) &&
                                                isStatusComplete(item.status)">
                    <LinkButton
                        :value="$t('fbm_reloadLabelFBM.localization_value.value')"
                        :type="'dog'"
                        @click.native="$emit('reloadLabelFBM', item.id)"
                    />
                  </template>
                  <template slot="item"
                            v-if="!(isCurrentUserRoleUser && isStatusComplete(item.status)) && !(isCurrentUserRoleUser && item.prepay === 1) && !item.has_active_customization">
                    <LinkButton
                            :value="$t('common_delete.localization_value.value')"
                            :type="'delete'"
                            @click.native="$emit('deleteOrder', item.id)"
                    />
                  </template>
                  <template slot="item"
                            v-if="isAdmin && item.prepay === 1">
                    <LinkButton
                        :value="$t('fbm_cancelPrepay.localization_value.value')"
                        :type="'delete'"
                        @click.native="$emit('cancelPrepayOrder', item.id)"
                    />
                  </template>
                  <template slot="item"
                            v-if="isStatusComplete(item.status) && !item.return_parcel && isAdmin">
                    <LinkButton
                            :value="$t('fbm_Return.localization_value.value')"
                            :type="'return'"
                            :link="$store.getters.GET_PATHS.problemsReturnGoodsCreation + '?returnFBMId=' + item.id"
                    />
                  </template>
                  <template slot="item"
                            v-if="item.can_stop_order">
<!--                            (item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.id || item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_LABEL.id) &&-->
<!--                            item.consolidation !== null &&-->
<!--                            !isStatusStop(item.status) &&-->
<!--                            !isGlobal24Table(item)-->
                    <LinkButton
                        :value="$t('common_stop.localization_value.value')"
                        :type="'pickup'"
                        @click.native="stopFBMOrder(item)"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getOrdersFBM"
                 :class="{'draft-row': item.in_draft === 1}"
                 :key="index"
            >
             <OrdersFBMTableUserTableMobile
                     :item="item"
                     :selectedNow="selectedNow"
                     :navTabs="navTabs"
                     :barcode="isActiveBarcode(item)"
                     @copyOrder="(val) => $emit('copyOrder', val)"
                     @deleteOrder="(val) => $emit('deleteOrder', val)"
                     @thermalPrint="(data) => $emit('thermalPrint', data)"
                     @getLabelFBM="$emit('getLabelFBM', item)"
                     @reloadLabelFBM="$emit('reloadLabelFBM', item.id)"
                     @stopOrderFBM="stopFBMOrder(item)"
             />
            </div>
          </div>
        </div>
      </div>


      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
                  v-if="$store.getters.getOrdersFBMCommonList.next_page_url !== null && $store.getters.getOrdersFBM.length > 0"
                  @click.native="$emit('showMore')"
                  v-bind:class="{'disabled-btn' : $store.getters.getNextOrdersFBMPage}"
                  :count="$store.getters.getOrdersFBMCommonList.total - $store.getters.getOrdersFBMForPage * $store.getters.getOrdersFBMCommonList.current_page < $store.getters.getOrdersFBMForPage ?
                  $store.getters.getOrdersFBMCommonList.total - $store.getters.getOrdersFBMForPage * $store.getters.getOrdersFBMCommonList.current_page:
                  $store.getters.getOrdersFBMForPage"
          />
          <ExportBtn
              class="table-bottom-btn__right"
              :customType="_.has($store.getters.getOrdersFBMCommonList, 'addition_export') ? $store.getters.getOrdersFBMCommonList.addition_export : null"
              @downloadFiles="type => $emit('downloadFiles', type)"
          />
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getOrdersFBMLoading === false && $store.getters.getOrdersFBM.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <StatusOrderDetailsPopup
            @close="closeStatusOrderDetailsPopup"
            v-if="isModalStatusOrderDetailsPopup"
    />


  </div>
</template>

<script>
  import ShowMore from '../../../../../../coreComponents/Buttons/ShowMore/ShowMore'
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import DefaultCheckbox from '../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox.vue'
  import LinkButton from '../../../../../../UI/buttons/LinkButton/LinkButton.vue'
  // import StatusIcoBtn from '../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn.vue'
  import ManagerButton from '../../../../../../UI/buttons/ManagerButton/ManagerButton.vue'
  import LabelCircle from '../../../../../../coreComponents/LabelCircle/LabelCircle'
  import StatusOrderDetailsPopup from "../../../../../../coreComponents/Popups/StatusOrderDetailsPopup/StatusOrderDetailsPopup.vue";
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import {FBM_ORDER_TYPES} from "../../../../../../../staticData/staticVariables";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {FBMMixinsHelper} from "../../../../../../../mixins/FBMMixins/FBMMixins";
  import {consolidationMixin} from "../../../../../../../mixins/consolidationMixins/consolidationMixin";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import OrdersFBMTableUserTableMobile from "./OrdersFBMTableUserTableMobile/OrdersFBMTableUserTableMobile";
  import TableTrackingLog from "@/components/coreComponents/TableComponents/TableTrackingLog/TableTrackingLog";
  import ReturnIconSVG from '../../../../../../../../public/img/UI-group/return-icon.svg?inline'
  import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
  import StatusIcoBtn from "@/components/UI/status/StatusIcoBtn/StatusIcoBtn";
  import Pagination from "../../../../../../coreComponents/Pagination/Pagination";
  import PerPageSelect from "../../../../../../coreComponents/PerPageSelect/PerPageSelect";


  export default {
    name: "OrdersFBMTableUserTable",

    components: {
      PerPageSelect,
      Pagination,
      StatusIcoBtn,
      TooltipTextHelper,
      TableTrackingLog,
      OrdersFBMTableUserTableMobile,
      TableUserColumn,
      ValueHelper,
      NoResult,
      ShowMore,
      ExportBtn,
      DefaultCheckbox,
      LinkButton,
      ManagerButton,
      // StatusIcoBtn,
      LabelCircle,
      StatusOrderDetailsPopup,
      ReturnIconSVG,
    },

    mixins: [mixinDetictingMobile, FBMMixinsHelper, consolidationMixin],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    data() {
      return {
        orderId: '',
        showFilter: false,

        FBM_ORDER_TYPES: FBM_ORDER_TYPES,

        isModalStatusOrderDetailsPopup: false,
        show1: false,
        show2: false,
        show3: false,


      }
    },

    methods: {

      isActiveBarcode(item) {
        if(item?.order_barcode && item.order_barcode?.barcode && this.isAdmin){
          if(item.order_barcode.barcode.indexOf('EZ-') > -1) {
            return item.order_barcode.barcode
          }
        }
        return false
      },

      showStatusOrderDetailsPopup() {
        this.isModalStatusOrderDetailsPopup = true
      },

      closeStatusOrderDetailsPopup() {
        this.isModalStatusOrderDetailsPopup = false
      },

      stopFBMOrder(item) {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_yes',
          no: 'common_no'
        }

        let confirm = () => {

          this.stopFBMOrderProcess(item)

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      async stopFBMOrderProcess(item) {

        let data = {
          'orderFbmId': item.id
        }

        await this.$store.dispatch('stopConsolidationOrdersFBM', data).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              return false
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.data.errors;
              this.notifyErrorHelper(errors)
              return true
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
              return true
            }
          }
        })
      },

    }
  }

</script>

<style scoped>

.green {
  color: #27AE60;
}

.red {
  color: #B82E24;
}

  .order-fbm-table .manager-btn {
    width: 120px;
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child {
    padding-right: 5px;
  }

  .red-ico path{
    stroke: #EB5757;
  }

  @media (max-width: 1800px) {

    .site-table tbody td,
    .site-table thead th {
      font-size: 13px;
    }
  }

  @media (max-width: 1500px) {

    .order-fbm-table .manager-btn {
      width: 180px;
    }
  }
</style>
