import {OrderFBM} from "./OrderFBM";

OrderFBM.prototype.getComment = function () {
  return this.data.comment;
}

OrderFBM.prototype.getLotNumber = function () {
  return this.data.lotNumber;
}

OrderFBM.prototype.getIsInsurance = function () {
  return this.data.isInsurance;
}

OrderFBM.prototype.getInsuranceAmount = function () {
  return this.data.insuranceAmount;
}

OrderFBM.prototype.getInsuranceCost = function () {
  return this.data.insuranceCost;
}

OrderFBM.prototype.getInsuranceFromDeliveryCompany = function () {
  return this.data.insuranceFromDeliveryCompany;
}

OrderFBM.prototype.getGiftMassage = function () {
  return this.data.giftMassage;
}

OrderFBM.prototype.getShippingPartnerType = function () {
  return this.data.shippingPartnerType;
}

OrderFBM.prototype.getShippingPartner = function () {
  return this.data.shippingPartner;
}

OrderFBM.prototype.getShippingAPIPrice = function () {
  return this.data.shippingAPIPrice;
}

OrderFBM.prototype.getPossibleSaturdayDelivery = function () {
  return this.data.possibleSaturdayDelivery;
}

OrderFBM.prototype.getPackagingType = function () {
  return this.data.packagingType;
}

OrderFBM.prototype.getGiftPackagingType = function () {
  return this.data.giftPackagingType;
}

OrderFBM.prototype.getPackagingAdminType = function () {
  return this.data.packagingAdminType;
}

OrderFBM.prototype.getPackagingAdminPrice = function () {
  return this.data.packagingAdminPrice;
}

OrderFBM.prototype.getGiftPackagingAdminType = function () {
  return this.data.giftPackagingAdminType;
}

OrderFBM.prototype.getGiftPackagingPrice = function () {
  return this.data.giftPackagingPrice;
}

OrderFBM.prototype.getGiftPackagingAdminPrice = function () {
  return this.data.giftPackagingAdminPrice;
}

OrderFBM.prototype.getPackagingCost = function () {
  return this.data.packagingCost;
}

OrderFBM.prototype.getSignFromRecipient = function () {
  return this.data.signFromRecipient;
}

OrderFBM.prototype.getGiftPaper = function () {
  return this.data.giftPaper;
}

OrderFBM.prototype.getFileGift = function () {
  return this.data.fileGift;
}

OrderFBM.prototype.getVatNumber = function () {
  return this.data.vatNumber
}

OrderFBM.prototype.getVisibleVatNumber = function () {
  return this.data.visibleVatNumber
}

OrderFBM.prototype.getReceivedAt = function () {
  return this.data.receivedAt
}

OrderFBM.prototype.getShippingCompanies = function () {
  return this.data.shippingCompanies
}

OrderFBM.prototype.getShippingAPIPrices = function () {
  return this.data.shippingAPIPrices
}

OrderFBM.prototype.getShippingCost = function () {
  return this.data.shippingCost
}

OrderFBM.prototype.getSkladUsaFee = function () {
  return this.data.skladUsaFee
}

OrderFBM.prototype.getOrderPrice = function () {
  return this.data.orderPrice
}


OrderFBM.prototype.getVisibleHSCode = function () {
  return this.data.visibleHSCode
}

OrderFBM.prototype.getIdReturned = function () {
  return this.data.idReturned
}

OrderFBM.prototype.getCalcOrderProcessFee = function () {
  return this.data.calcOrderProcessFee
}

OrderFBM.prototype.getChangeCarrier = function () {
  return this.data.changeCarrier
}


OrderFBM.prototype.getTrsnAddressCountry = function () {
  if(this.data.transactionAddress === this.data.transactionAddressTypes.etsy){
    return this.data.DeliveryEtsy.getCountry();
  }

  return this.data.DeliveryPayPal.getCountry();
}

OrderFBM.prototype.getTrsnAddress = function () {
  if(this.data.transactionAddress === this.data.transactionAddressTypes.etsy){
    return this.data.DeliveryEtsy.getRegion();
  }

  return this.data.DeliveryPayPal.getRegion();
}

OrderFBM.prototype.getTrsnCity = function () {
  if(this.data.transactionAddress === this.data.transactionAddressTypes.etsy){
    return this.data.DeliveryEtsy.getCity();
  }

  return this.data.DeliveryPayPal.getCity();
}

OrderFBM.prototype.getTrsnZip = function () {
  if(this.data.transactionAddress === this.data.transactionAddressTypes.etsy){
    return this.data.DeliveryEtsy.getZip();
  }

  return this.data.DeliveryPayPal.getZip();
}

OrderFBM.prototype.getTotalSumProformProduct = function () {
  let totalSum = 0

  this.data.ProformProducts.map(item => {
    totalSum += parseFloat(item.product.price * item.product.itemsQuantity)
  })
  return parseFloat(totalSum)
}

OrderFBM.prototype.isHasSelectedProformProducts = function () {
  let selected = false
  this.data.ProformProducts.map(item => {
    if(item.isSelectedProform()){
      selected = true
    }
  })
  return selected;
}

OrderFBM.prototype.isHasSelectedProducts = function () {
  let selected = false
  this.data.productsForCellArray.map(item => {
    if(item.data.Product.isSelectedProduct()){
      selected = true
    }
  })
  return selected;
}

OrderFBM.prototype.getConsolidationOrderUnionId = function () {
  return this.data.consolidationOrderUnionId
}

OrderFBM.prototype.getConsolidationUnion = function () {
  return this.data.consolidationUnion
}

OrderFBM.prototype.getConsolidationOrderUnionTrack = function () {
  return this.data.consolidationOrderUnionTrack
}

OrderFBM.prototype.getRegions = function () {
  return this.data.regions
}

OrderFBM.prototype.getSendFromRegion = function () {
  return this.data.sendFromRegion
}

OrderFBM.prototype.getExpressTrack = function () {
  return this.data.expressTrack
}

OrderFBM.prototype.getNPTrack = function () {
  return this.data.npTrack
}

