<template>
  <div class="fragment order-admin-products">


    <div class="custom-row">
      <div class="custom-col" v-if="productsForCellArray.length > 0">

        <div class="site-table-wrap table-small"
             v-if="!isMobileFunc()"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit"
               @click="editTranslate(['productForCell_scannedCount','fbm_ProductName','fba_Cells'])"></div>
          <table class="site-table">
            <thead>
            <tr>
              <th style="width: 20px;">
<!--                <DefaultCheckbox-->
<!--                    v-if="_.has(this.currentPermissions, PERMISSIONS.SELECT_ALL_SCAN_PRODUCTS)"-->
<!--                    style="width: 17px; min-height: 17px; background: white; padding-left: 1px; padding-top: 1px;"-->
<!--                    @input="changeCheckedAllProducts"-->
<!--                />-->
              </th>
              <th style="width: 100px;">{{ $t('productForCell_scannedCount.localization_value.value') }}</th>
              <th>{{ $t('fbm_ProductName.localization_value.value') }}</th>
              <th v-if="price">{{ $t('fbm_ProductPrice.localization_value.value') }}</th>
              <th v-if="description">{{ $t('fbm_ProductDescription.localization_value.value') }}</th>
              <th v-if="count">{{ $t('fbm_ItemsQty.localization_value.value') }}</th>
              <th style="width: 150px;">{{ $t('fba_Cells.localization_value.value') }}</th>
              <th v-if="hsCode" style="width: 85px;">{{ $t('fbm_ProductHsCode.localization_value.value') }}</th>
              <th style="width: 120px;"></th>
            </tr>
            </thead>
            <tbody>

            <tr
                v-for="(item, index) in productsForCellArray"
                :key="index"
            >
              <td>
                <ProductCheckedBlock
                    v-if="showProductCheckbox"
                    :item="item"
                    @changeProductChecked="(val) => changeProductChecked(val, item)"
                />
              </td>
              <td v-bind:class="{
                'red-color' : item.data.scannedCount !== item.data.productCount,
                'green-color' : item.data.scannedCount === item.data.productCount
              }">
                {{ item.data.scannedCount }} / {{ item.data.productCount }}
              </td>
              <td>
                <div class="table-row ellipsis max-200">
                  <ValueHelper
                      :value="item.data.Product"
                      :deep="'product.productObject.name_for_fba'"
                  />
                </div>
              </td>

              <td v-if="count">
                <template>
                  {{ item.data.Product.product.count }}
                </template>

              </td>
              <td v-if="price">
                {{ item.data.Product.product.price }}
              </td>
              <td v-if="description">
                {{ item.data.Product.product.description }}
              </td>
              <td class="white-space-nowrap">
                <template v-if="cells = getCellsInTable(item)">
                  {{ cells.text }}
                  <span v-if="cells.count > 0" class="site-link cursor-initial">+ {{ cells.count }}</span>
                </template>

              </td>
              <td v-if="hsCode">
                {{ item.data.Product.product.hsCode }}
              </td>
              <td>
                <div class="manage-block">
                  <LinkButton
                      :value="$t('common_edit.localization_value.value')"
                      :type="'edit'"
                      class="mr-3"
                      @click.native="openProductForCellPopup(index)"
                  />
                  <LinkButton
                      :value="$t('common_delete.localization_value.value')"
                      :type="'delete'"
                      @click.native="removeProductForCells(productsForCellArray, index)"
                  />
                </div>

              </td>
            </tr>

            </tbody>
          </table>
        </div>

        <div class="site-table-mobile"
             v-if="isMobileFunc()"
        >
          <div class="site-table-mobile__inner custom-row">
            <div class="custom-col custom-col--50 custom-col--sm-100"
                 v-for="(item, index) in productsForCellArray"
                 :key="index"
            >
              <div class="site-table-mobile__item bg--main-bg">
                <div class="site-table-mobile__head">
                  <ProductCheckedBlock
                      :item="item"
                      @changeProductChecked="(val) => changeProductChecked(val, item)"
                  />
                  <ValueHelper
                      :value="item.data.Product"
                      :deep="'product.productObject.name_for_fba'"
                  />
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{ $t('productForCell_scannedCount.localization_value.value') }}
                    <span v-bind:class="{
                      'red-color' : item.data.scannedCount !== item.data.productCount,
                      'green-color' : item.data.scannedCount === item.data.productCount
                    }">
                      {{ item.data.scannedCount }} / {{ item.data.productCount }}
                    </span>
                  </div>
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{ $t('fbm_ProductName.localization_value.value') }}
                    <span v-bind:class="{
                      'red-color' : item.data.scannedCount !== item.data.productCount,
                      'green-color' : item.data.scannedCount === item.data.productCount
                    }">
                      <ValueHelper
                          :value="item.data.Product"
                          :deep="'product.productObject.name_for_fba'"
                      />
                    </span>
                  </div>
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{ $t('fba_Cells.localization_value.value') }}
                    <span>
                      <template v-if="cells = getCellsInTable(item)">
                        {{ cells.text }}
                        <span v-if="cells.count > 0" class="site-link cursor-initial">+ {{ cells.count }}</span>
                      </template>
                    </span>
                  </div>
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col d-flex align-items-center">
                    {{ $t('common_manage.localization_value.value') }}
                    <div class="ml-2 d-flex">
                      <LinkButton
                          :value="$t('common_edit.localization_value.value')"
                          :type="'edit'"
                          class="mr-3"
                          @click.native="openProductForCellPopup(index)"
                      />
                      <LinkButton
                          :value="$t('common_delete.localization_value.value')"
                          :type="'delete'"
                          @click.native="removeProductForCells(productsForCellArray, index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_AddProduct'])"></div>
        <MainButton class="wfc secondary"
                    :value="$t('common_AddProduct.localization_value.value')"
                    :ico="'ico'"
                    @click.native="openProductPopup"
        >
          <template slot="ico">
            <PlusAccentIconSVG class="btn-icon-plus"/>
          </template>
        </MainButton>
      </div>
    </div>

    <!-- ****  ADD NEW PRODUCT **** -->
    <ProductsPopup
        v-if="isModalProductsPopup"
        :OrderModel="OrderModel"
        :user="OrderUser"
        :productsDefault="products"
        :productLabel="productLabel"
        :showProductDescription="description"
        :showProductPrice="price"
        :byStorageId="byStorageId"
        @removeProduct="data => $emit('removeProduct', data)"
        @changeProductName="data => $emit('changeProductName', data)"
        @changeProductQuantity="data => $emit('changeProductQuantity', data)"
        @addProduct="$emit('addProduct')"
        @close="changeProductsPopup(false)"
    />

    <!-- **** MANAGE CELLS **** -->
    <ProductForCellPopup
        v-if="isModalProductForCellPopup"
        :user="OrderUser"
        :productForCell="OrderModel.data.ProductForCell"
        :productForCellItem="productsForCellArray[productIndex]"
        :productsForCellArray="productsForCellArray"
        :arrayIndex="productIndex"
        :damaged="false"
        :price="price"
        :count="count"
        :description="description"
        :hsCode="hsCode"
        @close="changeProductForCellPopup(false)"
    />
  </div>
</template>

<script>
import LinkButton from "../../../UI/buttons/LinkButton/LinkButton";
import {mixinDetictingMobile} from "../../../../mixins/mobileFunctions";
import MainButton from "../../../UI/buttons/MainButton/MainButton";
import PlusAccentIconSVG from "../../../../../public/img/UI-group/plus-accent-icon.svg?inline";
import ProductForCellPopup from "../../../coreComponents/Popups/ProductForCellPopup/ProductForCellPopup";
import {productForCellMixin} from "../../../../mixins/productMixins/productForCellMixin";
import ValueHelper from "../../../coreComponents/ValueHelper/ValueHelper";
import ProductCheckedBlock from "../ProductCheckedBlock/ProductCheckedBlock";
import ProductsPopup from "../ProductsPopup/ProductsPopup";

/**
 * @removeProduct
 * @changeProductName
 * @changeProductQuantity
 * @addProduct
 */

export default {
  name: "ProductsAdminBlock",
  components: {
    ProductCheckedBlock,
    ValueHelper,
    LinkButton,
    MainButton,
    PlusAccentIconSVG,
    ProductsPopup,
    ProductForCellPopup,
  },

  mixins: [
    mixinDetictingMobile,
    productForCellMixin,
  ],

  props: {
    OrderModel: Object,
    productsForCellArray: Array,
    OrderUser: Object,
    products: Array,

    productLabel: String,
    price: {
      type: Boolean,
      default: true,
    },
    count: {
      type: Boolean,
      default: true,
    },
    description: {
      type: Boolean,
      default: true,
    },
    hsCode: {
      type: Boolean,
      default: true,
    },
    showProductCheckbox: {
      type: Boolean,
      default: true,
    },
    byStorageId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      isModalProductsPopup: false,
      isModalProductForCellPopup: false,

      productIndex: -1,
    }
  },

  methods: {

    // changeCheckedAllProducts(val) {
    //   this.productsForCellArray.map(item => {
    //     this.changeProductChecked(val, item)
    //   })
    // },

    openProductForCellPopup(index = false) {
      if (index >= 0) {
        this.productIndex = index
      } else {
        this.productIndex = -1
      }
      this.changeProductForCellPopup(true)
    },

    changeProductsPopup(open) {
      if (open === false) {
        this.$emit('recalculateFees')
      }
      this.isModalProductsPopup = open
    },
    changeProductForCellPopup(open) {
      this.isModalProductForCellPopup = open
      if (!open) {
        this.$emit('recalculateFees')
      }
    },

    openProductPopup() {
      this.changeProductsPopup(true)
    },

    removeProductForCells(array, index) {
      this.OrderModel.removeProductForCells(array, index)
      this.$emit('recalculateFees')
    },

    changeProductChecked(checked, item) {
      let productsForCellArray = item.data.UserCellArray
      let productsMixedForCellArray = item.data.MixedCellArray

      if(checked){
        item.allScannedProduct()

        let productsCheckedCountdown = +item.data.productCount

        if(productsForCellArray && productsForCellArray.length > 0) {
          productsForCellArray.forEach(cellItem => {
            if (productsCheckedCountdown !== 0 && +cellItem.data.storageCount > 0) {
              if (+cellItem.data.storageCount >= productsCheckedCountdown) {
                cellItem.data.cellCount = productsCheckedCountdown
                productsCheckedCountdown = 0
              }
              else if (+cellItem.data.storageCount < productsCheckedCountdown) {
                cellItem.data.cellCount = +cellItem.data.storageCount
                productsCheckedCountdown -= +cellItem.data.storageCount
              }
            }
          })
        }

        if(productsMixedForCellArray && productsMixedForCellArray.length > 0) {
          productsMixedForCellArray.forEach(cellItem => {
            if (productsCheckedCountdown !== 0 && +cellItem.data.storageCount > 0) {
              if (+cellItem.data.storageCount >= productsCheckedCountdown) {
                cellItem.data.cellCount = productsCheckedCountdown
                productsCheckedCountdown = 0
              }
              else if (+cellItem.data.storageCount < productsCheckedCountdown) {
                cellItem.data.cellCount = +cellItem.data.storageCount
                productsCheckedCountdown -= +cellItem.data.storageCount
              }
            }
          })
        }

        return item.data.withoutCellEndCounts = productsCheckedCountdown

      } else {
        item.removeAllScannedProduct()

        if(productsForCellArray && productsForCellArray.length > 0) {
          productsForCellArray.forEach(cellItem => {
              cellItem.data.cellCount = 0
          })
        }

        if(productsMixedForCellArray && productsMixedForCellArray.length > 0) {
          productsMixedForCellArray.forEach(cellItem => {
            cellItem.data.cellCount = 0
          })
        }

        if(parseInt(item.data.withoutCellEndCounts) !== 0) {
          item.data.withoutCellEndCounts = 0
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../../../scss/colors";

.order-admin-products {
  .red-color {
    color: $red
  }

  .green-color {
    color: $green
  }
}

</style>
