<template>
  <div class="fragment">
    <AccordBlock>
      <template slot="header">
        <div class="fragment"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['ordersInbound_settings'])"></div>
          {{ $t('ordersInbound_settings.localization_value.value') }}
        </div>
      </template>

      <template slot="body">
        <FBMAdminFields
            :FBM="FBM"
            @reload="$emit('reload')"
        />
      </template>
    </AccordBlock>
  </div>
</template>

<script>
import AccordBlock from "../../../../../../coreComponents/AccordBlock/AccordBlock";
import FBMAdminFields
  from "@/components/modules/OrdersFBMModule/components/components/FBMAdminEditBlocks/AdminFieldsBlock/FBMAdminFields/FBMAdminFields";

export default {
  name: "AdminFieldsBlock",
  components: {
    FBMAdminFields,
    AccordBlock,
  },

  props: {
    FBM: Object,
  },

}
</script>

<style scoped>

</style>
