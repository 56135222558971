<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fbm_id'])"></span>
        <DefaultInput
                :label="$t('fbm_id.localization_value.value')"
                :type="'text'"
                v-model="id"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fbm_Created'])"></span>
        <DatePickerDefault
                :label="$t('fbm_Created.localization_value.value')"
                v-model="date"
        >
          <template slot="body">
            <date-picker
                    v-model="date"
                    ref="datePicker"
                    valueType="format"
                    range
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fbm_WasSentAt'])"></span>
        <DatePickerDefault
                :label="$t('fbm_WasSentAt.localization_value.value')"
                v-model="sentDate"
        >
          <template slot="body">
            <date-picker
                    v-model="sentDate"
                    ref="datePicker"
                    valueType="format"
                    range
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>
        <SearchSelect
                :options="options"
                :label="$t('shop_user.localization_value.value')"
                :optionsLabel="'email.contacts[0]'"
                :functionSearch="functionSearchUserFilter"
                @change="changeUsersFilter"
                :selected="userName"
                :typeSelect="'users'"
                :userEmail="true"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fbm_Recepient'])"></span>
        <DefaultInput
                :label="$t('fbm_Recepient.localization_value.value')"
                :type="'text'"
                v-model="recipient"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fbm_PayPalTransactions'])"></span>
        <DefaultInput
                :label="$t('fbm_PayPalTransactions.localization_value.value')"
                :type="'text'"
                v-model="paypalTransaction"
        />
      </div>

<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit" @click="editTranslate(['fbm_Lots'])"></span>-->
<!--        <DefaultInput-->
<!--                :label="$t('fbm_Lots.localization_value.value')"-->
<!--                :type="'text'"-->
<!--                v-model="lots"-->
<!--        />-->
<!--      </div>-->

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fbm_Ship'])"></span>
        <DefaultInput
                :label="$t('fbm_Ship.localization_value.value')"
                :type="'text'"
                v-model="ship"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fbm_TrackingNumber'])"></span>
        <DefaultInput
                :label="$t('fbm_TrackingNumber.localization_value.value')"
                :type="'text'"
                v-model="trackingNumber"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fbm_DHLTracknumber'])"></span>
        <DefaultInput
            :label="$t('fbm_DHLTracknumber.localization_value.value')"
            :type="'text'"
            v-model="consoTrackingNumber"
        />
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fbm_ConsoID'])"></span>
        <DefaultInput
                :label="$t('fbm_ConsoID.localization_value.value')"
                :type="'text'"
                v-model="consoId"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fbm_ShippingPartner'])"></span>
        <DefaultSelect
                :label="$t('fbm_ShippingPartner.localization_value.value')"
                :options="shippingCompanies"
                :optionsLabel="'name'"
                :selected="getSelectedShippingCompany"
                @change="changeShippingCompany"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fbm_ReturnParcelsFilter'])"></span>
        <DefaultSelect
                :label="$t('fbm_ReturnParcelsFilter.localization_value.value')"
                :options="returnGoodsOptions"
                :optionsLabel="'name'"
                :selected="getReturnGoodsSelected"
                @change="changeReturnGoods"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['fbm_PaidFilter'])"></span>
        <DefaultSelect
                :label="$t('fbm_PaidFilter.localization_value.value')"
                :options="paidOptions"
                :optionsLabel="'name'"
                :selected="getPaidTypeSelected"
                @change="changePaidType"
        />
      </div>

      <template v-if="$store.getters.GET_COMMON_AUTHORIZED && $store.getters.GET_COMMON_AUTHORIZED.user && $store.getters.GET_COMMON_AUTHORIZED.user.email
        && $store.getters.GET_COMMON_AUTHORIZED.user.email === 'admin@expressposhta.com'">
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['fbm_expressTrack'])"></span>
          <DefaultInput
              :label="$t('fbm_expressTrack.localization_value.value')"
              :type="'text'"
              v-model="expressTrack"
          />
        </div>

        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['fbm_npTrack'])"></span>
          <DefaultInput
              :label="$t('fbm_npTrack.localization_value.value')"
              :type="'text'"
              v-model="npTrack"
          />
        </div>
      </template>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')"  class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {queryFilterMixin} from "@/mixins/routeFilterMixins/queryFilterMixin";

  export default {
    name: "OrdersFBMTableUserFilter",
    components: {
      DefaultSelect,
      SearchSelect,
      FilterBlock,
      MainButton,
      DefaultInput,
      DatePickerDefault,
      DatePicker,
    },

    mixins: [filterFunc, usersFunc, queryFilterMixin],

    props: {
      navTabs: Object,
      showFilter: Boolean,
      filterGetParams: Object,
    },

    computed: {
      getSelectedShippingCompany() {
        if(this.shippingCompanyId !== ''){
          return {
            id: this.shippingCompanyId,
            name: this.shippingCompanyName
          }
        }

        return null
      },

      getReturnGoodsSelected() {
        let foundSelected = this._.find(this.returnGoodsOptions, {value: this.returnGoods})
        return foundSelected ? foundSelected : ''
      },

      getPaidTypeSelected() {
        let foundSelected = this._.find(this.paidOptions, {value: this.paid})
        return foundSelected ? foundSelected : ''
      },
    },

    data(){
      return {

        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',
        sentDate: this.filterGetParams.sentDate ? this.generateFilterDate(this.filterGetParams.sentDate, 'MM/DD/YY'): '',
        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        recipient: this.filterGetParams.recipient ? this.filterGetParams.recipient : '',
        paypalTransaction: this.filterGetParams.paypalTransaction ? this.filterGetParams.paypalTransaction : '',
        lots: this.filterGetParams.lots ? this.filterGetParams.lots : '',
        ship: this.filterGetParams.ship ? this.filterGetParams.ship : '',
        trackingNumber: this.filterGetParams.trackingNumber ? this.filterGetParams.trackingNumber : '',
        consoTrackingNumber: this.filterGetParams.consoTrackingNumber ? this.filterGetParams.consoTrackingNumber : '',
        consoId: this.filterGetParams.consoId ? this.filterGetParams.consoId : '',

        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

        shippingCompanyId: this.filterGetParams.shippingCompanyId ? this.filterGetParams.shippingCompanyId : '',
        shippingCompanyName: this.filterGetParams.shippingCompanyName ? this.filterGetParams.shippingCompanyName : '',

        returnGoods: this.filterGetParams.returnGoods ? this.filterGetParams.returnGoods : '',
        paid: this.filterGetParams.paid ? this.filterGetParams.paid : '',

        expressTrack: this.filterGetParams.expressTrack ? this.filterGetParams.expressTrack : '',
        npTrack: this.filterGetParams.npTrack ? this.filterGetParams.npTrack : '',

        filterCounts: [
          'date',
          'sentDate',
          'id',
          'recipient',
          'paypalTransaction',
          'lots',
          'ship',
          'trackingNumber',
          'consoTrackingNumber',
          'consoId',

          'userName',
          'userId',

          'shippingCompanyId',
          'shippingCompanyName',

          'returnGoods',
          'paid',

          'expressTrack',
          'npTrack',
        ],

        options:[],
        shippingCompanies: [],
        returnGoodsOptions: [
          {
            name: this.$t('fbm_ReturnParcelsAll.localization_value.value'),
            value: ''
          },
          {
            name: this.$t('fbm_ReturnParcels.localization_value.value'),
            value: '1'
          }
        ],
        paidOptions: [
          {
            name: this.$t('fbm_paidAll.localization_value.value'),
            value: '-1'
          },
          {
            name: this.$t('fbm_paidYes.localization_value.value'),
            value: '1'
          },
          {
            name: this.$t('fbm_paidNo.localization_value.value'),
            value: '0'
          },
        ],
      }
    },


    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.sentDate = newVal.sentDate ? this.generateFilterDate(newVal.sentDate, 'MM/DD/YY') : ''
        this.id = newVal.id ? newVal.id : ''
        this.recipient = newVal.recipient ? newVal.recipient : ''
        this.paypalTransaction = newVal.paypalTransaction ? newVal.paypalTransaction : ''
        this.lots = newVal.lots ? newVal.lots : ''
        this.ship = newVal.ship ? newVal.ship : ''
        this.trackingNumber = newVal.trackingNumber ? newVal.trackingNumber : ''
        this.consoTrackingNumber = newVal.consoTrackingNumber ? newVal.consoTrackingNumber : ''
        this.consoId = newVal.consoId ? newVal.consoId : ''

        this.shippingCompanyId = newVal.shippingCompanyId ? newVal.shippingCompanyId : ''
        this.shippingCompanyName = newVal.shippingCompanyName ? newVal.shippingCompanyName : ''

        this.userName = newVal.userName ? newVal.userName : ''
        this.userId = newVal.userId ? newVal.userId : ''

        this.returnGoods = newVal.returnGoods ? newVal.returnGoods : ''
        this.paid = newVal.paid ? newVal.paid : ''

        this.expressTrack = newVal.expressTrack ? newVal.expressTrack : ''
        this.npTrack = newVal.npTrack ? newVal.npTrack : ''
      },

    },

    mounted() {
      let myQuery = this.createQueryBuilderObject({withUserNewMark: false})

      let url = '?' + myQuery.limit(100000).page(1).url().split('?')[1]

      this.$store.dispatch('getShippingCompanyFBM', url).then(response => {
        this.shippingCompanies = this.getRespPaginateData(response)
      })
    },



    methods: {
      closeFilter() {
        this.$emit('close')
      },


      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')
        data.sentDate = this.changeDateParams(this, 'sentDate')

        this.$emit('changeFilter', data)
      },

      changeShippingCompany(val) {
        this.shippingCompanyId = val.id
        this.shippingCompanyName = val.name
      },

      changeReturnGoods(val) {
        this.returnGoods = val.value
      },

      changePaidType(val) {
        this.paid = val.value
      },
    },

  }
</script>

<style scoped>

</style>
