<template>
  <div class="fragment">
    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator"
           v-bind:class="{'loading-tabs': $store.getters.getOrdersFBMLoading === true,}">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-if="item.permissionVisible"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <span class="admin-edit" @click="editTranslate([item.label])"></span>
              <template v-if="item.ico">
                <img :src="item.ico" alt="company-ico" :width="item.icoWidth">
              </template>
              <template v-else>
                {{ $t(item.label + '.localization_value.value')}}
              </template>
            </div>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton @toggleFilter="$emit('toggleFilter')" :count="countFilterParams" :showFilter="showFilter"/>
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block ">
        <div class="btn-left-block-i"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['common_selectAll'])"></span>
          <DefaultCheckbox
                  :label="$t('common_selectAll.localization_value.value')"
                  @input="(val) => $emit('selectAll', val)"
          />
        </div>
        <div class="btn-left-block-i">
          <div class="btn-left-block__list">
            <div class="btn-left-block__list__i"
                 v-if="_.has(this.currentPermissions, PERMISSIONS.CONSOLIDATION_GENERAL)"
            >
              <TopTableBoxesBtn
                      class="btn-left-block-i--link"
                      :type="'boxes'"
                      :title="'common_CreateConsolidation'"
                      @click.native="showChooseConsoTypePopup"
              />
            </div>
            <div class="btn-left-block__list__i"
                 v-if="navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active || isAdmin">
              <TopTableBoxesBtn
                      class="btn-left-block-i--link"
                      :type="'pdf'"
                      :title="'common_GeneratePdf'"
                      @click.native="$emit('generatePdf')"
              />
            </div>
            <div class="btn-left-block__list__i"
                 v-if="navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active || isAdmin">
              <TopTableBoxesBtn
                  class="btn-left-block-i--link"
                  :type="'pdf'"
                  :title="'fbm_GenerateBarcodePdf'"
                  @click.native="$emit('generateBarcodePdf')"
              />
            </div>
            <div class="btn-left-block__list__i"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_ThermalPrint','fbm_ThermalPrintInfo'])"></div>
              <TopTableBoxesBtn
                  :withTranslate="false"
                      class="btn-left-block-i--link"
                      :type="'print'"
                      :title="'common_ThermalPrint'"
                      @click.native="$emit('thermalPrint')"
              />
                    <v-popover
                        class="site-tooltip"
                        offset="5"
                        placement="top"
                        trigger="hover"
                    >
                      <TooltipBtn/>
                      <template slot="popover">
                        <p>{{$t('fbm_ThermalPrintInfo.localization_value.value')}}</p>
                      </template>
                    </v-popover>
            </div>
            <div class="btn-left-block__list__i"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_MailLabel','fbm_MailLabelInfo'])"></div>
              <TopTableBoxesBtn
                  :withTranslate="false"
                      class="btn-left-block-i--link"
                      :type="'dog'"
                      :title="'common_MailLabel'"
                      @click.native="$emit('getMailLabel')"
              />
              <v-popover
                  class="site-tooltip"
                  offset="5"
                  placement="top"
                  trigger="hover"
              >
                <TooltipBtn/>
                <template slot="popover">
                  <p>{{$t('fbm_MailLabelInfo.localization_value.value')}}</p>
                </template>
              </v-popover>
            </div>
            <div class="btn-left-block__list__i"
                 v-if="isAdmin">
              <TopTableBoxesBtn
                  class="btn-left-block-i--link"
                  :type="'pdf'"
                  :title="'fbm_royalMailManifest'"
                  @click.native="$emit('royalMailManifest')"
              />
            </div>
            <div class="btn-left-block__list__i"
                 v-if="isAdmin">
              <router-link :to="$store.getters.GET_PATHS.ordersFBMManifests">
                <TopTableBoxesBtn
                    class="btn-left-block-i--link"
                    :type="'pdf'"
                    :title="'fbm_royalMailManifestList'"
                />
              </router-link>
            </div>
            <div class="btn-left-block__list__i">
              <TopTableBoxesBtn
                  v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active && isUserPrepayment"
                      class="btn-left-block-i--link"
                      :type="'dollar'"
                      :title="'fbm_pay'"
                      @click.native="$emit('payFBM')"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="btn-right-block">

        <div class="d-flex"
             v-if="navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active ||
             ((navTabs[FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name].active || navTabs[FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name].active) && isAdmin)"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['fbm_OrderPlanner'])"></span>
          <SwitchCheckbox
                  class="mr-4 btn-right-block-i btn-right-block-i--switch brown fw-medium table-head-style"
                  :label="$t('fbm_OrderPlanner.localization_value.value')"
                  :value="orderPlanner"
                  @mouseup.native="openCalendarFunc"
          />
          <OrderPlannerButton
                  :isOpen="openCalendar"
                  :value="dateCalendar"
                  @datePickerBtn="changeOrderPlannerDate"
                  @changeDate="changeDate"
                  @cancel="cancelCalendar"
          />
        </div>


        <div v-if="navTabs.all.active"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
        >
          <div class="admin-edit" @click="editTranslate(['common_AddNew'])"></div>
          <MainButton class="btn-fit-content btn-right-block-i"
                      :value="$t('common_AddNew.localization_value.value')"
                      :ico="'ico'"
                      @click.native="showChooseOrderFBMCreatePopup"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus"/>
            </template>
          </MainButton>
        </div>

        <router-link
                v-else
                :to="$store.getters.GET_PATHS.ordersFBMCreate + '/' + activeTabType"
        >
          <MainButton class="btn-fit-content"
                      :value="$t('common_AddNew.localization_value.value')"
                      :ico="'ico'"
          >
<!--                      @click.native="showChooseExpressOrderPopup"-->
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus"/>
            </template>
          </MainButton>
        </router-link>

      </div>
    </div>


    <ChooseOrderFBMCreatePopup
            @close="closeChooseOrderFBMCreatePopup"
            v-if="isModalChooseOrderFBMCreatePopup"
            :consolidation="checkCreateConsolidation()"
            :warehouse="checkCreateWarehouse()"
            :label="checkCreateLabel()"
    />


    <WrongOrdersForPopup
            v-if="isModalWrongOrdersForPopup"
            :invalidOrders="invalidOrders"
            :validOrderIds="validOrderIds"
            :type="consolidationType"
            @close="closeWrongOrdersForPopup"
    />

    <ChooseConsolidationTypePopup
        @close="isCreateConsolidationFromOrderPopup = false"
        @checkOrdersForConsolidationType="checkOrdersForConsolidationType"
        v-if="isCreateConsolidationFromOrderPopup"
    />


  </div>
</template>

<script>
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import SwitchCheckbox from "../../../../../../UI/checkboxes/SwitchCheckbox/SwitchCheckbox";
  import OrderPlannerButton from "../../../../../../UI/buttons/OrderPlannerButton/OrderPlannerButton";
  import ChooseOrderFBMCreatePopup
    from "../../../../../../coreComponents/Popups/ChooseOrderFBMCreatePopup/ChooseOrderFBMCreatePopup";
  import TopTableBoxesBtn from "../../../../../../coreComponents/Buttons/TopTableBoxesBtn/TopTableBoxesBtn";
  import WrongOrdersForPopup from "../../../../popups/WrongOrdersForPopup/WrongOrdersForPopup";
  import {
    FBM_ORDER_TYPES,
    GLOBAL24_DELIVERY_SERVICE,
    POSTNL_DELIVERY_SERVICE,
  } from "../../../../../../../staticData/staticVariables";
  import ChooseConsolidationTypePopup from "../../../../popups/ChooseConsolidationTypePopup/ChooseConsolidationTypePopup";
  import {routeFilter} from "../../../../../../../mixins/routeFilterMixins/routeFilter";
  import {ORDERS_CONSOLIDATION_TYPE, DPD_DELIVERY_SERVICE} from "../../../../../../../staticData/staticVariables";
  import TooltipBtn from "@/components/UI/tooltips/TooltipBtn/TooltipBtn";


  export default {
    name: "OrdersFBMTableUserHead",
    components: {
      TooltipBtn,
      ChooseConsolidationTypePopup,
      MainButton,
      ToggleFilterButton,
      DefaultCheckbox,
      PlusIconSVG,
      TopTableBoxesBtn,
      SwitchCheckbox,
      OrderPlannerButton,
      ChooseOrderFBMCreatePopup,
      WrongOrdersForPopup,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    mixins: [
        routeFilter
    ],

    data() {
      return {
        isCreateConsolidationFromOrderPopup: false,
        isModalChooseOrderFBMCreatePopup: false,
        isModalWrongOrdersForPopup: false,
        orderPlanner: false,
        openCalendar: false,
        dateCalendar: null,

        FBM_ORDER_TYPES: FBM_ORDER_TYPES,
        ORDERS_CONSOLIDATION_TYPE: ORDERS_CONSOLIDATION_TYPE,
        DPD_DELIVERY_SERVICE: DPD_DELIVERY_SERVICE,

        validOrderIds: [],
        invalidOrders: [],
        consolidationType: '',
      }
    },

    computed: {
      activeTabType() {
        return this._.find(this.navTabs, {active: true}).name
      },
    },

    watch: {
      getUserPlannedDate(val){
        if(val && this.navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active){
          this.changeDate(this.$moment(val).format('YYYY-MM-DD'))
          this.orderPlanner = true
        }
      },

      getUserPlannedDateWarehouse(val){
        if(val && this.navTabs[FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name].active){
          this.changeDate(this.$moment(val).format('YYYY-MM-DD'))
          this.orderPlanner = true
        }
      },

      getUserPlannedDateConsolidation(val){
        if(val && this.navTabs[FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name].active){
          this.changeDate(this.$moment(val).format('YYYY-MM-DD'))
          this.orderPlanner = true
        }
      },

      activeTabType(val) {
        if (val === FBM_ORDER_TYPES.FBM_ORDER_LABEL.name && this.getUserPlannedDate) {
          this.changeDate(this.$moment(this.getUserPlannedDate).format('YYYY-MM-DD'))
          this.orderPlanner = true
        }
        else if (val === FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name && this.getUserPlannedDateWarehouse) {
          this.changeDate(this.$moment(this.getUserPlannedDateWarehouse).format('YYYY-MM-DD'))
          this.orderPlanner = true
        }
        else if (val === FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name && this.getUserPlannedDateConsolidation) {
          this.changeDate(this.$moment(this.getUserPlannedDateConsolidation).format('YYYY-MM-DD'))
          this.orderPlanner = true
        }
        else {
          this.changeDate(null)
          this.orderPlanner = false
        }
      }
    },

    mounted() {
      // document.getElementById('app').addEventListener('click', () => {
      //   console.log(this.openCalendar);
      //   if(this.openCalendar){
      //     this.openCalendar = false
      //   }
      // })

      if(this.getUserPlannedDate && this.navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active){
        this.changeDate(this.$moment(this.getUserPlannedDate).format('YYYY-MM-DD'))
        this.orderPlanner = true
      }
      else if (this.getUserPlannedDateWarehouse && this.navTabs[FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name].active) {
        this.changeDate(this.$moment(this.getUserPlannedDateWarehouse).format('YYYY-MM-DD'))
        this.orderPlanner = true
      }
      else if (this.getUserPlannedDateConsolidation && this.navTabs[FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name].active) {
        this.changeDate(this.$moment(this.getUserPlannedDateConsolidation).format('YYYY-MM-DD'))
        this.orderPlanner = true
      }
    },

    methods: {

      checkCreateConsolidation() {
        if(this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_FBM_CONSOLIDATION)){
          return `/create/${FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name}`
        }
      },
      checkCreateWarehouse() {
        if(this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_FBM_WAREHOUSE)){
          return `/create/${FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name}`
        }
      },
      checkCreateLabel() {
        if(this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_FBM_LABEL)){
          return `/create/${FBM_ORDER_TYPES.FBM_ORDER_LABEL.name}`
        }
      },

      openCalendarFunc() {
        if (!this.openCalendar && this.orderPlanner) {
          this.orderPlanner = false
          this.openCalendar = false

          this.changeDate(null)

          let data = {
            'planned_date': null
          }

          if (this.navTabs[FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name].active) {
            data = {
              'order_fbm_warehouse_planned_date' : null
            }
          }
          else if (this.navTabs[FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name].active) {
            data = {
              'order_fbm_consolidation_planned_date' : null
            }
          }

          this.updateOrderPlannerDate(data)
        } else {
          this.openCalendar = true
        }
      },

      cancelCalendar() {
        this.openCalendar = false
      },

      changeDate(date) {
        this.dateCalendar = date
      },

      showChooseOrderFBMCreatePopup() {
        this.isModalChooseOrderFBMCreatePopup = true
      },

      closeChooseOrderFBMCreatePopup() {
        this.isModalChooseOrderFBMCreatePopup = false
      },

      changeOrderPlannerDate() {
        this.orderPlanner = true
        this.openCalendar = false

        let data = {
          'planned_date': this.dateCalendar
        }

        if (this.navTabs[FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name].active) {
          data = {
            'order_fbm_warehouse_planned_date' : this.dateCalendar
          }
        }
        else if (this.navTabs[FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name].active) {
          data = {
            'order_fbm_consolidation_planned_date' : this.dateCalendar
          }
        }

        this.updateOrderPlannerDate(data)
      },

      updateOrderPlannerDate(data){
        let storeAction = 'updateUserPlannedDate'

        if (this.navTabs[FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name].active) {
          storeAction = 'updateUserPlannedDateWarehouse'
        }
        else if (this.navTabs[FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name].active) {
          storeAction = 'updateUserPlannedDateConsolidation'
        }

        this.$store.dispatch(storeAction, data).then(() => {
          this.$store.dispatch('updateCommonAuthorizedData')
        })
      },

      showWrongOrdersForPopup() {
        this.isModalWrongOrdersForPopup = true
      },

      closeWrongOrdersForPopup() {
        this.isModalWrongOrdersForPopup = false
      },

      showChooseConsoTypePopup() {
        let exportIds = this.getCheckedRows('row-name')

        if (exportIds.length === 0) return

        this.isCreateConsolidationFromOrderPopup = true

      },

      checkOrdersForConsolidationType(type) {
        let exportIds = this.getCheckedRows('row-name')
        console.log(type, exportIds);

        if (exportIds.length === 0) return

        const query = new this.Query();
        let url = '?';
        let myQuery = query
            .for('posts')

        //select orders with consolidation and label type id
        myQuery.where('OrderFbmTypeId', '1,3')
        // myQuery.where('user_id', this.consolidation.data.User.getUserId())
        if (this.$store.getters.GET_COMMON_AUTHORIZED.user?.company_owner?.id) {
          myQuery.where('user_id', this.$store.getters.GET_COMMON_AUTHORIZED.user?.company_owner?.id)
        }
        myQuery.where('consolidationOrderQuery', '1')
        if (type === ORDERS_CONSOLIDATION_TYPE.DPD) {
          let queryString = this.DPD_DELIVERY_SERVICE.id + ',' + POSTNL_DELIVERY_SERVICE.id + ',' + GLOBAL24_DELIVERY_SERVICE.id
          myQuery.where('deliveryService', queryString)
        }
        else if(type === ORDERS_CONSOLIDATION_TYPE.FEDEX) {
          let queryString = this.DPD_DELIVERY_SERVICE.id + ',' + GLOBAL24_DELIVERY_SERVICE.id
          myQuery.where('exemptManyDeliveryServices',  queryString)
        }

        url = url + myQuery.limit(100000).page(1).url().split('?')[1]

        this.isCreateConsolidationFromOrderPopup = false

        this.$store.dispatch('fetchOrdersFBMListForConsolidation', url).then(response => {
          //filter orders in draft
          let filteredOrdersFBM = this.getRespPaginateData(response)
          let filteredOrdersFBMIds = filteredOrdersFBM.map(item => {
            return item.id
          })

          console.log('orders for popup', filteredOrdersFBMIds);

          exportIds = exportIds.map(item => {
            return +item
          })

          //filter selected ids if they aren't in the allowed orders array
          let invalidOrderIds = exportIds.filter(item => {
            return !filteredOrdersFBMIds.includes(+item)
          })

          console.log('orders for popup2', invalidOrderIds);

          if (invalidOrderIds.length) {
            this.validOrderIds = exportIds.filter(item => {
              return !invalidOrderIds.includes(item)
            })

            this.invalidOrders = this.$store.getters.getOrdersFBM.filter(item => {
              return invalidOrderIds.includes(item.id)
            })

            // console.log(invalidOrderIds, exportIds, this.$store.getters.getOrdersFBM, this.validOrderIds, this.invalidOrders);

            this.consolidationType = type

            this.isModalWrongOrdersForPopup = true
          }
          else {
            this.$router.push({path: this.$store.getters.GET_PATHS.ordersConsolidationCreate + '/' + type, query: {setInitOrders: exportIds.join(',')}})
          }
        }).catch(error => this.createErrorLog(error))
      },

    }
  }
</script>

<style scoped>

  .content-tabs__ico .admin-edit {
    top: 0;
    left: -15px;
  }

  @media (min-width: 680px) {
    .btn-left-block {
      flex-wrap: wrap;
      max-width: 60%;
    }

    .btn-left-block-i {
      margin-left: 0 !important;
      margin-right: 15px;
    }
  }

  @media (max-width: 680px) {
    .btn-right-block {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
    }

    .btn-right-block .btn-right-block-i {
      /*margin-top: 15px;*/
      margin-right: 0 !important;
    }

    .btn-right-block .btn-right-block-i:last-child {
      margin-top: 0;
    }

    .btn-left-block {
      display: flex;
      flex-direction: column-reverse;
      max-width: 55%;
    }

    .btn-left-block-i {
      margin-left: 0;
      margin-top: 10px;
    }

    .btn-left-block-i:last-child {
      margin-top: 0;
    }

    .btn-left-block-i--link {
      font-size: 12px;
    }

    /*.light-bg-block {*/
      /*align-items: flex-end;*/
      /*width: calc(100% + 30px);*/
      /*margin: 0 -15px;*/
    /*}*/
  }
</style>
