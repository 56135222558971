<template>
  <div class="fragment">
    <div class="order-create__section"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
      'fbm_ShippingPartner',
      'fbm_ShippingPartnerToltip'
    ])"></div>

      <Shipping2
          :reloadKey="reloadKey"
          v-if="FBM.data.shippingCompanies.length > 0"
          :FBM="FBM"
          :isAdminManage="false"
          :shippingCompanyData="FBM.data.shippingCompanies"
          @showShippingCalculatorPopup="showShippingCalculatorPopup"
          @showSignatureInfoPopup="showSignatureInfoPopup"
      />

      <template v-else>
        <div class="waiting-for-data__block"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
            'fbm_waitingDataForSP',
          ])"></div>
          {{$t('fbm_waitingDataForSP.localization_value.value')}}
        </div>
<!--        <MainButton-->
<!--            class="wfc mb-3"-->
<!--            v-bind:class="{'disabled-btn' : !FBM.FBMFirstStepValidate() || disableBtn}"-->
<!--            :value="$t('fbm_ShippingPartner.localization_value.value')"-->
<!--            @click.native="getShippingFunc()"-->
<!--        />-->
      </template>

      <div class="row">
        <VatNumberBlock
            :FBM="FBM"
            :fileWidthClass="'custom-col--50'"
        />
      </div>


      <template v-if="!(globalCompany && isConsolidation)">
        <div class="order-create__section-label section-label"
             v-if="!isWarehouseLodz && !isWarehouseCambridge"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
          'fbm_Insurance',
          'fbm_InsuranceToltip'
        ])"></div>
          {{$t('fbm_Insurance.localization_value.value')}}
          <v-popover
              class="site-tooltip"
              :disabled="!tooltipAddProductActive"
              offset="5"
              placement="top"
              trigger="hover"
          >

            <TooltipBtn/>

            <template slot="popover">
              <p><b>{{$t('fbm_Insurance.localization_value.value')}}</b></p>
              <p>{{$t('fbm_InsuranceToltip.localization_value.value')}}</p>
            </template>
          </v-popover>
        </div>

        <InsuranceBlock
            :FBM="FBM"
            @changeInsurance="reload"
        />
      </template>

      <div class="order-create__section-label section-label"
           v-if="!isLabel && !(isConsolidation && globalCompany)"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_Packaging'])"></div>
        {{$t('fbm_Packaging.localization_value.value')}}
      </div>

      <Packaging
          v-if="!isLabel && !(isConsolidation && globalCompany)"
          :FBM="FBM"
      />

      <GiftMassage
          v-if="isWarehouse"
          :FBM="FBM"
          :giftPapers="giftPapers"
      />

      <div class="order-create__section-label section-label"
           v-if="isWarehouse"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_giftPackaging'])"></div>
        {{$t('fbm_giftPackaging.localization_value.value')}}
      </div>

      <GiftPackaging
          v-if="isWarehouse"
          :FBM="FBM"
      />



      <SignatureInfoPopup
          @close="closeSignatureInfoPopup"
          v-if="isModalSignatureInfoPopup"
      />


    </div>

    <ShippingCalculatorPopup
        @close="closeShippingCalculatorPopup"
        v-if="isModalShippingCalculatorPopup"
        :fromUSA="isWarehouse"
        :country="FBM.data.DeliveryPayPal.delivery.country"
        :dimensions="FBM.data.Dimensions"
        :city="FBM.data.DeliveryPayPal.delivery.city"
        :region="FBM.data.DeliveryPayPal.delivery.region"
        :zip="FBM.data.DeliveryPayPal.delivery.zip"
    />
  </div>

</template>

<script>


  import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import {VPopover} from "v-tooltip";
  // import TextareaDefault from "../../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import SignatureInfoPopup from "../../../../popups/SignatureInfoPopup/SignatureInfoPopup";
  import ShippingCalculatorPopup from "../../../../popups/ShippingCalculatorPopup/ShippingCalculatorPopup";
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  // import TitleHorizontal from "../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
  import Packaging from "./Packaging/Packaging";
  // import Shipping from "../../chunks/Shipping/Shipping";
  import InsuranceBlock from "../../chunks/InsuranceBlock/InsuranceBlock";
  import {FBMMixins} from "../../../../../../../mixins/FBMMixins/FBMMixins";
  import {USA_COUNTRY_ID} from "../../../../../../../staticData/staticVariables";
  import VatNumberBlock from "../../chunks/VatNumberBlock/VatNumberBlock";
  import GiftPackaging from "./GiftPackaging/GiftPackaging";
  import GiftMassage
    from "@/components/modules/OrdersFBMModule/components/components/FBMUserBlocks/StepThree/GiftMassage/GiftMassage";
  import Shipping2 from "@/components/modules/OrdersFBMModule/components/components/chunks/Shipping2/Shipping2";
  // import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import FBMAdminFields
  //   from "@/components/modules/OrdersFBMModule/components/components/FBMAdminEditBlocks/AdminFieldsBlock/FBMAdminFields/FBMAdminFields";


  export default {
    name: "StepThree",
    components: {
      Shipping2,
      // MainButton,
      GiftPackaging,
      // FBMAdminFields,
      VatNumberBlock,
      InsuranceBlock,
      // Shipping,
      Packaging,
      GiftMassage,
      TooltipBtn,
      VPopover,
      // TextareaDefault,
      SignatureInfoPopup,
      ShippingCalculatorPopup,
      // TitleHorizontal,
    },

    mixins: [mixinDetictingMobile, FBMMixins],

    props: {
      FBM: Object,
      user: Object,
      etsyCountry: [Object, String],
      paypalCountry: [Object, String],
      edit: Boolean,
    },

    watch: {
      /**
       * If load user and not admin or order type Edit
       */
      // orderUser(newVal) {
      //   if(newVal !== '' && (!this.isAdmin || this.$route.params.id) && this.edit === true){
      //     this.getShippingData().then(() => {
      //       this.getShippingPrices()
      //       this.gotShippingData = true
      //     })
      //   }
      // },

      /**
       * If change or choose Country
       */
      selectedCountry() {
        if (this.currentPersonalContactName && this.currentDeliveryZipCode && this.isSetWarehouse) {
          this.getShippingData().then(() => {
            if (this.FBM.data.useEasyPost && this.isLabel) {
              this.getShippingPrices()
            }
          })
        }
      },

      /**
       * If change recipient name
       */
      currentPersonalContactName() {
        if (this.selectedCountry && this.currentDeliveryZipCode && this.isSetWarehouse) {
          this.getShippingData().then(() => {
            if (this.currentPersonalContactName && this.FBM.data.useEasyPost && this.isLabel) {
              this.getShippingPrices()
            }
          })
        }
      },

      /**
       * If change recipient zip
       */
      currentDeliveryZipCode() {
        if (this.selectedCountry && this.currentPersonalContactName && this.isSetWarehouse) {
          this.getShippingData().then(() => {
            if (this.FBM.data.useEasyPost && this.isLabel) {
              this.getShippingPrices()
            }
          })
        }
      },

      /**
       * If change Storage
       */
      selectedStorage() {
        if (this.selectedCountry && this.currentPersonalContactName && this.isSetWarehouse) {
          this.getShippingData().then(() => {
            // if (this.FBM.data.useEasyPost && this.isLabel) {
              this.getShippingPrices()
            // }
          })
        }
      },
    },

    computed: {
      orderUser(){
        return this.FBM.data.User.userId
      },
      selectedStorage() {
        return this.FBM.data.Order.data.warehouse
      }
    },

    data(){
      return {
        USA_COUNTRY_ID: USA_COUNTRY_ID,

        options: [{}],

        tooltipAddProductActive: true,
        comment: 'Happy New Year! Wish you all best, by darling friend!',

        isModalSignatureInfoPopup: false,
        isModalShippingCalculatorPopup: false,


        radioVal: 0,
        shippingPartner: 1,


        giftPapers: [],

        reloadKey: -1,
        disableBtn: false,
        gotShippingData: false,
      }
    },

    mounted() {
      // this.$store.dispatch('getDeliveryTypeByCountry', this.FBM.getTrsnAddressCountry().id)

      console.log('step 3');
      // this.checkShippingCompany()

      this.$store.dispatch('fetchPackagingGiftPapers', '?filter[enable]=1&page=0&limit=100000').then(response => {
        this.giftPapers = this.getRespPaginateData(response)
      })

      if (this.currentPersonalContactName && this.currentDeliveryZipCode && this.selectedCountry && this.selectedStorage) {
        this.getShippingData().then(() => {
          if (this.FBM.data.useEasyPost && this.isLabel) {
            this.getShippingPrices()
          }
        })
      }

    },

    methods: {
      reload() {
        this.reloadKey = Math.random()
        this.getShippingPrices()
      },

      showSignatureInfoPopup() {
        this.$store.dispatch('getSignaturePrice').then((response) => {
          console.log(this.getRespData(response));
          this.isModalSignatureInfoPopup= true
        })
      },

      closeSignatureInfoPopup() {
        this.isModalSignatureInfoPopup= false
      },

      showShippingCalculatorPopup() {
        this.isModalShippingCalculatorPopup= true
      },

      closeShippingCalculatorPopup() {
        this.isModalShippingCalculatorPopup= false
      },


      changeRadio(val){
        this.radioVal = val
      },

      getShippingFunc() {

        if (!this.FBM.FBMFirstStepValidate()) return

        this.disableBtn = true

        this.getShippingData().then(() => {
          this.getShippingPrices().then(() => {
            this.disableBtn = false
            this.gotShippingData = true
          })
        })
      }
    },
  }
</script>

<style scoped>
.waiting-for-data__block {
  background: #F8F4EE;
  border: 1px solid #BBAD9C;
  padding: 24px 32px 24px;
  display: inline-block;
  margin-bottom: 20px;
  box-sizing: border-box;
  border-radius: 5px;
}
</style>
