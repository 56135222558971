<template>
  <modal
      @close="$emit('close')"
      class="custom-popup wrong-orders-for-popup"
  >
    <template slot="header">
      Signature Required Service
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <p>
          If you want to have a signature from the recepient, you may use our service, but for different countries the fee of this service is different
        </p>
        <br>
        <div class="site-table-wrap table-small">
          <table class="site-table min-w-initial" >
            <thead>
            <tr>
              <th>Country</th>
              <th align="right" class="text-right">Fee, $</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                United Kigdom
              </td>
              <td align="right">3.5</td>
            </tr>
            <tr>
              <td>
                Germany
              </td>
              <td align="right"></td>
            </tr>
            <tr>
              <td>
                Germany
              </td>
              <td align="right">6.5</td>
            </tr>
            <tr>
              <td>
                France
              </td>
              <td align="right"></td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </template>
  </modal>

</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'

  export default {
    name: "SignatureInfoPopup",
    components: {
      Modal,
    },

  }

</script>

<style lang="scss">

</style>
