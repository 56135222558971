<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_PayOrder'])"></div>
        {{$t('fbm_PayOrder.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
       <div class="custom-row">
         <div class="custom-col"
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
           <div class="admin-edit" @click="editTranslate(['fbm_PayOrder'])"></div>
           <b>{{$t('fbm_Balance.localization_value.value')}} :</b> {{balance}}
         </div>
         <div class="custom-col"
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
           <div class="admin-edit" @click="editTranslate(['fbm_PayTotal'])"></div>
           <b>{{$t('fbm_PayTotal.localization_value.value')}} :</b> {{orderCount}} / ${{orderTotalAmount}}
         </div>
         <div class="custom-col"
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
           <div class="admin-edit" @click="editTranslate(['fbm_NotEnoughBalance', 'fbm_AuthorizeFee'])"></div>
           <b>{{$t('fbm_NotEnoughBalance.localization_value.value')}} :</b> ${{totalAmount}} ({{$t('fbm_AuthorizeFee.localization_value.value')}} ${{paymentSystemCommission}})
         </div>
       </div>

<!--       <div class="custom-row">-->
<!--         <div class="custom-col ">-->
<!--           <PriceCardBig-->
<!--               class="small big"-->
<!--               :value="'$32'"-->
<!--               :text="'Delivery Cost'"-->
<!--           />-->
<!--         </div>-->
<!--       </div>-->
      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'fbm_pay'])"></div>
        <span
            class="site-link site-link--alt buy-label__btn-i mr-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
            :value="$t('fbm_pay.localization_value.value')"
            class="buy-label__btn-i"
            @click.native="$emit('pay', totalAmount, balanceAmount)"
        />
      </div>
    </template>
  </modal>

</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  // import PriceCardBig from "../../../../coreComponents/PriceCardBig/PriceCardBig";

  export default {
    name: "PayOrderPopup",
    components: {
      Modal,
      MainButton,
      // PriceCardBig,
    },

    props: {
      orderCount: Number,
      orderTotalAmount: Number,
      paymentSystemCommission: Number,
      totalAmount: Number,
      balanceAmount: Number,
      balance: Number,
    }

  }

</script>

<style scoped>

</style>
