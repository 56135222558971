var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[(_vm.$store.getters.getManifests.length > 0 && !_vm.$store.getters.getManifestsLoading)?[_c('div',{staticClass:"site-table-wrap small-table",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'fbm_manifestId',
          'fbm_manifestDocNum',
          'fbm_manifestCreated',
          ])}}}),(!_vm.isMobileFunc())?_c('table',{staticClass:"site-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('fbm_manifestId.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('fbm_manifestDocNum.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('fbm_manifestCreated.localization_value.value')))]),_c('th',{staticStyle:{"width":"255px"}})])]),_c('tbody',_vm._l((_vm.$store.getters.getManifests),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(item.id)+" ")]),_c('td',[_c('ValueHelper',{attrs:{"value":item.document_number}})],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD MMM, YYYY HH:mm"))+" ")]),_c('td',{staticStyle:{"width":"255px"}},[_c('div',{staticClass:"table-right table-manage-list table-manage-list--small",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'common_react',
                'common_print',
                ])}}}),_c('ManagerButton',{key:_vm.$store.getters.getGlobalUpdateManagerButton,on:{"reloadManagerBtn":function($event){_vm.$store.commit('setGlobalUpdateManagerButton', Math.random())}}},[_c('template',{slot:"item"},[(_vm.isAdmin && item.file_entity && item.file_entity.uuid)?_c('LinkButton',{attrs:{"value":_vm.$t(`common_print.localization_value.value`),"type":'print'},nativeOn:{"click":function($event){return _vm.getManifestFile(item.file_entity.uuid)}}}):_vm._e()],1)],2)],1)])])}),0)]):_vm._e(),(_vm.isMobileFunc())?_c('div',{staticClass:"table-card"},[_c('div',{staticClass:"table-card__list"},_vm._l((_vm.$store.getters.getManifests),function(item,index){return _c('div',{key:index,staticClass:"table-card__item"},[_c('ManifestsTableMobile',{attrs:{"item":item,"selectedNow":_vm.selectedNow},on:{"getManifestFile":_vm.getManifestFile}})],1)}),0)]):_vm._e(),_c('div',{staticClass:"table-bottom-btn"},[_c('div',{staticClass:"table-bottom-btn__inner"},[(_vm.$store.getters.getManifestsCommonList.next_page_url !== null && _vm.$store.getters.getManifests.length > 0)?_c('ShowMore',{class:{'disabled-btn' : _vm.$store.getters.getNextManifestsPage},attrs:{"count":_vm.$store.getters.getManifestsCommonList.total - _vm.$store.getters.getManifestsForPage * _vm.$store.getters.getManifestsCommonList.current_page < _vm.$store.getters.getManifestsForPage ?
                  _vm.$store.getters.getManifestsCommonList.total - _vm.$store.getters.getManifestsForPage * _vm.$store.getters.getManifestsCommonList.current_page:
                  _vm.$store.getters.getManifestsForPage},nativeOn:{"click":function($event){return _vm.$emit('showMore')}}}):_vm._e()],1)])])]:_vm._e(),(_vm.$store.getters.getManifestsLoading === false && _vm.$store.getters.getManifests.length === 0)?[_c('NoResult',{attrs:{"countFilterParams":_vm.countFilterParams,"title":_vm.$t('common_noDataYet.localization_value.value'),"titleWithFilter":_vm.$t('common_noResult.localization_value.value'),"text":_vm.$t('common_noDataYetTxt.localization_value.value'),"textWithFilter":_vm.$t('common_noResultSeems.localization_value.value')},on:{"resetFilter":function($event){return _vm.$emit('resetFilter')}}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }