<template>
  <div class="fragment">
    <OrdersFBMTableUser
            :navTabs="navTabs"
            @changeTab="changeTab"
            :countFilterParams="countFilterParams"
            :filterGetParams="filterGetParams"
            @resetFilter="resetFilter"
            @changeFilter="changeFilter"
            @showMore="showMore"
            @reload="reload"
            @copyOrder="(id) => copyOrder(id, true)"
            @deleteOrder="(id) => deleteOrder(id, true)"
            @thermalPrint="thermalPrint"
            @royalMailManifest="royalMailManifest"
            @generatePdf="generateICreateLabelFile"
            @generateBarcodePdf="generateICreateLabelFile(true)"
            @getMailLabel="getMailLabel"
            @downloadFiles="exportFile"
            @payFBM="payFBM"
            @getLabelFBM="order => openLabelFBM(order)"
            @getBarcodeFBM="order => getBarcodeFBM(order)"
            @reloadLabelFBM="order => reloadLabelFBM(order)"
            @paginate="paginate"
            @changePerPage="changePerPage"
            @cancelPrepayOrder="cancelPrepayOrder"
    />

    <PayOrderPopup
        v-if="isPayOrderPopup"
        :orderCount="payOrderPopupData.order_count"
        :orderTotalAmount="payOrderPopupData.order_total_amount"
        :paymentSystemCommission="payOrderPopupData.payment_system_commission"
        :totalAmount="payOrderPopupData.total_amount"
        :balance="payOrderPopupData.user_balance"
        :balanceAmount="payOrderPopupData.balance_amount"
        @close="isPayOrderPopup = false"
        @pay="payOrder"
    />

    <AuthorizePaymentPopup
        v-if="authorizePaymentPopup"
        :countMoney="authorizePaySum"
        :authorizePayData="authorizePayData"
        :authorizeError="authorizeError"
        :serverError="serverError"
        :changeCloseToSaveBtn="false"
        @close="closeAuthorizePaymentPopup"
        @submitForm="submitAuthorizePay"
    />

    <BraintreePaymentPopup
        v-if="braintreePaymentPopup"
        :amount="authorizePaySum"
        :payData="authorizePayData"
        :changeCloseToSaveBtn="false"
        @submitForm="submitBrainTreePay"
        @close="braintreePaymentPopup = false"
    />

    <ThankYouPopup
        v-if="thankYouPopupPopup"
        @close="closeThankYouPopupPopup"
        :title="'common_paySuccessTitle'"
        :text="'common_paySuccessText'"
        :linkLastText="'common_paySuccessTextLink'"
        :linkLastTextHref="$store.getters.GET_PATHS.financeMyPayments + '?type=authorize'"
    />

    <SendFromDataPopup
        v-if="isSendFromDataPopup"
        :type="'info'"
        @close="isSendFromDataPopup = false"
    />


  </div>
</template>

<script>
  import OrdersFBMTableUser from "./OrdersFBMTableUser/OrdersFBMTableUser.vue";
  import {FBM_ORDER_TYPES} from "../../../../../staticData/staticVariables";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {FBMMixins} from "../../../../../mixins/FBMMixins/FBMMixins";
  import PayOrderPopup from "@/components/modules/OrdersFBMModule/popups/PayOrderPopup/PayOrderPopup";
  import AuthorizePaymentPopup from "@/components/coreComponents/Popups/AuthorizePaymentPopup/AuthorizePaymentPopup";
  import ThankYouPopup from "@/components/coreComponents/Popups/ThankYouPopup/ThankYouPopup";
  import {paymentBlockFunc} from "@/mixins/dashboardMixins/paymentBlockFunc";
  import BraintreePaymentPopup from "@/components/coreComponents/Popups/BraintreePaymentPopup/BraintreePaymentPopup";
  import SendFromDataPopup from "@/components/coreComponents/Popups/SendFromDataPopup/SendFromDataPopup";

  export default {
    name: "OrdersFBMTable",

    components: {
      SendFromDataPopup,
      BraintreePaymentPopup,
      ThankYouPopup,
      AuthorizePaymentPopup,
      PayOrderPopup,
      OrdersFBMTableUser,
    },

    mixins: [routeFilter, FBMMixins, paymentBlockFunc],

    watch: {
      downloadPermissions() {
        this.checkTabPermission()
      },

      checkLoadOrders(newVal) {
        if(this.$route.query.openLabel){
          let order = this._.find(newVal, {id: parseInt(this.$route.query.openLabel)})
          let query = Object.assign({}, this.$route.query);
          delete query.openLabel;
          this.$router.replace({ query });
          this.openLabelFBM(order)
        }

        if(this.$route.query.noPrepay){
          let data = {
            'ids': [parseInt(this.$route.query.noPrepay)]
          }
          this.getPrePayAmountFBM(data)
          let query = Object.assign({}, this.$route.query);
          delete query.noPrepay;
          this.$router.replace({ query });
        }
      },
    },

    computed: {
      checkLoadOrders() {
        return this.$store.getters.getOrdersFBM
      },
    },

    data() {
      return {
        forPage: this.$store.getters.getOrdersFBMForPage,
        page: 1,
        onePage: 1,
        filterType: '',
        filterDate: [],
        filterUserName: '',
        filterUserId: '',
        filterId: '',
        filterSentDate: [],
        filterRecipient: '',
        filterPaypalTransaction: '',
        filterLots: '',
        filterShip: '',
        filterTrackingNumber: '',
        filterConsoTrackingNumber: '',
        filterConsoId: '',
        filterShippingCompanyId: '',
        filterShippingCompanyName: '',
        filterReturnGoods: '',
        filterPaid: '',
        filterExpressTrack: '',
        filterNpTrack: '',

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          all: {
            active: true,
            // label: this.$t('FBM_allOrders.localization_value.value'),
            label: 'fbm_allOrders',
            name: 'all',
            id: false,
            permissionVisible: true,
          },
          [FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name]: {
            active: false,
            // label: this.$t('FBM_warehouse.localization_value.value'),
            label: 'fbm_warehouse',
            name: FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name,
            id: FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.id,
            permissionVisible: false,
          },
          [FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name]: {
            active: false,
            // label: this.$t('FBM_consolidation.localization_value.value'),
            label: 'fbm_consolidation',
            name: FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name,
            id: FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.id,
            permissionVisible: false,
          },
          [FBM_ORDER_TYPES.FBM_ORDER_LABEL.name]: {
            active: false,
            // label: this.$t('FBM_label.localization_value.value'),
            label: 'fbm_label',
            name: FBM_ORDER_TYPES.FBM_ORDER_LABEL.name,
            id: FBM_ORDER_TYPES.FBM_ORDER_LABEL.id,
            permissionVisible: false,
          },
        },

        isPayOrderPopup: false,
        payOrderPopupData: null,

        serverError: false,
        authorizePayData: {},
        authorizePaySum: -1,
        authorizeBalanceAmount: -1,
        bankFeeds: null,
        authorizePaymentPopup: false,
        braintreePaymentPopup: false,
        thankYouPopupPopup: false,
        authorizeError: false,

        isSendFromDataPopup: false,
      }
    },

    mounted() {

      if(!this.isAdmin) {
        this.$store.dispatch('fetchOrderSendFrom', '?limit=1').then(response => {
          if(this.getRespPaginateData(response).length === 0) {
            this.isSendFromDataPopup = true
          }
        })
      }

      if (this.getCookie('perPageFBM')) {
        this.$store.commit('setOrdersFBMForPage', +this.getCookie('perPageFBM'))
        this.forPage = this.$store.getters.getOrdersFBMForPage
      }

      if(this.$route.query.type !== undefined){
        this.navTabs.all.active = false
        this.navTabs[this.$route.query.type].active = true
      }


      if(this.downloadPermissions){
        this.checkTabPermission()
      }
    },

    methods: {

      closeThankYouPopupPopup() {
        this.thankYouPopupPopup = false
      },

      closeAuthorizePaymentPopup() {
        this.authorizePaymentPopup = false
      },

      submitAuthorizePay(data){
        this.bankFeeds = this.authorizePaySum
        if(this.authorizeBalanceAmount > -1) {
          data.balanceAmount = this.authorizeBalanceAmount
        }
        this.payAuthorize(data).then(() => {
          this.authorizePaymentPopup = false

          setTimeout(() => {
            this.changeThankYouPopupPopup(true)
          }, 200)
        })
      },

      submitBrainTreePay(){
        setTimeout(() => {
          this.changeThankYouPopupPopup(true)
        }, 200)
      },

      payOrder(sum, balanceAmount) {
        this.isPayOrderPopup = false
        this.authorizePaySum = sum
        this.authorizeBalanceAmount = -1
        this.authorizeBalanceAmount = balanceAmount

        // PAY TYPE authorize / braintree

        if(this._.has(this.currentPermissions, this.PERMISSIONS.CUSTOMER_PAYMENT_BRAINTREE)) {
          this.braintreePaymentPopup = true
        } else {
          this.authorizePaymentPopup = true
        }
      },

      cancelPrepayOrder(id) {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {
          this.$store.dispatch('cancelPrepayOrder', id).then(response => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.reload()
                this.openNotify('success', 'common_notificationRecordChanged')
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)

      },

      checkTabPermission() {
        this.activeTabByPermissions('navTabs', {
          [FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name]: this.PERMISSIONS.ORDER_FBM_WAREHOUSE,
          [FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name]: this.PERMISSIONS.ORDER_FBM_CONSOLIDATION,
          [FBM_ORDER_TYPES.FBM_ORDER_LABEL.name]: this.PERMISSIONS.ORDER_FBM_LABEL,
        })

        this.hideTabAllIfVisibleOnlyOne('navTabs', 'all', ['permissionVisible'])

        if(!this._.find(this.navTabs, {active: true}).permissionVisible){
          this.setActivePermissionVisibleTab('navTabs')
          return
        }

        // if(this.isAdmin && !this.$route.query.paid){
        //   this.$route.query.paid = '1'
        // }

        if(this.isAdmin && this.$route.query.paid == -1){
          this.$route.query.paid = ''
        }

        this.filter()
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          pagination: this.$route.query.pagination,
          date: this.$route.query.date,
          type: this.$route.query.type,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,

          id: this.$route.query.id,
          sentDate: this.$route.query.sentDate,
          recipient: this.$route.query.recipient,
          paypalTransaction: this.$route.query.paypalTransaction,
          lots: this.$route.query.lots,
          ship: this.$route.query.ship,
          trackingNumber: this.$route.query.trackingNumber,
          consoTrackingNumber: this.$route.query.consoTrackingNumber,
          consoId: this.$route.query.consoId,
          shippingCompanyId: this.$route.query.shippingCompanyId,
          shippingCompanyName: this.$route.query.shippingCompanyName,
          returnGoods: this.$route.query.returnGoods,
          paid: this.$route.query.paid,
          expressTrack: this.$route.query.expressTrack,
          npTrack: this.$route.query.npTrack,
        }
      },

      changePerPage(val) {
        this.$store.commit('setOrdersFBMForPage', val)
        this.forPage = this.$store.getters.getOrdersFBMForPage

        if (this.$route.query.page > Math.ceil(this.$store.getters.getOrdersFBMCommonList.total / this.$store.getters.getOrdersFBMForPage)) {
          this.filterGetParams.page = Math.ceil(this.$store.getters.getOrdersFBMCommonList.total / this.$store.getters.getOrdersFBMForPage)
          this.$router.replace({name: '', query: {...this.$route.query, page: this.filterGetParams.page}})
        }

        this.filter(false)
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        let paginationFlag = false
        if (this.$route.query?.pagination === 'true') {
          paginationFlag = true
        }

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this, paginationFlag)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextOrdersFBMPage', true)
        this.$store.dispatch('fetchOrdersFBM', {filter: url}).then(() => {
          this.$store.commit('setNextOrdersFBMPage', false)
        })


        this.checkCountFilter(['user', 'type', 'shippingCompanyName'])
      },

      generateFilterUrl(page, forPage, exportIds = false) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
          myQuery,
          {
            filterUserId: 'user_id',
            filterId: 'OrderFbmId',
            filterRecipient: 'OrderFbmRecipientContactName',
            filterPaypalTransaction: 'transaction_number',
            // filterLots: 'proform_entity_naming',
            filterShip: 'OrderFbmDeliveryCost',
            filterTrackingNumber: 'OrderFbmTrackingNumber',
            filterConsoTrackingNumber: 'consolidationUserTrackingNumber',
            filterConsoId: 'consolidationId',
            filterShippingCompanyId: 'shippingCompany',
            filterReturnGoods: 'byReturnParcel',
            filterExpressTrack: 'OrderFbmExternalNumber',
            filterNpTrack: 'OrderFbmUserTrackingNumber',
          },
        )

        let deliveryTypeId = ''
        switch (this.filterType) {
          case FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name:
            deliveryTypeId = FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.id
            break
          case FBM_ORDER_TYPES.FBM_ORDER_LABEL.name:
            deliveryTypeId = FBM_ORDER_TYPES.FBM_ORDER_LABEL.id
            break
          case FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name:
            deliveryTypeId = FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.id
            break
        }
        if (deliveryTypeId !== '')
          myQuery.where('OrderFbmTypeId', deliveryTypeId)

        if (this.filterPaid !== '' && this.filterPaid !== '-1')
          myQuery.where('OrderFbmPrepay', this.filterPaid)

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')
          myQuery.whereIn(
            'between_created_at',
            date
          )
        }

        if (this.filterSentDate.length > 0) {
          let deliveryDate = this.generateDateForFilter(this.filterSentDate, 'YYYY-MM-DD')
          myQuery.whereIn(
              // 'betweenUnionSentAt',
            'between_delivery_date',
            deliveryDate
          )
          myQuery.where('OrderFbmStatus', 'completed')
        }

        // If generate on export file
        if(exportIds && exportIds.length > 0) {
          myQuery.whereIn('OrderFbmId', exportIds)
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)
      },

      thermalPrint(data) {
        let exportIds = this.getCheckedRows('row-name').join(',')

        if(!Object.hasOwnProperty.call(data, 'selected')) {
          exportIds = data.id
        }

        if (exportIds.length === 0) return

        let postData = {
          orderIds: exportIds,
          format: 'pdf'
        }

        let routeData = this.$router.resolve({
          path: this.$store.getters.GET_PATHS.generatePdfFile + '/fbm-order-thermal-print',
          query: {
            ids: postData.orderIds,
            format: postData.format
          }
        })

        window.open(routeData.href, '_blank');
      },

      getMailLabel(data) {
        let exportIds = this.getCheckedRows('row-name')

        if(!Object.hasOwnProperty.call(data, 'selected')) {
          exportIds = data.id
        }

        if (exportIds.length === 0) return

        let format = 'docx'
        this.$store.dispatch('getOrderFBMThermalPrint', {ids: exportIds, format: format}).then((response) => {
          console.log(response);
          this.globalDownloadBase64File('application/docx', response.data.data.fileContentBase64, response.data.data.fileName)
        }).catch(error => this.createErrorLog(error))
      },


      exportFile(type){
        let forPage, page, url, exportIds

        exportIds = this.getCheckedRows('row-name')

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage, exportIds)

        this.exportTableFiles(url, type, 'getExportFBM', 'FBM')

        // this.$store.dispatch('getExportFBM', {filter: url, exportType: type}).then((response) => {
        //   if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
        //
        //     this.createDownloadLink({data: response.data, name: `FBM.${type}`, type: type})
        //   } else {
        //     setTimeout(() => {
        //       this.openNotify('error', 'common_notificationUndefinedError')
        //     }, 200)
        //   }
        // })
      },

      generateICreateLabelFile(forBarcode = false){
        let exportIds = this.getCheckedRows('row-name')

        if (exportIds.length === 0) return

        let actualIds = []
        console.log(exportIds);
        this.$store.getters.getOrdersFBM.map(order => {
          exportIds.map(exportId => {
            if(parseInt(exportId) === parseInt(order.id) && this.isStatusComplete(order.status)){
              actualIds.push(order.id)
            }
          })
        })

        if (actualIds.length === 0) return

        let postData = {
          orderIds: actualIds.join(','),
          format: 'pdf'
        }

        let routePath = forBarcode ? '/fbm-order-generate-barcode-pdf' : '/fbm-order-generate-pdf'

        let routeData = this.$router.resolve({
          path: this.$store.getters.GET_PATHS.generatePdfFile + routePath,
          query: {
            ids: postData.orderIds,
            format: postData.format
          }
        })

        window.open(routeData.href, '_blank');
      },

      payFBMConfirmation() {
        let text = {
          title: 'fbm_pay',
          txt: '',
          yes: 'common_yes',
          no: 'common_no'
        }

        let confirm = () => {

          this.payFBM()

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },


      payFBM(){
        let exportIds = this.getCheckedRows('row-name')

        let ids = []
        let orders = this.$store.getters.getOrdersFBM

        exportIds.map(id => {
          let foundOrder = this._.find(orders, {id: parseInt(id)})
          if (foundOrder && !foundOrder.prepay) {
            ids.push(id)
          }
        })

        let data = {
          'ids': ids.length > 0 ? ids : null
        }

        this.$store.dispatch('prePayFBM', data).then(response => {

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */

            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              // let respData = this.getRespData(response)
              // let flag = true
              // for(let item in respData.result){
              //   if(!respData.result[item]) {
              //     this.openNotify('error', {txtServer: + '#' + item + ' not paid'})
              //     flag = false
              //   }
              // }
              //
              // if(flag){
              //   this.reload()
              // }
              this.reload()

              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              // this.notifyErrorHelper(errors)

              if(Object.hasOwnProperty.call(errors, 'balance_error')){
                this.getPrePayAmountFBM(data)
              }

              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })

      },

      getPrePayAmountFBM(data) {
        this.$store.dispatch('prePayAmountFBM', data).then(response => {
          this.isPayOrderPopup = true
          this.payOrderPopupData = this.getRespData(response)
        })
      },

      reloadLabelFBM(id) {
        return this.$store.dispatch('regenerateLabelFBM', id).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              console.log('reload label: ',response);
              let tableData = this.$store.getters.getOrdersFBM
              let indexTableData = this._.findKey(tableData, {id: id})
              console.log(444444444444, indexTableData);

              this.$store.dispatch('getOrdersFBM', id).then(editResponse => {
                let responseData = this.getRespData(editResponse)
                tableData[indexTableData].file_entity = responseData.orderFbmInstance?.file_entity || []
                this.openNotify('success', 'common_notificationRecordChanged')
              })

              return true
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              return false
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
              return false
            }
          }
        })
      },

      royalMailManifest() {
        this.$store.dispatch('getOrdersFBMRoyalMailManifest').then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              let respData = this.getRespData(response)
              let routeData = this.$router.resolve({
                path: this.$store.getters.GET_PATHS.getPdfFile + '/' + respData?.file_entity?.uuid
              })

              window.open(routeData.href, '_blank');
              return true
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              return false
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
              return false
            }
          }

          // later

        })
      },

    },


    destroyed() {
      setTimeout(() => {
        this.$store.commit('setOrdersFBM', {ordersFBM: []})
        this.$store.commit('setOrdersFBMCommonList', {ordersFBMCommonList: []})
      }, 300)
    },

  }
</script>

<style scoped>

</style>
