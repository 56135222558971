var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-page"},[_c('div',{staticClass:"detail-page__left",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'fbm_orderId',
        'fbm_PayPalTransactions'
      ])}}}),_c('CardLeftBlock',{attrs:{"backgroundImage":'consolidation',"name":_vm.$t('fbm_orderId.localization_value.value'),"value":'#'+_vm.FBM.data.Order.getId()}},[_c('template',{slot:"body"},[_c('div',{staticClass:"card-detail-left__date"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.FBM.data.Order.data.createdAt,"DD MMM, YYYY"))+" ")]),_c('div',{staticClass:"site-link brown text-decoration-underline mt-4"},[(_vm.isAdmin && _vm.FBM.data.Order.data.historyOfChanged)?_c('div',{staticClass:"mb-3",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['common_CheckHistory'])}}}),_c('div',{staticClass:"site-link text-decoration-underline btn-style brown",on:{"click":function($event){return _vm.historyOfChangesFunc('fbm', _vm.FBM.data.Order.data.historyOfChanged)}}},[_vm._v(" "+_vm._s(_vm.$t('common_CheckHistory.localization_value.value'))+" ")])]):_vm._e()])])],2)],1),_c('div',{staticClass:"detail-page__right"},[_c('CardRightBlock',[_c('template',{slot:"header"},[_c('div',{staticClass:"order-create__head"},[_c('div',{staticClass:"order-create__head-line"},[_c('div',{staticClass:"order-create__header-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['common_backTo'])}}}),_c('router-link',{staticClass:"order-create__head-back",attrs:{"to":_vm.$store.getters.GET_PATHS.ordersFBM}},[_c('LinkBack',{attrs:{"value":_vm.$t('common_backTo.localization_value.value')}})],1)],1)]),_c('div',{staticClass:"order-create__head-title",class:{
                  'color-lightBlue': _vm.isConsolidation,
                  'color-violet': _vm.isLabel,
                  'color-orange': _vm.isWarehouse,
                  'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()
                 }},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'fbm_OrderForConsolidation',
                'fbm_OrderFromWarehouse',
                'fbm_CreateLabel'
              ])}}}),(_vm.isConsolidation)?[_vm._v(" "+_vm._s(_vm.$t('fbm_OrderForConsolidation.localization_value.value'))+" ")]:_vm._e(),(_vm.isLabel)?[_vm._v(" "+_vm._s(_vm.$t('fbm_CreateLabel.localization_value.value'))+" ")]:_vm._e(),(_vm.isWarehouse)?[_vm._v(" "+_vm._s(_vm.$t('fbm_OrderFromWarehouse.localization_value.value'))+" ")]:_vm._e()],2)])]),_c('template',{slot:"body"},[_c('div',{staticClass:"order-create__section"},[_c('StepFour',{attrs:{"FBM":_vm.FBM,"personal":_vm.FBM.data.Personal,"deliveryPayPal":_vm.FBM.data.DeliveryPayPal,"deliveryEtsy":_vm.FBM.data.DeliveryEtsy,"proformProducts":_vm.FBM.data.ProformProducts,"products":_vm.FBM.data.Products,"show":true}})],1)]),_c('template',{slot:"footer"},[_c('div',{staticClass:"order-create__footer"},[_c('div',{staticClass:"order-create__footer-btn ml-auto",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
										'common_CreateCopy',
									])}}}),_c('MainButton',{staticClass:"order-create__footer-btn-i wfc",attrs:{"value":_vm.$t('common_CreateCopy.localization_value.value')},nativeOn:{"click":function($event){return _vm.copyOrder(_vm.FBM.data.Order.data.id, false)}}})],1)])])],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }