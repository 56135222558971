<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup medium-size"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([popupTitle])"></div>
        {{$t(`${popupTitle}.localization_value.value`)}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="custom-row">

          <template v-for="(item, index) in dataItems">
            <div class="custom-col  custom-col--md-100"  :key="index"
                 v-bind:class="{
                  'custom-col--50': dataItems.length > 1 && item.type !== 'radio' && item.field !== 'consolidationOrderUnionId' && item.field !== 'consolidationUnionId',
                  'd-flex': item.type === 'radio',
                  'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()
                 }">
              <!--<div class="admin-edit" @click="editTranslate([item.name])"></div>-->
              <template v-if="(item.type === 'text' && item.field !== 'region') || (!showRegionsSelect && item.field === 'region' && item.type === 'text')">
                <DefaultInput
                    :label="$t(`${item.name}.localization_value.value`)"
                    v-bind:class="{'ui-no-valid': item.valid}"
                    :error="item.valid"
                    :errorTxt="$t(`${item.validTxt}.localization_value.value`)"
                    :type="item.type"
                    :maxLength="item.maxLength ? item.maxLength : ''"
                    :caption="item.maxLength ? $t('express_maxSymbols.localization_value.value') : ''"
                    :autocomplete="_.has(item, 'autocomplete') ? item.autocomplete : true"
                    v-model="item.value"
                />
              </template>

              <template v-if="showRegionsSelect && item.field === 'region' && optionsRegions.length > 0">
                <DefaultSelect
                    :options="optionsRegions"
                    :label="$t(`${item.name}.localization_value.value`)"
                    :optionsLabel="'code'"
                    v-bind:class="{'ui-no-valid': item.valid}"
                    :error="item.valid"
                    :errorTxt="$t(`${item.validTxt}.localization_value.value`)"
                    :selected="getSelectedRegion(item)"
                    @change="val => {changeRegion(val, index)}"
                />
              </template>

              <template v-if="item.field === 'country'">
                <DefaultSelect
                    :options="options"
                    :label="$t(`${item.name}.localization_value.value`)"
                    :optionsLabel="'label'"
                    v-bind:class="{'ui-no-valid': item.valid}"
                    :error="item.valid"
                    :errorTxt="$t(`${item.validTxt}.localization_value.value`)"
                    :selected="item.value"
                    @change="val => {changeCountry(val, index)}"
                />
              </template>

              <template v-if="item.field === 'consolidationOrderUnionId'">
                <DefaultSelect
                    :options="optionsAPCConso"
                    :label="$t('fbm_apc.localization_value.value')"
                    :otherValue="'consolidationUnion'"
                    :optionsLabel="'label'"
                    :disabled="checkAPCDeparted()"
                    v-bind:class="{'ui-no-valid': item.valid}"
                    :error="item.valid"
                    :errorTxt="$t(`${item.validTxt}.localization_value.value`)"
                    :selected="selectedConsolidation"
                    @change="val => {changeConsolidation(val, index)}"
                />
              </template>

              <template v-if="item.field === 'consolidationUnionId'">
<!--                    :otherValue="'consolidationUnionFBM'"-->
                <DefaultSelect
                    :options="optionsConsolidationUnion"
                    :label="$t('fbm_consolidationUnion.localization_value.value')"
                    :otherValue="'consolidationUnion'"
                    :optionsLabel="'id'"
                    v-bind:class="{'ui-no-valid': item.valid}"
                    :error="item.valid"
                    :errorTxt="$t(`${item.validTxt}.localization_value.value`)"
                    :selected="selectedConsolidationUnion"
                    @change="val => {changeConsolidationUnion(val, index)}"
                />
              </template>

              <template v-if="item.field === 'orderPrice'">
                <DefaultSelect
                        :options="$store.getters.getOrdersFBMItem.order_fees_list"
                        :label="$t(`${item.name}.localization_value.value`)"
                        :optionsLabel="'cost'"
                        :selected="item.value"
                        @change="val => {changeOrderFee(val, index)}"
                />
              </template>

              <template v-if="item.type === 'textarea'">
                <TextareaDefault
                        :label="$t(`${item.name}.localization_value.value`)"
                        v-bind:class="{'ui-no-valid': item.valid}"
                        :error="item.valid"
                        :errorTxt="$t(`${item.validTxt}.localization_value.value`)"
                        v-model="item.value"
                />
              </template>


              <template v-if="item.type === 'radio' && item.field === 'packaging'">
                <div class="custom-row">
                  <div class="order-create__col custom-col custom-col--sm-50 custom-col--wfc">
                    <RadioDefault
                            :label="'None'"
                            name="fedexRadioList2"
                            :value="true"
                            @input="changeGiftPaper('', index)"
                    />
                  </div>

                  <div class="order-create__col custom-col custom-col--sm-50 custom-col--wfc d-flex"
                       v-for="(itemGiftPaper, indexGiftPapers) in giftPapers"
                       :key="indexGiftPapers"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <template
                        v-if="_.has(itemGiftPaper, 'label')">
<!--                            v-if="_.has(_.find(FBM_GIFT_PAPERS, {name: itemGiftPaper.name}), 'translation')"-->
<!--                      <div class="admin-edit" @click="editTranslate([_.find(FBM_GIFT_PAPERS, {id: itemGiftPaper.id}).translationName, _.find(FBM_GIFT_PAPERS, {id: itemGiftPaper.id}).translation, 'fbmGiftPrice'])"></div>-->
                      <RadioDefault
                              class="wfc"
                              :label="itemGiftPaper.label"
                              name="fedexRadioList2"
                              :value="_.has(item.value, 'id') && item.value.id === itemGiftPaper.id"
                              @input="changeGiftPaper(itemGiftPaper, index)"
                      />

                      <TooltipTextHelper>
                        <template slot="text">
                          <TooltipBtn/>
                        </template>
                        <template slot="paragraph">
                          <div class="status-tooltip">
                            <div class="box-tooltip">
                              <div class="box-tooltip__img">
                                <img v-if="itemGiftPaper.small_image_file"
                                     :src="'data:' + itemGiftPaper.small_image_file.mime_type + ';base64,' + itemGiftPaper.imageBase64"
                                     alt="img"
                                >
                                <img v-else src="/img/common/box-birthday-img.png" alt="img">
                              </div>
                              <div class="box-tooltip__content">
                                {{itemGiftPaper.label}}
<!--                                <p v-if="paper = _.find(FBM_GIFT_PAPERS, {id: itemGiftPaper.id})">-->
<!--                                  {{$t(`${paper.translation}.localization_value.value`)}}-->
<!--                                </p>-->
                              </div>
                            </div>
                          </div>
                        </template>
                      </TooltipTextHelper>

                    </template>

                  </div>
                </div>

              </template>
            </div>
            <template v-if="item.field === 'region'">
              <div class="custom-col  custom-col--md-100"  :key="index+400"
                   v-bind:class="{
                  'custom-col--50': dataItems.length > 1 && item.type !== 'radio',
                 }">
              </div>
            </template>
          </template>

        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel','common_save'])"></div>
        <span
            class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
            @click="$emit('close')"
        >
        {{$t('common_cancel.localization_value.value')}}
        </span>
        <MainButton
            class="custom-popup__btn-i "
            :value="$t('common_save.localization_value.value')"
            @click.native="$emit('save', items)"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import TextareaDefault from "../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {filterCountriesUSA} from "../../../../../services/filterCountries";
  import RadioDefault from "../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import TooltipBtn from "../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import {
    AUSTRALIA_ID,
    CANADA_COUNTRY_ID,
    FBM_GIFT_PAPERS,
    MEXICO_COUNTRY_ID
  } from "../../../../../staticData/staticVariables";
  import TooltipTextHelper from "../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import {USA_COUNTRY_ID} from "../../../../../staticData/staticVariables";

  export default {
    name: "ChangeDataPopup",

    props: {
      items: Array,
      popupTitle: String,
      getRegionsIfUSA: {
        type: Boolean,
        default: false,
      },
      // itemGiftPapers: {
      //   type: Object,
      //   default: {}
      // },
    },

    components: {
      TooltipTextHelper,
      TooltipBtn,
      RadioDefault,
      DefaultSelect,
      TextareaDefault,
      Modal,
      MainButton,
      DefaultInput,
    },

    data() {
      return {
        input01: '',
        input02: '',
        input03: '',
        input04: '',
        input05: '',

        dataItems: this.items,
        // dataItemGiftPapers: this.itemGiftPapers,

        options: [],
        optionsAPCConso: [],
        optionsConsolidationUnion: [],
        optionsRegions: [],
        showRegionsSelect: false,
        selectedConsolidation: null,
        selectedConsolidationUnion: null,

        giftPapers: [],

        FBM_GIFT_PAPERS: FBM_GIFT_PAPERS,
      }
    },

    watch: {
      items(newVal) {
        this.dataItems = newVal
      }
    },

    mounted(){
      if(this._.find(this.dataItems, {type: 'select'})){
        let url = ''


        if (this.$route.query.idReturned) {
          url = '?return_parcel_id=' + this.$route.query.idReturned;
        }

        this.$store.dispatch('fetchCountries', url).then(() => {
          let countries = this._.toArray(this.$store.getters.getCountries)
          filterCountriesUSA(countries)
          this.options = countries;
        })
      }

      if(this.getRegionsIfUSA && this._.find(this.dataItems, {field: 'region'}) && this._.find(this.dataItems, {field: 'country'})
          && (this._.find(this.dataItems, {field: 'country'})?.value?.id === USA_COUNTRY_ID || this._.find(this.dataItems, {field: 'country'})?.value?.id === AUSTRALIA_ID ||
              this._.find(this.dataItems, {field: 'country'})?.value?.id === CANADA_COUNTRY_ID || this._.find(this.dataItems, {field: 'country'})?.value?.id === MEXICO_COUNTRY_ID)){
        this.initRegionsSelectIfSelectedCountries(this._.find(this.dataItems, {field: 'country'})?.value?.id)
        this.showRegionsSelect = true
      }

      if(this._.find(this.dataItems, {field: 'consolidationOrderUnionId'})){
        this.$store.dispatch('fetchAPCDelcon').then((response) => {
          this.optionsAPCConso = this.getRespPaginateData(response)
          let consoItem = this._.find(this.dataItems, {field: 'consolidationOrderUnionId'})

          this.selectedConsolidation = this.optionsAPCConso.find(item => {
            return item.id === consoItem.value
          })

          //remove all "departed" apc conso from list and disable select if the selected for order is departed too
          this.optionsAPCConso = this.optionsAPCConso.filter(item => {
            return item.status !== 'departed'
          })

          if (this.selectedConsolidation?.status === 'departed') {
            this.optionsAPCConso = [...this.optionsAPCConso, this.selectedConsolidation]
          }
        })
      }

      if(this._.find(this.dataItems, {field: 'consolidationUnionId'})){
        let consoItem = this._.find(this.dataItems, {field: 'consolidationUnionId'})

        this.selectedConsolidationUnion = consoItem.value

        this.$store.dispatch('fetchConsolidationUnion').then((response) => {
          this.optionsConsolidationUnion = this.getRespPaginateData(response)
        })
      }

      if(this._.find(this.dataItems, {type: 'radio'})){
        this.$store.dispatch('fetchPackagingGiftPapers', '?filter[enable]=1&page=0&limit=100000').then(response => {
          this.giftPapers = this.getRespPaginateData(response)
        })
      }
    },

    methods: {
      changeCountry(val, index) {
        this.dataItems[index].value = val
        if (this.getRegionsIfUSA && (val?.id === USA_COUNTRY_ID || val?.id === AUSTRALIA_ID || val?.id === CANADA_COUNTRY_ID || val?.id === MEXICO_COUNTRY_ID)) {
          this.initRegionsSelectIfSelectedCountries(val?.id)
          this.showRegionsSelect = true
          console.log(5555555555555555);
        }
        else {
          this.showRegionsSelect = false
          console.log(8888888888888);
        }
      },

      changeOrderFee(val, index) {
        this.dataItems[index].value = val
      },

      changeGiftPaper(val, index = false){
        this.dataItems[index].value = val
      },

      changeConsolidation(val, index = false){
        this.dataItems[index].value = val.id
        this.selectedConsolidation = val
      },

      changeConsolidationUnion(val, index = false){
        this.dataItems[index].value = val
        this.selectedConsolidationUnion = val
      },

      checkAPCDeparted() {
        return this.selectedConsolidation?.status === 'departed'
      },

      initRegionsSelectIfSelectedCountries(countryId) {
        this.$store.dispatch('getRegionsByCountry', countryId).then(() => {
          this.optionsRegions = this._.toArray(this.$store.getters.getRegions)
        });
      },

      getSelectedRegion(item) {
        return this.optionsRegions.find(searchItem => {
          return searchItem?.code.toLowerCase() === item.value.toLowerCase()
        })
      },

      changeRegion(val, index) {
        this.dataItems[index].value = val.code
      },

    }
  }
</script>

<style scoped>

</style>
