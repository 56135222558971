<template>
  <div class="fragment">
    <div v-if="FBM.data.Order.data.fileLabel"
         class="mb-3"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fbm_LabelAdmin'])"></div>
      <router-link :to="$store.getters.GET_PATHS.getPdfFile + '/' + FBM.data.Order.data.fileLabel.uuid"
                   :target="'_blank'">
        <MainButton
            style="min-width: 149px;"
            class="order-create__footer-btn-i secondary"
            :value="$t('fbm_LabelAdmin.localization_value.value')"
        />
      </router-link>
    </div>

    <div v-if="getLabelBarcode"
         class="mb-3"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fbm_LabelBarcodeAdmin'])"></div>
      <router-link :to="$store.getters.GET_PATHS.getPdfFile + '/' + getLabelBarcode.uuid" :target="'_blank'">
        <MainButton
            style="min-width: 149px;"
            class="order-create__footer-btn-i secondary"
            :value="$t('fbm_LabelBarcodeAdmin.localization_value.value')"
        />
      </router-link>
    </div>

    <template v-if="_.has(FBM.data.shippingPartner, 'delivery_driver_service_name') &&
            FBM.data.shippingPartner.delivery_driver_service_name === 'easyPost'">
      <div v-if="FBM.data.trackingNumberRefund === 1"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_labelRefunded', 'fbm_labelRefund'])"></div>
        <TooltipTextHelperPlugin>
          <template slot="text">
            <StatusIcoBtn :type="'active'" class="ml-2"/>
          </template>
          <template slot="paragraph">
            {{ $t('fbm_labelRefunded.localization_value.value') }}
          </template>
        </TooltipTextHelperPlugin>
      </div>

      <div
          v-if="FBM.data.Order.data.oldStatus.value === FBM_ALL_STATUSES.FBM_COMPLETED_STATUS.value && FBM.data.trackingNumberRefund === 0"
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_labelRefunded', 'fbm_labelRefund'])"></div>
        <MainButton
            style="min-width: 149px;"
            class="order-create__footer-btn-i secondary"
            :value="$t('fbm_labelRefund.localization_value.value')"
            @click.native="$emit('returnLabel')"
        />
      </div>
    </template>
  </div>
</template>

<script>
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import {FBMMixins} from "@/mixins/FBMMixins/FBMMixins";
import TooltipTextHelperPlugin from "@/components/coreComponents/TooltipTextHelperPlugin/TooltipTextHelperPlugin";
import StatusIcoBtn from "@/components/UI/status/StatusIcoBtn/StatusIcoBtn";
import {FBM_ALL_STATUSES} from "@/staticData/staticVariables";

export default {
  name: "ShippingAdminBtns",

  components: {StatusIcoBtn, TooltipTextHelperPlugin, MainButton},

  mixins: [FBMMixins],

  props: {
    FBM: Object,
  },

  data() {
    return {
      FBM_ALL_STATUSES: FBM_ALL_STATUSES,
    }
  },

}
</script>

<style scoped>

</style>