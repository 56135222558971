<template>
  <div class="fragment">
    <template v-if="$store.getters.getManifests.length > 0 && !$store.getters.getManifestsLoading">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'fbm_manifestId',
          'fbm_manifestDocNum',
          'fbm_manifestCreated',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{$t('fbm_manifestId.localization_value.value')}}</th>
            <th>{{$t('fbm_manifestDocNum.localization_value.value')}}</th>
            <th>{{$t('fbm_manifestCreated.localization_value.value')}}</th>
            <th style="width: 255px;"></th>
          </tr>
          </thead>
          <tbody>

          <tr
              v-for="(item, index) in $store.getters.getManifests"
              :key="index"
          >
            <td>
              {{item.id}}
            </td>
            <td>
              <ValueHelper
                  :value="item.document_number"
              />
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY HH:mm") }}
            </td>
            <td style="width: 255px;">
              <div class="table-right table-manage-list table-manage-list--small"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                'common_react',
                'common_print',
                ])"></div>
                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item">
                    <LinkButton
                        v-if="isAdmin && item.file_entity && item.file_entity.uuid"
                        :value="$t(`common_print.localization_value.value`)"
                        :type="'print'"
                        @click.native="getManifestFile(item.file_entity.uuid)"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getManifests"
                 :key="index"
            >
              <ManifestsTableMobile
                  :item="item"
                  :selectedNow="selectedNow"
                  @getManifestFile="getManifestFile"
              />
            </div>

          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getManifestsCommonList.next_page_url !== null && $store.getters.getManifests.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextManifestsPage}"
                :count="$store.getters.getManifestsCommonList.total - $store.getters.getManifestsForPage * $store.getters.getManifestsCommonList.current_page < $store.getters.getManifestsForPage ?
                  $store.getters.getManifestsCommonList.total - $store.getters.getManifestsForPage * $store.getters.getManifestsCommonList.current_page:
                  $store.getters.getManifestsForPage"
            />
<!--            <ExportBtn-->
<!--                @click.native="$emit('downloadFiles')"-->
<!--                class="table-bottom-btn__right"/>-->
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getManifestsLoading === false && $store.getters.getManifests.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>


  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import ManifestsTableMobile from "./ManifestsTableMobile/ManifestsTableMobile";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";


  export default {
    name: "ManifestsTable",

    components: {
      LinkButton,
      ManagerButton,
      ValueHelper,
      NoResult,
      ManifestsTableMobile,
      ShowMore,
    },

    mixins: [mixinDetictingMobile],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },


    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,
        hideEditField: false,

        show1: false,
        show2: false,

        isModalInfoPopup: false,
        infoPopupItemId: 0,
      }
    },

    methods: {
      getManifestFile(uuid) {
        let routeData = this.$router.resolve({
          path: this.$store.getters.GET_PATHS.getPdfFile + '/' + uuid
        })

        window.open(routeData.href, '_blank');
      }
    }

  }

</script>

<style scoped lang="scss">
  .comment-row{
    overflow: hidden;
    text-overflow: ellipsis;
    width: 356px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
  }

  .table-manage-list {
    width: 240px;


    .manager-btn {
      max-width: 370px;
      width: 100%;
    }
  }


  @media (max-width: 1900px){
    .comment-row{
      width: 250px;
    }
  }

  @media (max-width: 1400px){
    .comment-row{
      width: 200px;
    }
  }

  .site-table thead th:first-child,
  .site-table tbody td:first-child{
    padding-right: 0;
  }

  .site-table thead th:nth-child(2),
  .site-table tbody td:nth-child(2){
    padding-left: 0;
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child{
    padding-right: 5px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .small-table th,
  .small-table td{
    white-space: nowrap;
  }

  .conso-user-select {
    min-width: 150px;
  }

  .site-table .ellipsis {
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: inline-block;
  }

</style>
