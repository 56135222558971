<template>
  <div class="fragment">
    <div class="order-create__title-horizontal"
         v-if="FBM.data.price > 0 || loadingPrepayPrice"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
										'express_Shipping',
									])"></div>
      <TitleHorizontal
          :value="$t('express_Shipping.localization_value.value')"
      />
    </div>

    <div class="pt-2 pb-3" v-if="loadingPrepayPrice">
      <DotsShadowPreloader/>
    </div>

    <template v-if="FBM.data.price > 0 && !loadingPrepayPrice">
      <LabelPrice
          :labelPrice="getLabelPrice"
          :bigCards="true"
          :dearCustomer="false"
      />
    </template>

    <div class="red" v-if="prepayError">
      <LabelPrice
          :labelPrice="{prepayError: prepayError}"
          :bigCards="true"
          :dearCustomer="false"
          :totalPrice="false"
      />
    </div>

  </div>
</template>

<script>
import LabelPrice from "@/components/coreComponents/LabelPrice/LabelPrice";
import DotsShadowPreloader from "@/components/coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
import TitleHorizontal from "@/components/coreComponents/TitleHorizontal/TitleHorizontal";
import {FBMMixins} from "@/mixins/FBMMixins/FBMMixins";
export default {
name: "PriceBlock",
  components: {TitleHorizontal, DotsShadowPreloader, LabelPrice},

  mixins: [FBMMixins],

  props: {
    FBM: Object,
    loadingPrepayPrice: Boolean,
    prepayError: [Boolean,String]
  }

}
</script>

<style scoped>
.red{
  color: red;
  margin-bottom: 30px;
}
</style>