var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"custom-popup wrong-orders-for-popup",on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'fbm_wrongOrdersForConso',
          ])}}}),_vm._v(" "+_vm._s(_vm.$t('fbm_wrongOrdersForConso.localization_value.value'))+" ")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"custom-popup__content",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'fbm_theOrders',
            'fbm_wrongOrdersPopupText',
          ])}}}),_c('p',{staticClass:"fsz14"},[_vm._v(" "+_vm._s(_vm.$t('fbm_theOrders.localization_value.value'))+" "),_vm._l((_vm.invalidOrders),function(item,index){return [_c('router-link',{key:index,staticClass:"site-link",attrs:{"to":_vm.$store.getters.GET_PATHS.ordersFBM + '/' + _vm.getOrderFBMTypeById(item.order_type_id).name + '/' + item.id}},[_vm._v(" "+_vm._s(item.id)+" ")]),_vm._v(", ")]}),_vm._v(" "+_vm._s(_vm.$t('fbm_wrongOrdersPopupText.localization_value.value'))+" ")],2)])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"buy-label__btn d-flex align-items-center",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'common_cancel',
            'common_CreateConsolidation',
          ])}}}),_c('span',{staticClass:"site-link site-link--alt buy-label__btn-i mr-3",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('common_cancel.localization_value.value'))+" ")]),_c('MainButton',{staticClass:"buy-label__btn-i wfc",attrs:{"value":_vm.$t('common_CreateConsolidation.localization_value.value')},nativeOn:{"click":function($event){return _vm.createConsolidationFromValidOrders.apply(null, arguments)}}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }