import {Dimensions} from "../../../../../globalModels/Dimensions";
import {User} from "../../../../../globalModels/User";

export function PackingLabel() {

  this.data = {
    Dimensions: new Dimensions(),
    User: new User(),
  }

}

PackingLabel.prototype.setItem = function (item, user) {
  this.data.Dimensions.setDimensionsName(item.package_name)
  this.data.Dimensions.setDimensionsHeight(item.height)
  this.data.Dimensions.setDimensionsWidth(item.width)
  this.data.Dimensions.setDimensionsLength(item['length'])
  this.data.Dimensions.setDimensionsWeightLB(parseInt(item.weightKg))
  this.data.Dimensions.setDimensionsWeightOZ(parseInt(item.weightGr))
  this.data.User.setUser(user)
}

PackingLabel.prototype.packingLabelValidation = function (isAdmin) {
  let dimensions = this.data.Dimensions.dimensionsPartialValidate({
    name: true,
    width: true,
    height: true,
    dimensionLength: true,
    weightLB: true,
    weightOZ: true,
  })

  console.log(dimensions);

  let user = true
  if(isAdmin) {
    user = this.data.User.userValidation({userId: true})
  }

  return (dimensions && user)
}

PackingLabel.prototype.prepareSave = function () {
  return {
    "package_name": this.data.Dimensions.getDimensionsName(),
    "height": this.data.Dimensions.getDimensionsHeight(),
    "width": this.data.Dimensions.getDimensionsWidth(),
    "length": this.data.Dimensions.getDimensionsLength(),
    "weightLb": this.data.Dimensions.getDimensionsWeightLB(),
    "weightOz": this.data.Dimensions.getDimensionsWeightOZ(),
    "user_id": this.data.User.getUserId(),
    "packing_type": 'order_fbm'
  }
}

PackingLabel.prototype.prepareSaveMetric = function () {
  return {
    "package_name": this.data.Dimensions.getDimensionsName(),
    "height": this.data.Dimensions.getDimensionsHeight(),
    "width": this.data.Dimensions.getDimensionsWidth(),
    "length": this.data.Dimensions.getDimensionsLength(),
    "weightKg": this.data.Dimensions.getDimensionsWeightLB(),
    "weightGr": this.data.Dimensions.getDimensionsWeightOZ(),
    "user_id": this.data.User.getUserId(),
    "packing_type": 'order_fbm'
  }
}
