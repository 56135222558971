<template>
  <div class="fragment">


    <div v-for="(engravingItem, index) in FBM.data.Engravings" :key="index">
      <div class="order-create__title-horizontal"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <TitleHorizontal
            :value="' #' + (parseInt(index) + 1)"
            :rightBtn="true"
            :rightBtnType="'delete'"
            :rightBtnText="$t('common_delete.localization_value.value')"
            @rightBtnClick="FBM.removeEngraving(parseInt(index))"
        />
      </div>

      <EngravingItem
          :engravingItem="engravingItem"
          :engravingsCount="FBM.data.Engravings.length"
          :engravingCustomOptions="engravingCustomOptions"
          :index="index"
          @changeCustomization="(oldCustomization, val, count) => $emit('changeCustomization', oldCustomization, val, count)"
          @changeQuantity="(customization, val) => $emit('changeQuantity', customization, val)"
      />

    </div>

    <div class="custom-row">
      <div class="custom-col mb-0">
        <div class="order-create__product-btn add-product-btn-wrap mb-2">
          <div class="order-create__product-btn-i add-product-btn site-link"
               @click="FBM.addEngraving()"
          >
            <div class="admin-edit" @click="editTranslate(['common_AddProduct'])"></div>
            +  {{$t('common_AddNew.localization_value.value')}} {{$t('fbm_EngravingFileName.localization_value.value')}}
          </div>
        </div>
      </div>
    </div>




  </div>
</template>

<script>
import EngravingItem
  from "@/components/modules/OrdersFBMModule/components/components/chunks/EngravingFile/components/EngravingItem/EngravingItem";
import TitleHorizontal from "@/components/coreComponents/TitleHorizontal/TitleHorizontal";

export default {
  name: "Engraving",
  components: {TitleHorizontal, EngravingItem},

  props: {
    FBM: Object,
    engravingCustomOptions: Array,
  },



}
</script>

<style scoped>

</style>