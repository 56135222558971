<template>

  <div class="order-create__row custom-row">
    <div class="order-create__col custom-col custom-col--sm-50 custom-col--wfc"
    >
      <RadioDefault
              :label="'None'"
              name="fedexRadioList2345"
              :value="FBM.data.giftPackagingType === '' || FBM.data.giftPackagingType === null"
              @input="changeGiftPackagePrice('')"
      />
    </div>
    <div class="order-create__col custom-col custom-col--sm-50 custom-col--wfc d-flex"
         v-if="giftPackagingPrice"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editPopup(giftPackagingPrice)"></div>
      <RadioDefault
              class="wfc"
              :label="`${giftPackagingPrice.currentTranslate.name} $${giftPackagingPrice.price}`"
              name="fedexRadioList2345"
              :value="checkActiveCheckbox(giftPackagingPrice)"
              @input="changeGiftPackagePrice(giftPackagingPrice, giftPackagingType)"
      />

      <v-popover
          v-if="giftPackagingPrice"
          class="site-tooltip site-tooltip--radio-after"
          :disabled="!tooltipAddProductActive"
          offset="5"
          placement="top-left"
          trigger="hover"
      >

        <TooltipBtn/>

        <template slot="popover">
          <div class="box-tooltip">
            <div class="box-tooltip__img">
              <img style="max-width: 56px"
                   v-if="Object.keys(giftPackagingType.file_entity).length" :src="`data:${giftPackagingType.file_entity.mime_type};base64,${giftPackagingType.file_entity.fileContentBase64}`"
                   alt="img">
              <img v-else src="/img/common/box-img.png" alt="img">
            </div>
            <div class="box-tooltip__content">
              <p>
                <b>{{giftPackagingPrice.currentTranslate.name}}</b>
              </p>
              <p>
                {{giftPackagingPrice.currentTranslate.description}}
              </p>
            </div>
          </div>

        </template>
      </v-popover>
    </div>

    <PackingTypeEditPopup
            v-if="isActiveEditPopup"
            :item="itemEdit"
            @close="closeEditPopup"
    />

  </div>
</template>

<script>
  import RadioDefault from "../../../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import PackingTypeEditPopup
    from "../../../../../../../coreComponents/Popups/PackingTypeEditPopup/PackingTypeEditPopup";
  import TooltipBtn from "../../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  export default {
    name: "GiftPackaging",
    components: {TooltipBtn, PackingTypeEditPopup, RadioDefault},

    props: {
      FBM: Object,
    },

    data() {
      return {
        tooltipAddProductActive: true,
        giftPackagingType: null,
        giftPackagingPrice: null,

        isActiveEditPopup: false,
        itemEdit: {}
      }
    },

    mounted() {
      this.$store.dispatch('getDeliveryPackingTypeGiftForUser').then((response) => {
        this.giftPackagingType = this.getRespPaginateData(response).length ? this.getRespPaginateData(response)[0] : null
        this.giftPackagingPrice = this.getRespPaginateData(response).length ? this.getRespPaginateData(response)[0]?.user_packing_price : null
        console.log(3333333333333, this.giftPackagingType);
      })
    },

    methods: {
      changeGiftPackagePrice(price, type) {
        this.FBM.setGiftPackagingPrice(price)
        console.log(type);
        this.FBM.setGiftPackagingType(type)
      },

      checkActiveCheckbox(item) {
        return this.FBM.data.giftPackagingPrice?.id === item.id
      },

      editPopup(item){
        this.itemEdit = item
        this.isActiveEditPopup = true
      },

      closeEditPopup(){
        this.itemEdit = {}
        this.isActiveEditPopup = false
        this.$store.dispatch('getDeliveryPackingType')
      }

    }

  }
</script>

<style scoped>

</style>
