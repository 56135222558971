<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
        'fbm_orderId',
        'fbm_PayPalTransactions'
      ])"></div>
      <CardLeftBlock
              :backgroundImage="'consolidation'"
              :name="$t('fbm_orderId.localization_value.value')"
              :value="'#'+FBM.data.Order.getId()"
      >
        <template slot="body">
          <div class="card-detail-left__date">
            {{ FBM.data.Order.data.createdAt | moment("DD MMM, YYYY") }}
          </div>

          <div class="site-link brown text-decoration-underline mt-4">
            <div v-if="isAdmin && FBM.data.Order.data.historyOfChanged"
                 class="mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
              <div class="site-link text-decoration-underline btn-style brown"
                   @click="historyOfChangesFunc('fbm', FBM.data.Order.data.historyOfChanged)">
                {{$t('common_CheckHistory.localization_value.value')}}
              </div>
            </div>
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_backTo'])"></div>
                <router-link :to="$store.getters.GET_PATHS.ordersFBM" class="order-create__head-back">
                  <LinkBack
                          :value="$t('common_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{
                  'color-lightBlue': isConsolidation,
                  'color-violet': isLabel,
                  'color-orange': isWarehouse,
                  'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()
                 }"
            >
              <div class="admin-edit" @click="editTranslate([
                'fbm_OrderForConsolidation',
                'fbm_OrderFromWarehouse',
                'fbm_CreateLabel'
              ])"></div>
              <template v-if="isConsolidation">
                {{$t('fbm_OrderForConsolidation.localization_value.value')}}
              </template>
              <template v-if="isLabel">
                {{$t('fbm_CreateLabel.localization_value.value')}}
              </template>
              <template v-if="isWarehouse">
                {{$t('fbm_OrderFromWarehouse.localization_value.value')}}
              </template>
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <StepFour
                    :FBM="FBM"
                    :personal="FBM.data.Personal"
                    :deliveryPayPal="FBM.data.DeliveryPayPal"
                    :deliveryEtsy="FBM.data.DeliveryEtsy"
                    :proformProducts="FBM.data.ProformProducts"
                    :products="FBM.data.Products"
                    :show="true"
            />
          </div>
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
										'common_CreateCopy',
									])"></div>

              <MainButton
                      class="order-create__footer-btn-i wfc"
                      :value="$t('common_CreateCopy.localization_value.value')"
                      @click.native="copyOrder(FBM.data.Order.data.id, false)"
              />
            </div>
          </div>
        </template>
      </CardRightBlock>
    </div>

  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import StepFour from "../../components/FBMUserBlocks/StepFour/StepFour";
  import {FBM_ORDER_TYPES} from "../../../../../../staticData/staticVariables";
  import {proformMixins} from "../../../../../../mixins/proformMixins/proformMixins";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import {tabsMixins} from "../../../../../../mixins/creationPageMixins/tabsMixins";
  import {FBMMixins} from "../../../../../../mixins/FBMMixins/FBMMixins";

  export default {
    name: "OrdersFBMShow",
    components: {
      LinkBack,
      CardRightBlock,
      MainButton,
      CardLeftBlock,
      StepFour,
    },

    mixins: [proformMixins, tabsMixins, FBMMixins],

    props: {
      FBM: Object,
    },

    data() {
      return {
        FBM_ORDER_TYPES: FBM_ORDER_TYPES,
      }
    },

    methods: {},


  }
</script>

<style scoped>

</style>
