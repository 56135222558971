import {checkValid} from "../../../globalModels/functions/ModelsValidate";


export function GiftMassage() {

  this.checkValid = checkValid

  this.data = {
    giftMassage: '',
    giftPaper: '',
  }

  this.validation = {
    giftMassage: false,
  }

  this.validationTranslate = {
    giftMassage: '',
  }

  this.validationTxt = {
    giftMassage: false,
  }

}


/*****************   SETTERS   ******************/
GiftMassage.prototype.setGiftMassage = function(val){
  this.data.giftMassage = val;
}
GiftMassage.prototype.setGiftPaper = function(val){
  this.data.giftPaper = val;
}

/*****************   GETTERS   ******************/
GiftMassage.prototype.getGiftMassage = function(){
  return this.data.giftMassage;
}

GiftMassage.prototype.getGiftPaper = function(){
  return this.data.giftPaper;
}



/*****************   VALIDATION   ******************/

GiftMassage.prototype.giftMassageValidate = function(that){

  let validationItems = {
    giftMassage: this.data.giftMassage,
    giftPaper: this.data.giftPaper,
  }

  let validationOptions = {
    giftMassage: {type: ['empty']},
    giftPaper: {type: ['empty']},
  }

  if((this.data.giftPaper === '' || this.data.giftPaper === null) && this.data.giftMassage.length === 0){
    return true
  }

  if((this.data.giftPaper === '' || this.data.giftPaper === null) && this.data.giftMassage.length > 0) {
    that.openNotify('error', 'common_notificationGiftPaperRequired')
  }

  return this.checkValid(validationItems, validationOptions)
}
