<template>
  <div class="toggle-block__details w-100">
    <div class="toggle-block__details-head flex-column">
      <div class="toggle-block__details-title">
        <img v-if="FBM.data.shippingPartnerType.logo_base64" height="28" style="max-width: 100px" :src="FBM.data.shippingPartnerType.logo_base64" alt="ico">
      </div>
    </div>
    <div class="toggle-block__details-body"
    >
      <div class="custom-row">
        <div class="custom-col flex-column-reverse mb-2"
             v-if="getShippingPartner"
        >
          <template
              v-for="(itemShippingPartner, index) in getShippingPartner['shipping_methods']">
            <RadioDefault
                v-if="checkShippingPartnerRequireSignature(itemShippingPartner)"
                class="mb-2 w-auto"
                name="fedexRadioList"
                :key="index + 'shippingPartner'"
                :label="itemShippingPartner.name"
                :value="FBM.data.shippingPartner && FBM.data.shippingPartner.id === itemShippingPartner.id"
                :cardFlex="true"
                @mouseup.native="$emit('changeShippingPartner', itemShippingPartner)"
            >

              <template slot="card">
                <div v-if="APC_CAMBRIDGE.deliveryCompanyId === FBM.data.shippingPartnerType.delivery_company_id"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['fbm_companyMailPack','fbm_companyCourierPack', 'fbm_companyParcel'])"></div>
                  <TooltipTextHelperPlugin>
                    <template slot="text"><TooltipBtn class="tooltip-target"/></template>
                    <template slot="paragraph">
                      <span v-if="itemShippingPartner.id === 22">{{$t('fbm_companyMailPack.localization_value.value')}}</span>
                      <span v-if="itemShippingPartner.id === 23">{{$t('fbm_companyCourierPack.localization_value.value')}}</span>
                      <span v-if="itemShippingPartner.id === 24">{{$t('fbm_companyParcel.localization_value.value')}}</span>
                    </template>
                  </TooltipTextHelperPlugin>
                </div>
              </template>
            </RadioDefault>
          </template>
        </div>

        <div class="custom-col mb-2"
             v-if="FBM.data.shippingPartnerType.signature_price && FBM.data.shippingPartner.can_requaire_signature"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                      'fbm_TheSignFromRecepient',
                      'fbm_ServiceFee',
                      'fbm_ViewPrices'
                    ])"></div>
          <DefaultCheckbox
              class="bold"
              :label="$t('fbm_TheSignFromRecepient.localization_value.value')"
              :value="FBM.data.signFromRecipient"
              @input="val => $emit('changeSignFromRecipient', val)"
          />
          <div class="amount-row light pl-4 mt-2" v-if="Number(FBM.data.shippingPartnerType.signature_price) > 0">
            <template v-if="FBM.data.shippingPartnerType.delivery_service_code === FBM_SHIPPING_TYPES.FBM_SHIPPING_FEDEX.delivery_service_code">
              {{$t('fbm_ServiceFeeFedex.localization_value.value')}}
            </template>
            <template v-else-if="FBM.data.shippingPartnerType.delivery_service_code === FBM_SHIPPING_TYPES.FBM_SHIPPING_USPS.delivery_service_code">
              {{$t('fbm_ServiceFeeUsps.localization_value.value')}}
            </template>
            <template v-else>
              {{$t('fbm_ServiceFee.localization_value.value')}}
            </template>

            <!--<span class="ml-1">-->
            <!--  ${{FBM.data.shippingPartnerType.signature_price}}-->
            <!--</span>-->
          </div>
        </div>

        <div class="custom-col mb-2"
             v-if="FBM.data.shippingPartner.possible_saturday_delivery"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_PossibleSaturdayDelivery',])"></div>
          <DefaultCheckbox
              class="bold"
              :label="$t('fbm_PossibleSaturdayDelivery.localization_value.value')"
              v-model="FBM.data.possibleSaturdayDelivery"
              @changeCheckbox="val => $emit('calculatePriceUser', val)"
          />
        </div>

        <div class="custom-col mb-0"
             v-if="isAdminManage"
        >
          <div style="justify-content: flex-start;" class="toggle-block__details-bottom"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_CalculatePrice', 'fbm_reCreateLabel'])"></div>
            <MainButton
                class="wfc mr-3 mb-2"
                :value="$t('fbm_CalculatePrice.localization_value.value')"
                @click.native="val => $emit('calculatePriceAdmin', val)"
            />
            <MainButton
                v-if="isAdminManage && FBM.data.Order.data.fileLabel"
                class="wfc mb-2"
                :value="$t('fbm_reCreateLabel.localization_value.value')"
                @click.native="$emit('reCreateLabel')"
                v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBMBtn}"
            />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
import TooltipTextHelperPlugin from "@/components/coreComponents/TooltipTextHelperPlugin/TooltipTextHelperPlugin";
import TooltipBtn from "@/components/UI/tooltips/TooltipBtn/TooltipBtn";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import {APC_CAMBRIDGE, FBM_SHIPPING_TYPES} from "@/staticData/staticVariables";
export default {
  name: "ShippingMethods",

  components: {
    MainButton,
    DefaultCheckbox,
    TooltipBtn,
    TooltipTextHelperPlugin,
    RadioDefault
  },

  props: {
    FBM: Object,
    getShippingPartner: Object,
    isAdminManage: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      APC_CAMBRIDGE: APC_CAMBRIDGE,
      FBM_SHIPPING_TYPES: FBM_SHIPPING_TYPES,
    }
  },

  methods: {
    checkShippingPartnerRequireSignature(itemShippingPartner) {
      if(!this._.has(itemShippingPartner, 'requaire_signature')) return true

      if(this.FBM.data.signFromRecipient && itemShippingPartner.requaire_signature) return true

      if(!this.FBM.data.signFromRecipient && !itemShippingPartner.requaire_signature) return true

      return false
    },
  }

}
</script>

<style scoped>

</style>