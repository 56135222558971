<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <div class="set__name"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fba_addProduct'])"></div>
        {{ $t(`fba_addProduct.localization_value.value`) }}
      </div>

    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <SelectProductBlock
            v-for="(item, index) in productsDefault"
            :key="index+'product'"
            :item="item"
            :description="showProductDescription"
            :price="showProductPrice"
            :userId="parseInt(user.userId)"
            :productLabel="productLabel"
            :byStorageId="byStorageId"
            :largeCountColumn="true"
            @removeProduct="removeProduct(index)"
            @changeProductName="changeProductName"
            @changeProductQuantity="changeProductQuantity"
        />
        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col custom-col--83 custom-col--md-100">
            <div class="add-product-btn-wrap mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_AddProduct',])"></div>
              <span class="order-create__product-btn-i add-product-btn site-link"
                    @click="$emit('addProduct')">
              + {{$t('common_AddProduct.localization_value.value')}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex flex-wrap mt-2 scoped-btns"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'common_cancel',
            'common_addProducts',
          ])"></div>

        <span class="site-link site-link--alt mr-3 mb-2 mt-2"
              @click="$emit('close')"
        >
              {{ $t(`common_cancel.localization_value.value`) }}
            </span>

        <MainButton
            class="wfc"
            :value="$t(`common_addProducts.localization_value.value`)"
            @click.native="addProduct"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "../../../commonModals/Modal";
import MainButton from "../../../UI/buttons/MainButton/MainButton";
import SelectProductBlock from "../../../coreComponents/SelectProductBlock/SelectProductBlock";
import {productForCellMixin} from "@/mixins/productMixins/productForCellMixin";

export default {
  name: "ProductsPopup",
  components: {
    Modal,
    MainButton,
    SelectProductBlock,
  },

  mixins: [productForCellMixin],

  props: {
    OrderModel: Object,
    user: Object,
    productsDefault: Array,
    productLabel: String,
    showProductDescription: {
      type: Boolean,
      default: false,
    },
    showProductPrice: {
      type: Boolean,
      default: false,
    },
    byStorageId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {

    }
  },

  mounted() {
    this.OrderModel.setProductsDefault('')
  },

  methods: {
    addProduct(){
      if(!this.OrderModel.productsDefaultValidation(this.showProductDescription)) return
      this.productsDefault.map((item) => {
        let newId = this.OrderModel.addProductForCells(item)
        this.getCellsForUser(this.OrderModel.data.productsForCellArray[newId], this.OrderModel.data.User.getUserId(), this.OrderModel.data?.Order.data.warehouse?.id)
      })
      this.$emit('close')
    },

    changeProductName(item, val) {
      item.setProduct(val)
    },

    changeProductQuantity(item, val) {
      item.setProductCount(val)
    },

    removeProduct(index) {
      this.productsDefault.splice(index, 1)
    },

    // let cells = []
    // productEntity.cells.map(cellItem => {
    //   let cellOptions = productEntity.cells.map(cell => {return cell.cell})
    //   let cell = new ProductCells(cellOptions, cellItem.cell, false, cellItem.item_quantity)
    //   cells.push(cell)
    // })



  },
}
</script>

<style scoped>

</style>
