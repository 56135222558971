<template>
  <div class="fragment">
    <OrdersFBMTableUserHead
            :showFilter="showFilter"
            :navTabs="navTabs"
            :countFilterParams="countFilterParams"
            @toggleFilter="toggleFilter"
            @filter="$emit('filter')"
            @changeTab="(data) => $emit('changeTab', data)"
            @reload="$emit('reload')"
            @selectAll="selectAll"
            @generatePdf="$emit('generatePdf')"
            @generateBarcodePdf="$emit('generateBarcodePdf')"
            @royalMailManifest="$emit('royalMailManifest')"
            @payFBM="$emit('payFBM')"
            @thermalPrint="$emit('thermalPrint', {selected: true})"
            @getMailLabel="$emit('getMailLabel', {selected: true})"
    />
    <div class="table-filter-wrap">

      <OrdersFBMTableUserFilter
              :showFilter="showFilter"
              :filterGetParams="filterGetParams"
              :navTabs="navTabs"
              @close="closeFilter"
              @changeFilter="data => $emit('changeFilter', data)"
              @resetFilter="$emit('resetFilter')"
      />

      <OrdersFBMTableUserTable
              @showMore="$emit('showMore')"
              @resetFilter="$emit('resetFilter')"
              :countFilterParams="countFilterParams"
              :navTabs="navTabs"
              :selectedNow="selectedNow"
              @reload="$emit('reload')"
              @copyOrder="(id) => $emit('copyOrder', id)"
              @deleteOrder="(id) => $emit('deleteOrder', id)"
              @thermalPrint="(data) => $emit('thermalPrint', data)"
              @downloadFiles="type => $emit('downloadFiles', type)"
              @getLabelFBM="val => $emit('getLabelFBM', val)"
              @getBarcodeFBM="val => $emit('getBarcodeFBM', val)"
              @reloadLabelFBM="val => $emit('reloadLabelFBM', val)"
              @cancelPrepayOrder="id => $emit('cancelPrepayOrder', id)"
              @paginate="(page) => {$emit('paginate', page)}"
              @changePerPage="(perPage) => {$emit('changePerPage', perPage)}"
      />
    </div>


  </div>
</template>

<script>
  import OrdersFBMTableUserHead from "./OrdersFBMTableUserHead/OrdersFBMTableUserHead";
  import OrdersFBMTableUserFilter from "./OrdersFBMTableUserFilter/OrdersFBMTableUserFilter";
  import OrdersFBMTableUserTable from "./OrdersFBMTableUserTable/OrdersFBMTableUserTable.vue";


  export default {
    name: "OrdersFBMTableUser",
    components: {
      OrdersFBMTableUserHead,
      OrdersFBMTableUserFilter,
      OrdersFBMTableUserTable,
    },

    props: {
      navTabs: Object,
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data() {
      return {
        showFilter: false,
        selectedNow: false,
        isModalStatusOrderDetailsPopup: false
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      selectAll(val) {
        this.selectedNow = val
      },


    }
  }
</script>

<style scoped>

</style>
