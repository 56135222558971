<template>
  <div class="fragment">
    <div class="section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['shippingCost_from',])"></div>
      {{$t('shippingCost_from.localization_value.value')}}
    </div>

    <div class="custom-row"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'shippingCost_ua',
          'shippingCost_us',
          'shippingCost_pl',
          'shippingCost_lv',
          'shippingCost_gb',
          ])"></div>
      <div
          class="custom-col custom-col--33 custom-col--sm-50"
          v-for="(item, index) in getFromWarehouses()"
          :key="index"
      >
        <template
            v-if="index === SHIPPING_COST_CALC_WAREHOUSE_UKRAINE.name || (index === SHIPPING_COST_CALC_WAREHOUSE_LATVIA.name && !isAdmin)"
          >
          <LargeRadio
              class="soft-text"
              :checked="true"
              :label="$t(`shippingCost_${index}.localization_value.value`)"
              :name="'type1'"
              @change="changeFromWarehouse(item)"
          />
        </template>
        <template v-else>
          <LargeRadio
              class="soft-text"
              :label="$t(`shippingCost_${index}.localization_value.value`)"
              :name="'type1'"
              @change="changeFromWarehouse(item)"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import LargeRadio from "../../../../../../UI/radiobuttons/LargeRadio/LargeRadio";
import {
  SHIPPING_COST_CALC_WAREHOUSE_UKRAINE,
  SHIPPING_COST_CALC_WAREHOUSE_LATVIA,
  SHIPPING_COST_CALC_WAREHOUSE_POLAND, SHIPPING_COST_CALC_WAREHOUSE_USA
} from "../../../../../../../staticData/staticVariables";

export default {
  name: "FromWarehouseBlock",

  components: {
    LargeRadio
  },

  props: {
    calculator: Object,
    createData: Object,
  },

  created() {
    this.calculator.setSenderWarehouseId(this.isBaltic ? this.SHIPPING_COST_CALC_WAREHOUSE_LATVIA.id : this.SHIPPING_COST_CALC_WAREHOUSE_UKRAINE.id)
  },

  data() {
    return {
      SHIPPING_COST_CALC_WAREHOUSE_UKRAINE: SHIPPING_COST_CALC_WAREHOUSE_UKRAINE,
      SHIPPING_COST_CALC_WAREHOUSE_LATVIA: SHIPPING_COST_CALC_WAREHOUSE_LATVIA,
    }
  },

  methods: {
    getFromWarehouses() {
      let warehouses = {...this.createData?.from_warehouses} || {}

      let isPolandAlternativeAddress = this.getUserAlternativeContact(this.getCurrentUser)?.country_group?.fromPlGroup
      if (!(this.isUkrainian || this.isPoland || isPolandAlternativeAddress) && !this.isCzechCountry && !this.isAdmin) {
        delete warehouses[SHIPPING_COST_CALC_WAREHOUSE_POLAND.name]
      }
      if ((this.isBaltic || this.isCzechCountry) && !this.isAdmin) {
        delete warehouses[SHIPPING_COST_CALC_WAREHOUSE_UKRAINE.name]
      }
      if (this.isPoland && !this.isAdmin) {
        let polandWarehouses = {}
        if(this._.has(warehouses, SHIPPING_COST_CALC_WAREHOUSE_USA.name))
          polandWarehouses[SHIPPING_COST_CALC_WAREHOUSE_USA.name] = warehouses[SHIPPING_COST_CALC_WAREHOUSE_USA.name]

        if(this._.has(warehouses, SHIPPING_COST_CALC_WAREHOUSE_POLAND.name))
          polandWarehouses[SHIPPING_COST_CALC_WAREHOUSE_POLAND.name] = warehouses[SHIPPING_COST_CALC_WAREHOUSE_POLAND.name]

        warehouses = polandWarehouses
      }
      else if (!this.isAdmin && !this.isLatviaCountry) {
        delete warehouses[SHIPPING_COST_CALC_WAREHOUSE_LATVIA.name]
      }

      return warehouses
    },

    changeFromWarehouse(item) {
      this.calculator.setSenderWarehouseId(item)
    }
  }
}
</script>

<style scoped>

</style>