<template>
    <div class="table-card__item-content"
         :class="{show: show}"
    >
      <div class="table-card__item-head">
        <div class="table-card__item-head-row d-flex">
          <div class="table-card__item-number table-card__item-number--small"
          >
            {{item.id}}
          </div>
        </div>
      </div>
      <transition name="slide">
        <div class="table-card__item-body"
             v-if="show"
        >
          <div class="table-card__item-row custom-row">
            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('fbm_manifestDocNum.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <ValueHelper
                    :value="item.document_number"
                />
              </div>
            </div>

            <div class="table-card__item-col custom-col custom-col--50">
              <div class="table-card__item-label">
                {{$t('fbm_manifestCreated.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                {{ item.created_at | moment("DD MMM, YYYY HH:mm") }}
              </div>
            </div>

          </div>

          <div class="table-card__item-btn pl-2">
            <div class="table-card__item-btn-i">
              <LinkButton
                  v-if="isAdmin && item.file_entity && item.file_entity.uuid"
                  :value="$t(`common_print.localization_value.value`)"
                  :type="'print'"
                  @click.native="$emit('getManifestFile', item.file_entity.uuid)"
              />
            </div>
          </div>
        </div>
      </transition>
      <div class="table-card__item-footer">
        <div class="table-card__item-show"
             @click="show = !show"
        >
          {{$t('common_showMore.localization_value.value')}}
        </div>
      </div>
    </div>
</template>

<script>
  // import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";

  export default {
    name: "ConsolidationUnionTableMobile",

    components: {
      LinkButton,
      ValueHelper,
    },

    props: {
      item: Object,
      selectedNow: Boolean,
    },

    data() {
      return {
        show: false,
        hideEditField: false,
      }
    },

    methods: {

    },

  }
</script>

<style scoped>
  .conso-user-select {
    min-width: 150px;
  }

  .table-card .ellipsis {
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: inline-block;
  }
</style>
