<template>
  <modal
      @close="$emit('close')"
      class="custom-popup wrong-orders-for-popup"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'fbm_wrongOrdersForConso',
          ])"></div>
        {{$t('fbm_wrongOrdersForConso.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'fbm_theOrders',
            'fbm_wrongOrdersPopupText',
          ])"></div>
        <p class="fsz14">
          {{$t('fbm_theOrders.localization_value.value')}}
          <template v-for="(item, index) in invalidOrders">
<!--            <a-->
<!--                class="site-link"-->
<!--                :href="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.order_type_id).name + '/' + item.id"-->
<!--                :key="index"-->
<!--            >-->
              <router-link
                  class="site-link"
                  :to="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.order_type_id).name + '/' + item.id"
                  :key="index"
              >
                {{item.id}}
              </router-link>,

<!--            </a>,-->
          </template>
          {{$t('fbm_wrongOrdersPopupText.localization_value.value')}}
        </p>
      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'common_cancel',
            'common_CreateConsolidation',
          ])"></div>
        <span
            class="site-link site-link--alt buy-label__btn-i mr-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
            :value="$t('common_CreateConsolidation.localization_value.value')"
            class="buy-label__btn-i wfc "
            @click.native="createConsolidationFromValidOrders"
        />
      </div>
    </template>
  </modal>

</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import {FBMMixinsHelper} from "../../../../../mixins/FBMMixins/FBMMixins";

  export default {
    name: "WrongOrdersForPopup",

    components: {
      Modal,
      MainButton,
    },

    mixins: [
      FBMMixinsHelper
    ],

    props: {
      invalidOrders: Array,
      validOrderIds: Array,
      type: String
    },

    methods: {
      createConsolidationFromValidOrders() {
        this.$router.push({path: this.$store.getters.GET_PATHS.ordersConsolidationCreate + '/' + this.type, query: {setInitOrders: this.validOrderIds.join(',')}})
      }
    }
  }

</script>

<style lang="scss">

  @import "../../../../../scss/mixins/mixins";

  .wrong-orders-for-popup .modal-component__inner{
    max-width: 570px;
  }

  @include for-550{
    .wrong-orders-for-popup .buy-label__btn{
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start!important;
    }

    .wrong-orders-for-popup .buy-label__btn-i.mr-3{
      margin-bottom: 15px;
    }
  }

</style>
