<template>
  <div>
    <ManifestsHead
        :showFilter="showFilter"
        :countFilterParams="countFilterParams"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeTab="(data) => $emit('changeTab', data)"
        @reload="$emit('reload')"
        @selectAll="selectAll"
    />

    <div class="table-filter-wrap">

<!--      <ManifestsFilter-->
<!--          :showFilter="showFilter"-->
<!--          :filterGetParams="filterGetParams"-->
<!--          @close="closeFilter"-->
<!--          @changeFilter="data => $emit('changeFilter', data)"-->
<!--          @resetFilter="$emit('resetFilter')"-->
<!--      />-->

      <ManifestsTable
          :countFilterParams="countFilterParams"
          :selectedNow="selectedNow"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>


  import ManifestsHead from "./ManifestsHead/ManifestsHead";
  // import ManifestsFilter from "./ManifestsFilter/ManifestsFilter";
  import ManifestsTable from "./ManifestsTable/ManifestsTable";

  export default {
    name: "ManifestsTableUser",

    components: {
      ManifestsHead,
      // ManifestsFilter,
      ManifestsTable,
    },

    props: {
      typeShops: String,
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{
        showFilter: false,
        selectedNow: false,
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.filterGetParams = newVal
      },
      countFilterParams(newVal) {
        this.countFilterParams = newVal
      },
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      selectAll(val) {
        this.selectedNow = val
      },
    }
  }
</script>

<style scoped>

</style>
