<template>
  <div class="toggle-block__details">
    <div class="toggle-block__details-head">
      <div class="toggle-block__details-title"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_EtsyAddress'])"></div>
        {{$t(`${translationTitle}.localization_value.value`)}}
      </div>
    </div>

    <div class="toggle-block__details-body" >
      <div class="custom-row">
        <div class="custom-col custom-col--50 custom-col--xl-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_Address'])"></div>
          <DefaultInput
                  class="white"
                  :label="$t('fbm_Address.localization_value.value')"
                  :type="'text'"
                  v-bind:class="{'ui-no-valid': delivery.validation.address}"
                  :errorTxt="$t(`${delivery.validationTranslate.address}.localization_value.value`)"
                  :error="delivery.validation.address"
                  :maxLength="'35'"
                  :caption="$t('express_maxSymbols.localization_value.value')"
                  v-model="delivery.delivery.address"
          />
        </div>
        <div class="custom-col custom-col--50 custom-col--xl-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_addressSecond', 'express_maxSymbols'])"></div>
          <DefaultInput
                  :label="$t('common_addressSecond.localization_value.value')"
                  :type="'text'"
                  v-bind:class="{'ui-no-valid': delivery.validation.address2}"
                  :errorTxt="$t(`${delivery.validationTranslate.address2}.localization_value.value`)"
                  :error="delivery.validation.address2"
                  :maxLength="'35'"
                  :caption="$t('express_maxSymbols.localization_value.value')"
                  v-model="delivery.delivery.address2"
          />
        </div>
        <div class="custom-col custom-col--50 custom-col--xl-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_City'])"></div>
          <DefaultInput
                  class="white"
                  :label="$t('fbm_City.localization_value.value')"
                  :type="'text'"
                  v-bind:class="{'ui-no-valid': delivery.validation.city}"
                  :errorTxt="$t(`${delivery.validationTranslate.city}.localization_value.value`)"
                  :error="delivery.validation.city"
                  v-model="delivery.delivery.city"
          />
        </div>
        <div class="custom-col custom-col--50 custom-col--xl-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_Region'])"></div>
          <DefaultSelect
              v-if="delivery.delivery.country && delivery.delivery.country.id === USA_COUNTRY_ID && FBM.getRegions().length > 0"
              :options="FBM.getRegions()"
              :label="$t('fbm_Region.localization_value.value')"
              v-bind:class="{'ui-no-valid': delivery.validation.region}"
              :errorTxt="$t(`${delivery.validationTranslate.region}.localization_value.value`)"
              :error="delivery.validation.region"
              :optionsLabel="'code'"
              :selected="getSelectedRegion()"
              @change="val => {$emit('changeRegion', val)}"
          />
          <DefaultInput
                  v-else
                  class="white"
                  :label="$t('fbm_Region.localization_value.value')"
                  :type="'text'"
                  v-bind:class="{'ui-no-valid': delivery.validation.region}"
                  :errorTxt="$t(`${delivery.validationTranslate.region}.localization_value.value`)"
                  :error="delivery.validation.region"
                  v-model="delivery.delivery.region"
          />
        </div>
        <div class="custom-col custom-col--50 custom-col--xl-100 position-relative"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_Country', 'express_ViewCustomLimits'])"></div>
          <DefaultSelect
                  class="white"
                  :options="options"
                  :label="$t('fbm_Country.localization_value.value')"
                  :optionsLabel="'label'"
                  v-bind:class="{'ui-no-valid': delivery.validation.country}"
                  :errorTxt="$t(`${delivery.validationTranslate.country}.localization_value.value`)"
                  :error="delivery.validation.country"
                  :selected="delivery.delivery.country"
                  @change="val => {$emit('changeCountry', val)}"
          />

          <span class="site-link site-link-alt mt-3 wfc	d-flex toggle-block__view-link"
                @click="$emit('showCustomLimitsPopup')"
          >
											{{$t('express_ViewCustomLimits.localization_value.value')}}
										</span>
        </div>
        <div class="custom-col custom-col--25 custom-col--xl-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_ZipCode'])"></div>
          <DefaultInput
                  class="white"
                  :label="$t('fbm_ZipCode.localization_value.value')"
                  :type="'text'"
                  v-bind:class="{'ui-no-valid': delivery.validation.zip}"
                  :errorTxt="$t(`${delivery.validationTranslate.zip}.localization_value.value`)"
                  :error="delivery.validation.zip"
                  v-model="delivery.delivery.zip"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {USA_COUNTRY_ID} from "../../../../../../../staticData/staticVariables";
  export default {
    name: "AddressBlock",
    components: {DefaultSelect, DefaultInput},

    props: {
      options: Array,
      translationTitle: String,
      delivery: Object,
      // delivery: Object,
      // delivery: Object,
      FBM: Object,
    },

    computed: {
      getTypeAddressPayPal() {
        return this.FBM.data.transactionAddress === this.FBM.data.transactionAddressTypes.paypal
      },
    },

    data() {
      return {
        USA_COUNTRY_ID: USA_COUNTRY_ID,
      }
    },

    methods: {
      getSelectedRegion() {
        return this.FBM.getRegions().find(item => {
          return item?.code === this.delivery.getRegion()
        })
      },
    }
  }
</script>

<style scoped>

</style>
