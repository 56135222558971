import { render, staticRenderFns } from "./ManifestsTable.vue?vue&type=template&id=e2eb49cc&scoped=true"
import script from "./ManifestsTable.vue?vue&type=script&lang=js"
export * from "./ManifestsTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2eb49cc",
  null
  
)

export default component.exports