<template>
  <div>
    <div class="toggle-block__type-item"
         v-for="(item, index) in shippingCompanyData"
         :key="index"
    >
      <RadioLabelPicture
          name="shippingPartner"
          :label="item.name"
          :value="FBM.data.shippingPartnerType.delivery_company_id === item.delivery_company_id"
          @change="$emit('changeShippingPartnerType', item)"
      >
        <template slot="ico">
          <img v-if="item.logo_base64" height="12" :src="item.logo_base64" alt="ico">
        </template>
      </RadioLabelPicture>
    </div>
  </div>
</template>

<script>
import RadioLabelPicture from "@/components/UI/radiobuttons/RadioLabelPicture/RadioLabelPicture";

export default {
  name: "ShippingCompany",
  components: {RadioLabelPicture},

  props: {
    shippingCompanyData: Array,
    FBM: Object,
  },

}
</script>

<style scoped>

</style>