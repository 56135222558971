<template>
  <div class="toggle-block__details w-100 ml-5">
    <div class="toggle-block__details-head flex-column">
      <div class="toggle-block__details-title">
        <img v-if="FBM.data.shippingPartnerType.logo_base64" height="22" :src="FBM.data.shippingPartnerType.logo_base64" alt="ico">
      </div>
    </div>
    <div class="toggle-block__details-body"
    >
      <div class="custom-row" v-if="FBM.data.shippingAPIPrices && Object.keys(FBM.data.shippingAPIPrices).length">
        <div class="custom-col flex-column-reverse mb-2">
          <RadioDefault
              class="mb-2"
              name="fedexRadioListRate"
              v-for="(itemShippingAPIPrices, index) in FBM.data.shippingAPIPrices"
              :key="index + 'shippingPrice'"
              :label="shippingPriceRadioLabel(itemShippingAPIPrices)"
              :value="FBM.data.shippingAPIPrice && FBM.data.shippingAPIPrice.driver_delivery_method_id === itemShippingAPIPrices.driver_delivery_method_id"
              :disabled="!isLabel && !isAdmin"
              @click.native="$emit('changeShippingAPIPrices', itemShippingAPIPrices)"
          />
        </div>

        <template v-if="isLabel || isAdminManage">
          <template v-if="_.has(FBM.data.shippingPartnerType, 'signature_price') && getShippingPartner['shipping_methods'].length === 1">

            <div class="custom-col mb-2"
                 v-if=" FBM.data.shippingPartnerType.signature_price && FBM.data.shippingPartner.can_requaire_signature"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                        'fbm_TheSignFromRecepient',
                        'fbm_ServiceFee',
                        'fbm_ViewPrices'
                        ])"></div>
              <DefaultCheckbox
                  class="bold"
                  :label="$t('fbm_TheSignFromRecepient.localization_value.value')"
                  :value="FBM.data.signFromRecipient"
                  @input="val => $emit('changeSignFromRecipient', val)"
              />
              <div class="amount-row light pl-4 mt-2" v-if="Number(FBM.data.shippingPartnerType.signature_price) > 0">
                <template v-if="FBM.data.shippingPartnerType.delivery_service_code === FBM_SHIPPING_TYPES.FBM_SHIPPING_FEDEX.delivery_service_code">
                  {{$t('fbm_ServiceFeeFedex.localization_value.value')}}
                </template>
                <template v-else-if="FBM.data.shippingPartnerType.delivery_service_code === FBM_SHIPPING_TYPES.FBM_SHIPPING_USPS.delivery_service_code">
                  {{$t('fbm_ServiceFeeUsps.localization_value.value')}}
                </template>
                <template v-else>
                  {{$t('fbm_ServiceFee.localization_value.value')}}
                </template>

                <!--                        <span class="ml-1">-->
                <!--                          ${{FBM.data.shippingPartnerType.signature_price}}-->
                <!--                        </span>-->
              </div>
            </div>

            <div class="custom-col mb-2"
                 v-if="FBM.data.shippingPartner.possible_saturday_delivery"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fbm_PossibleSaturdayDelivery',])"></div>
              <DefaultCheckbox
                  class="bold"
                  :label="$t('fbm_PossibleSaturdayDelivery.localization_value.value')"
                  v-model="FBM.data.possibleSaturdayDelivery"
                  @changeCheckbox="$emit('calculatePriceUser')"
              />
            </div>

            <div class="custom-col mb-0"
                 v-if="isAdminManage"
            >
              <div style="justify-content: flex-start;" class="toggle-block__details-bottom"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_CalculatePrice', 'fbm_reCreateLabel'])"></div>
                <MainButton
                    class="wfc mr-3"
                    :value="$t('fbm_CalculatePrice.localization_value.value')"
                    @click.native="$emit('calculatePriceAdmin')"
                />
                <MainButton
                    v-if="isAdminManage && FBM.data.Order.data.fileLabel"
                    class="wfc"
                    :value="$t('fbm_reCreateLabel.localization_value.value')"
                    @click.native="$emit('reCreateLabel')"
                    v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBMBtn}"
                />
              </div>
            </div>

          </template>
        </template>



      </div>

      <div class="custom-row" v-if="FBM.data.shippingAPIPrices && Object.keys(FBM.data.shippingAPIPrices).length === 0">
        <div class="custom-col">
          <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_NoPricesAvailable'])"></div>
            {{$t('fbm_NoPricesAvailable.localization_value.value')}}
          </div>
          <div v-if="isAdminManage">
            <div class="toggle-block__details-bottom"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fbm_CalculatePrice',])"></div>
              <MainButton
                  class="wfc"
                  :value="$t('fbm_CalculatePrice.localization_value.value')"
                  @click.native="$emit('calculatePriceAdmin')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import {FBMMixins} from "@/mixins/FBMMixins/FBMMixins";
import {FBM_SHIPPING_TYPES} from "@/staticData/staticVariables";

export default {
  name: "ShippingRates",

  components: {MainButton, DefaultCheckbox, RadioDefault},

  mixins: [FBMMixins],

  props: {
    FBM: Object,
    // getShippingPartner: Object,
    shippingCompanyData: Array,
    isAdminManage: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    getShippingPartner(){
      let partner = this._.find(this.shippingCompanyData, {delivery_company_id: this.FBM.data.shippingPartnerType.delivery_company_id})

      if(!partner) {
        partner = this._.first(this.shippingCompanyData)
      }
      return partner
    },
  },

  data() {
    return {
      FBM_SHIPPING_TYPES: FBM_SHIPPING_TYPES,
    }
  },

  methods: {
    shippingPriceRadioLabel(itemShippingAPIPrices) {
      let label = itemShippingAPIPrices.name + ' $' + itemShippingAPIPrices.shipmentCost + ' ' +
          `${itemShippingAPIPrices.warning_message ? itemShippingAPIPrices.warning_message : ''}`
      if(parseFloat(itemShippingAPIPrices.insuranceCost) > 0){
        label += ' insurance $' + itemShippingAPIPrices.insuranceCost
      }
      return label
    },
  }

}
</script>

<style scoped>

</style>