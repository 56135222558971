<template>
  <div class="order-create__row custom-row">

    <template v-if="!isWarehouseLodz && !isWarehouseCambridge">

      <div class="order-create__col custom-col custom-col--33 custom-col--md-25 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_InsuranceNo'])"></div>
        <RadioDefault
            :label="$t('fbm_InsuranceNo.localization_value.value')"
            :name="'insuranceRadio'"
            @input="changeIsInsurance(false)"
            :value="!FBM.data.isInsurance"
            :disabled="checkboxState"
        />
      </div>
      <div class="order-create__col custom-col custom-col--50 custom-col--md-75 custom-col--xs-100 flex-column mb-0"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
        'fbm_InsuranceYes'
      ])"></div>
        <RadioDefault
            :label="$t('fbm_InsuranceYes.localization_value.value')"
            :name="'insuranceRadio'"
            @input="changeIsInsurance(true)"
            :value="FBM.data.isInsurance"
            :disabled="checkboxState"
        />

        <div class="custom-row mt-4 mb-5">
          <div class="custom-col custom-col--33"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_InsuranceAmount', 'fbm_InsuranceHelp'])"></div>
            <InputSum
                v-bind:class="{'ui-no-valid': FBM.validation.insuranceAmount}"
                :label="$t('fbm_InsuranceAmount.localization_value.value')"
                :icoType="'dollar'"
                :errorTxt="serverError ? FBM.validationTxt.insuranceAmount : $t(`${FBM.validationTranslate.insuranceAmount}.localization_value.value`)"
                :error="FBM.validation.insuranceAmount"
                v-model="FBM.data.insuranceAmount"
                :disabled="!FBM.data.isInsurance || checkboxState"
                :caption="$t('fbm_InsuranceHelp.localization_value.value')"
                @focusOut="changeInsurance"
            />
          </div>
          <div class="custom-col custom-col--33"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_InsuranceCost'])"></div>
            <InputSum
                :label="$t('fbm_InsuranceCost.localization_value.value')"
                :icoType="'dollar'"
                v-bind:class="{'ui-no-valid': FBM.validation.insuranceCost}"
                :errorTxt="serverError ? FBM.validationTxt.insuranceCost : $t(`${FBM.validationTranslate.insuranceCost}.localization_value.value`)"
                :error="FBM.validation.insuranceCost"
                :value="getInsuranceCost"
                :disabled="true"
            />
<!--                :caption="$t('fbm_InsuranceHelpCost.localization_value.value')"-->
          </div>

          <div class="custom-col custom-col--33"
               v-if="!isLabel"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_InsuranceExtended'])"></div>
            <InputSum
                :label="$t('fbm_InsuranceExtended.localization_value.value')"
                :icoType="'dollar'"
                :value="FBM.data.insuranceFromDeliveryCompany"
                :disabled="true"
                :preWrap="true"
            />
          </div>
        </div>
      </div>

    </template>

    <template v-else>
      <div class="order-create__col custom-col custom-col--50 custom-col--md-75 custom-col--xs-100 flex-column mb-0">
        <div class="custom-row mt-4">
          <div class="custom-col custom-col--33"
               v-if="!isLabel"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_InsuranceExtended'])"></div>
            <InputSum
                :label="$t('fbm_InsuranceExtended.localization_value.value')"
                :icoType="'dollar'"
                :value="FBM.data.insuranceFromDeliveryCompany"
                :disabled="true"
            />
          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
  import RadioDefault from "../../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import InputSum from "../../../../../../UI/inputs/InputSum/InputSum";
  import {FBMMixinsHelper} from "../../../../../../../mixins/FBMMixins/FBMMixins";
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  export default {
    name: "InsuranceBlock",

    components: {
      // DefaultCheckbox,
      InputSum,
      RadioDefault,
    },

    mixins: [FBMMixinsHelper],

    props: {
      FBM: Object,
      checkboxState: {
        type: Boolean,
        default: false,
      }
    },

    computed: {
      getInsuranceCost(){
        if(this.FBM.data.insuranceAmount === '' || this.FBM.data.insuranceAmount === '0.00'){
          return 0
        }
        // return parseFloat((parseInt((this.FBM.data.insuranceAmount - 0.01) / 100) + 1) * 1.10).toFixed(2)
        // this.FBM.setInsuranceCost(parseFloat(this.FBM.data.insuranceAmount * this.getPercentOrderOutboundInsuranceCost / 100).toFixed(2))
        this.FBM.setInsuranceCost(this.FBM.data.shippingAPIPrice?.insuranceCost || '0.00')
        return this.FBM.getInsuranceCost()
      },

      getShippingPartnerType(){
        return this.FBM.data.shippingPartnerType
      }
    },

    watch: {
      /**
       * set Insurance from shipping company
       */
      getShippingPartnerType(newVal) {
        console.log(555);
        if(newVal && newVal?.insurance_price){
          this.FBM.setInsuranceFromDeliveryCompany(newVal.insurance_price)
        }
      },
    },

    data() {
      return {
        serverError: false,
      }
    },

    mounted() {

      /**
       * set Insurance from shipping company
       */
      let shippingPartnerType = this.FBM.data.shippingPartnerType
      if(shippingPartnerType && shippingPartnerType?.insurance_price){
        this.FBM.setInsuranceFromDeliveryCompany(shippingPartnerType.insurance_price)
      }
    },

    methods: {
      changeIsInsurance(val) {
        this.FBM.setIsInsurance(val)

        this.changeInsurance()
      },

      changeInsurance(){
        this.$emit('changeInsurance')
      },
    }

  }
</script>

<style scoped>

</style>
