<template>

  <div class="custom-row">
    <div class="custom-col custom-col--40 custom-col--md-100">

      <div class="mb-3 fsz14">
        <div class="mb-2"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_userChoose'])"></div>
          {{$t('fbm_userChoose.localization_value.value')}}
        </div>
        {{activeUserType}}
      </div>

      <div class="order-create__toggle-block toggle-block small-size">
        <div class="toggle-block__type min-w-140">
          <div class="toggle-block__type-list">
            <div class="d-flex align-items-center mb-3">
              <RadioDefault
                      class="wfc"
                      :label="'None'"
                      name="fedexRadioList2"
                      :value="FBM.data.packagingAdminType === ''"
                      @input="changePackageType(false)"
              />
            </div>

            <div class="d-flex align-items-center mb-3"
                 v-for="(item, index) in packageTypes"
                 :key="index"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editPopup(item)"></div>
              <RadioDefault
                      class="wfc"
                      :label="item.currentTranslate.name"
                      name="fedexRadioList2"
                      :value="checkActivePackagingType(item)"
                      @input="changePackageType(item)"
              />

              <TooltipTextHelperPlugin>
                <template slot="text">
                  <TooltipBtn/>
                </template>
                <template slot="paragraph">
                  <div class="status-tooltip">
                    <div class="box-tooltip__img">
                      <img style="max-width: 56px"
                           v-if="!Array.isArray(item.file_entity) && Object.keys(item.file_entity).length" :src="`data:${item.file_entity.mime_type};base64,${item.file_entity.fileContentBase64}`"
                           alt="img">
                      <img v-else src="/img/common/box-img.png" alt="img">
                    </div>
                  </div>
                </template>
              </TooltipTextHelperPlugin>
            </div>
          </div>
        </div>

        <template v-for="(item, index) in packageTypes">

          <div class="toggle-block__details"
               :key="index"
               v-if="checkActivePackagingType(item)"
          >
            <div class="toggle-block__details-head">
              <div class="toggle-block__details-title">
                <span>Packaging Prices</span>
              </div>
            </div>

            <div class="toggle-block__details-body">
              <div class="custom-row">
                <div class="custom-col flex-column-reverse mb-2"
                     :key="getPackingPrice"
                >
                  <template
                          v-for="(itemPackingPrices, index) in item.packing_prices"
                  >
                    <RadioDefault
                            :key="index"
                            class="mb-2"
                            :label="itemPackingPrices.currentTranslate.name + ' & price: ' + itemPackingPrices.price"
                            name="fedexRadioList23423"
                            :value="checkActivePackagingAdminPrice(itemPackingPrices)"
                            @input="changePackagingAdminPrice(item, itemPackingPrices)"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </template>

      </div>
    </div>

    <div class="custom-col custom-col--25 custom-col--md-100">
      <div class="mb-3 fsz14 mobile-hide">
        <div class="mb-2">
          <br/>
        </div>
        <br/>
      </div>
      <div class="order-create__toggle-block toggle-block small-size">
        <div class="toggle-block__details">
          <div class="toggle-block__details-head">
            <div class="toggle-block__details-title"
                 v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fbm_giftBag'])"></div>
              <span>{{$t('fbm_giftBag.localization_value.value')}}</span>
            </div>
          </div>
          <div class="toggle-block__type min-w-140">
            <div class="toggle-block__type-list">
              <div class="d-flex align-items-center mb-2">
                <RadioDefault
                    class="wfc"
                    :label="'None'"
                    name="giftPackPriceRadio"
                    :value="FBM.data.giftPackagingAdminType === '' || FBM.data.giftPackagingAdminType === null"
                    @input="changeGiftPackagingAdminPrice(false, null)"
                />
              </div>

              <div
                  v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <template
                    v-for="(priceItem, index) in giftPackTypePrices"
                >
                  <div :key="index">
                    <div class="admin-edit" @click="editPopup(priceItem)"></div>
                    <RadioDefault

                        class="mb-2"
                        :label="priceItem.currentTranslate.name + ' & price: ' + priceItem.price"
                        name="giftPackPriceRadio"
                        :value="checkActiveGiftPackagingAdminPrice(priceItem)"
                        @input="changeGiftPackagingAdminPrice(priceItem, giftPackType)"
                    />
                  </div>


                </template>

              </div>
            </div>
          </div>
        </div>


      </div>
    </div>

    <PackingTypeEditPopup
            v-if="isActiveEditPopup"
            :item="itemEdit"
            @close="closeEditPopup"
    />

  </div>
</template>

<script>
  import RadioDefault from "../../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import PackingTypeEditPopup from "../../../../../../coreComponents/Popups/PackingTypeEditPopup/PackingTypeEditPopup";
  import TooltipTextHelperPlugin from "@/components/coreComponents/TooltipTextHelperPlugin/TooltipTextHelperPlugin";

  export default {
    name: "AdminPackingPrices",
    components: {
      TooltipTextHelperPlugin,
      PackingTypeEditPopup,
      TooltipBtn,
      RadioDefault
    },

    props: {
      FBM: Object,
    },

    computed: {
      activeUserType() {
        return this.FBM.data.packagingType?.translationStorage?.en?.name
      },

      getPackingPrice(){
        return this.FBM.data.packagingAdminPrice?.id
      }
    },

    data() {
      return {
        packageTypes: [],
        giftPackType: null,
        giftPackTypePrices: [],
        isActiveEditPopup: false,
        itemEdit: {}
      }
    },

    mounted() {

      this.packageTypes = this.FBM.data.packagingTypes
      this.giftPackType = this.FBM.data.giftPackagingTypes.length > 0 ? this.FBM.data.giftPackagingTypes[0] : null
      if (this.giftPackType) {
        this.giftPackTypePrices = this.giftPackType?.packing_prices || []
      }
      console.log(this.giftPackType);

      // let packingPrice = this.FBM.getPackagingAdminPrice()

      // if(!packingPrice){
      //   let firstType = this._.first(this.packageTypes)
      //   let firstPrice = this._.first(firstType['packing_prices'])
      //   this.FBM.setPackagingAdminPrice(firstPrice)
      // }

      // this.$store.dispatch('getDeliveryPackingTypeForAdmin').then(response => {
      //   this.packageTypes = this.getRespPaginateData(response)
      // })

    },

    methods: {
      changePackageType(item) {
        if(!item){
          this.FBM.setPackagingAdminType('')
          this.FBM.setPackagingAdminPrice(null)
        }
        this.FBM.setPackagingAdminType(item)
        this.FBM.setPackagingAdminPrice(null)
        // this.FBM.setPackagingAdminPrice(this._.first(item['packing_prices']))
      },

      changePackagingAdminPrice(item, itemPackingPrices) {
        this.FBM.setPackagingAdminType(item)
        this.FBM.setPackagingAdminPrice(itemPackingPrices)
      },

      checkActivePackagingAdminPrice(item) {
        return this.FBM.data.packagingAdminPrice?.id === item.id
      },

      changeGiftPackagingAdminPrice(itemPackingPrices, packType) {
        this.FBM.setGiftPackagingAdminPrice(itemPackingPrices)
        this.FBM.setGiftPackagingAdminType(packType)
      },

      checkActiveGiftPackagingAdminPrice(item) {
        console.log(5555555555555, this.FBM.data.giftPackagingAdminPrice, item.id);
        return this.FBM.data.giftPackagingAdminPrice?.id === item.id
      },


      checkActivePackagingType(item) {
        return this.FBM.data.packagingAdminType?.id === item.id
      },

      editPopup(item){
        this.itemEdit = item
        this.isActiveEditPopup = true
      },

      closeEditPopup(){
        this.itemEdit = {}
        this.isActiveEditPopup = false
        this.$store.dispatch('getDeliveryPackingType')
      }
    },
  }
</script>

<style scoped>

</style>
