import {checkValid} from "../../../globalModels/functions/ModelsValidate";


export function Comment() {

  this.checkValid = checkValid

  this.data = {
    comment: '',
  }

  this.validation = {
    comment: false,
  }

  this.validationTranslate = {
    comment: '',
  }

  this.validationTxt = {
    comment: false,
  }

}


/*****************   SETTERS   ******************/
Comment.prototype.setComment = function(val){
  this.data.comment = val;
}

/*****************   GETTERS   ******************/
Comment.prototype.getComment = function(){
  return this.data.comment;
}



/*****************   VALIDATION   ******************/

Comment.prototype.commentValidate = function(){

  let validationItems = {
    comment: this.data.comment,
  }

  let validationOptions = {
    comment: {type: ['empty']},
  }


  return this.checkValid(validationItems, validationOptions)
}
