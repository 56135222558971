<template>
  <div class="fragment">
    <OrdersFBMCreationPage
            v-if="!advancedUI && loading"
            :FBM="FBM"
            @addProformProduct="addProformProduct"
            @removeProformProduct="removeProformProduct"
            @prepareSaveFBM="prepareSaveFBM"
            @changeUser="changeUser"
    />

    <OrdersFBMAdvancedCreationPage
        v-else-if="loading"
        :FBM="FBM"
        @addProformProduct="addProformProduct"
        @removeProformProduct="removeProformProduct"
        @prepareSaveFBM="prepareSaveFBM"
        @changeUser="changeUser"
    />
  </div>
</template>

<script>
  import OrdersFBMCreationPage from "./OrdersFBMCreationPage/OrdersFBMCreationPage";
  import {OrderFBM} from "../../models/OrderFBM";
  import {proformMixins} from "../../../../../mixins/proformMixins/proformMixins";
  import {
    AUSTRALIA_ID, CANADA_COUNTRY_ID,
    FBM_FROM_TRANSACTIONS,
    FBM_ORDER_TYPES, MEXICO_COUNTRY_ID,
    USA_COUNTRY_ID
  } from "../../../../../staticData/staticVariables";
  import {FBMMixins} from "../../../../../mixins/FBMMixins/FBMMixins";
  import {vatNumberMixin} from "../../../../../mixins/commonMixins/vatNumberMixin";
  import {hsCodeMixin} from "../../../../../mixins/commonMixins/hsCodeMixin";
  import OrdersFBMAdvancedCreationPage from "./OrdersFBMAdvancedCreationPage/OrdersFBMAdvancedCreationPage";
  import {userAvatarMixin} from "../../../../../mixins/usersMixins/userAvatarMixin";

  export default {
    name: "OrdersFBMCreation",

    components: {
      OrdersFBMAdvancedCreationPage,
      OrdersFBMCreationPage,
    },

    mixins: [proformMixins, FBMMixins, vatNumberMixin, hsCodeMixin, userAvatarMixin],

    watch: {
      loadUserAuthorizedData: function () {
        this.getFBMUserAuthorizedData()
      },

      selectedCountry: function(country){
        this.$store.dispatch('getCountryDataById', country.id).then(() => {
          let countryWithCountryGroups = this.$store.getters.getCountryGroup

          this.FBM.setVisibleHSCode(this.getHSCodePermissionsByCountry(countryWithCountryGroups, true))
          this.FBM.setVisibleVatNumber(this.getVatNumberPermissionsByCountry(countryWithCountryGroups))
        }).catch(error => this.createErrorLog(error))
        if ((country.id === USA_COUNTRY_ID || country.id === AUSTRALIA_ID || country.id === CANADA_COUNTRY_ID || country.id === MEXICO_COUNTRY_ID)) {
          this.$store.dispatch('getRegionsByCountry', country.id).then(() => {
            this.FBM.setRegions(this._.toArray(this.$store.getters.getRegions))
          }).catch(error => this.createErrorLog(error))
        }
      },

      downloadPermissions() {
        this.checkPermission()
      },
    },

    data() {
      return {
        FBM: new OrderFBM(),
        FBM_ORDER_TYPES: FBM_ORDER_TYPES,
        advancedUI: false,

        loading: false,

        isFirstCreateCusClearance: true,
      }
    },

    mounted() {

      console.log(111111111, this.$route.params);

      if(this.downloadPermissions){
        if(!this.checkPermission()) return
      }

      if(this.loadUserAuthorizedData){
        this.getFBMUserAuthorizedData()
        this.advancedUI = this.isExpertUser
        if (this.advancedUI && this.FBM.data.User.user?.small_avatar_file && this.FBM.data.User.user?.small_avatar_file.length > 0) {
          this.FBM.data.User.user.avatar_files = this.FBM.data.User.user?.small_avatar_file
          this.setUserAvatar(this.FBM.data.User)
        }
      }

      /**
       * Set user if create inbound with return goods Id
       */
      if(this.$route.query.idReturned) {
        this.FBM.setIdReturned(this.$route.query.idReturned)
      }
      if(this.$route.query.returnedUserId && this.isAdmin) {
        this.$store.dispatch('fetchUsersFromFilter', `?filter[UserId]=${this.$route.query.returnedUserId}`)
            .then(response => {
              this.changeUser(this.getRespPaginateData(response)[0])
            }).catch(error => this.createErrorLog(error))
      }

      this.FBM.setTypeFBM(this.$route.params.type)

      this.initializeProductProformArray(this.FBM.data.ProformProducts)

      if(this.$route.query.request_id) {
        this.setCreateDataOptions()
      }

      this.checkAndSetOrderFromTransaction()

      // this.$store.dispatch('getOrdersTypes').then((response) => {
      //   console.log(response);
      // })
    },

    methods: {

      setCreateDataOptions() {
        let url = '?request_id=' + this.$route.query.request_id
        this.$store.dispatch('getOrdersFBMCreate', url).then(response => {
          if(this.getRespData(response)?.orderFbmInstance) {
            let order = this.getRespData(response)?.orderFbmInstance
            console.log(order);

            this.FBM.data.Order.setWarehouse(order['warehouse_storage'])

            this.FBM.setShippingPartner(order['delivery_method'])
            if(this._.has(order['delivery_method'], 'shipping_company')){
              this.FBM.setShippingPartnerType(order['delivery_method']['shipping_company'])
            }

            this.FBM.data.DeliveryPayPal.setDeliveryItem({
              address: '',
              address2: '',
              city: order['recipient_city'],
              region: order['recipient_region'],
              country: {
                id: order['recipientCountry']['id'],
                label: order['recipientCountry']['name'],
              },
              zip: order['recipient_zip_code']
            })

            if(Object.keys(order?.orderDimensions).length > 0){
              this.FBM.data.Dimensions.setDimensionsHeight(order?.orderDimensions[0]?.height || '')
              this.FBM.data.Dimensions.setDimensionsWidth(order?.orderDimensions[0]?.width || '')
              this.FBM.data.Dimensions.setDimensionsLength(order?.orderDimensions[0]['length'] || '')
              // if (isAdmin) {
              //   this.FBM.data.Dimensions.setDimensionsWeightLB(item?.orderDimensions[0]?.imperial?.weight_lb || '')
              //   this.FBM.data.Dimensions.setDimensionsWeightOZ(item?.orderDimensions[0]?.imperial?.weight_oz || '')
              // } else {
              this.FBM.data.Dimensions.setDimensionsWeightLB(order?.orderDimensions[0]?.linear?.weight_kg || '')
              this.FBM.data.Dimensions.setDimensionsWeightOZ(order?.orderDimensions[0]?.linear?.weight_g || '')
              // }
            }
          }
        })
      },

      checkAndSetOrderFromTransaction() {
        if(this.$route.params.typeTransaction && this.$route.params.id){
          let type = this._.find(FBM_FROM_TRANSACTIONS, {name: this.$route.params.typeTransaction})

          this.FBM.setFBMTypeTransaction({
            type: type.value,
            id: this.$route.params.id,
          })

          this.$store.dispatch('getIdentifyFBMOrderFromTrn', {data: {
              "from_model" : this.FBM.data.Order.getTransactionType(),
              "model_id": this.FBM.data.Order.getTransactionId()
            }}).then(response => {

            this.FBM.setTransactionInfo(
              response.data.data !== null ? response.data.data : false
            )
          }).catch(error => this.createErrorLog(error))
        }
      },

      getFBMUserAuthorizedData() {
        this.FBM.setUser(this.loadUserAuthorizedData.user)
        this.loading = true
      },

      addProformProduct(){
        let newIndex = this.FBM.addProformProduct()
        this.initializeProductProformArray(this.FBM.data.ProformProducts, newIndex)
      },

      removeProformProduct(index) {
        this.FBM.removeProformProduct(index)
      },

    },

  }
</script>

<style scoped>

</style>
