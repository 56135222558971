<template>
  <div class="fragment">
    <div class="order-create__row custom-row">
      <div class="custom-col custom-col--16 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['packaging_Length'])"></div>
        <DefaultInput
            :label="$t('packaging_Length.localization_value.value')"
            :type="'text'"
            v-bind:class="{'ui-no-valid': FBM.data.UVPrinting.data.Dimensions.validation.dimensionLength}"
            :errorTxt="$t(`${FBM.data.UVPrinting.data.Dimensions.validationTranslate.dimensionLength}.localization_value.value`)"
            :error="FBM.data.UVPrinting.data.Dimensions.validation.dimensionLength"
            v-model="FBM.data.UVPrinting.data.Dimensions.data.dimensionLength"
            @input.native="changeEngravingPrice($event, 'length')"
            @change="calculateUVPrintingPrice"
        />
      </div>
      <div class="custom-col custom-col--16 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['packaging_Width'])"></div>
        <DefaultInput
            :label="$t('packaging_Width.localization_value.value')"
            :type="'text'"
            v-bind:class="{'ui-no-valid': FBM.data.UVPrinting.data.Dimensions.validation.width}"
            :errorTxt="$t(`${FBM.data.UVPrinting.data.Dimensions.validationTranslate.width}.localization_value.value`)"
            :error="FBM.data.UVPrinting.data.Dimensions.validation.width"
            v-model="FBM.data.UVPrinting.data.Dimensions.data.width"
            @input.native="changeEngravingPrice($event, 'width')"
            @change="calculateUVPrintingPrice"
        />
      </div>
      <div class="custom-col custom-col--16 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_engravingCount'])"></div>
        <DefaultInput
            :label="$t('fbm_engravingCount.localization_value.value')"
            :type="'text'"
            v-bind:class="{'ui-no-valid': FBM.data.UVPrinting.validation.count}"
            :errorTxt="$t(`${FBM.data.UVPrinting.validationTranslate.count}.localization_value.value`)"
            :error="FBM.data.UVPrinting.validation.count"
            v-model="FBM.data.UVPrinting.data.count"
            @input.native="changeEngravingPrice($event, 'count')"
            @change="(val) => {calculateUVPrintingPrice(); $emit('changeQuantity', FBM.data.UVPrinting.data.customization, val)}"
        />
      </div>
      <div class="custom-col custom-col--16 custom-col--sm-50"
           v-if="!isWarehouseLodz"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['calculatorEngraving_product',])"></div>
        <DefaultSelect
            :options="UVPrintingCustomOptions"
            :label="$t('calculatorEngraving_product.localization_value.value')"
            :otherValue="'calculatorCustomization'"
            :selected="FBM.data.UVPrinting.data.customization"
            @change="changeCustomization"
        />
      </div>
      <div class="custom-col custom-col--16 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_engravingPrice'])"></div>
        <DefaultInput
            :label="$t('fbm_engravingPrice.localization_value.value')"
            :type="'text'"
            :disabled="true"
            v-model="FBM.data.UVPrinting.data.resultCost"
        />
      </div>
    </div>

    <div
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fbm_engravingAcceptFiles2'])"></div>
      {{ $t('fbm_engravingAcceptFiles3.localization_value.value') }}
    </div>
    <div class="order-create__row custom-row">
      <div class="custom-col position-relative"
           v-if="FBM.data.UVPrinting.data.File.data.maxCountFiles - Object.keys(FBM.data.UVPrinting.data.File.data.downloadFiles).length > 0">
        <DropZone class="drop-zone-bg"
                  :parentFiles="FBM.data.UVPrinting.data.File.data.files"
                  :multiple="true"
                  :maxCount="FBM.data.UVPrinting.data.File.data.maxCountFiles - FBM.data.UVPrinting.data.File.data.downloadFiles.length"
                  :maxSize="FBM.data.UVPrinting.data.File.data.maxSizeFiles"
                  @changeImg="(files, maxSize) => {changeFile(files, maxSize)}"
                  :accept="'/*'"
        />
        <div class="error-field ml-3" v-if="FBM.data.UVPrinting.data.File.validation.files">
          {{ $t('common_fileIsRequired.localization_value.value') }}
        </div>
      </div>

      <div class="custom-col mt-3"
           v-if="Object.keys(FBM.data.UVPrinting.data.File.data.downloadFiles).length > 0"
      >
        <div class="custom-row product-img-row">
          <div class="custom-col custom-col--16 custom-col--md-25 custom-col--sm-100"
               v-for="(item, index) in FBM.data.UVPrinting.data.File.data.downloadFiles"
               :key="index">
            <DownloadFileBlock
                :file="item"
                @removeFile="(id) => {removeFile(id, index)}"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import DownloadFileBlock from "@/components/coreComponents/DownloadFileBlock/DownloadFileBlock";
import DropZone from "@/components/coreComponents/DropZone/DropZone";
import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
import {FBMMixinsHelper} from "@/mixins/FBMMixins/FBMMixins";

export default {
  name: "UVPrinting",
  components: {DefaultSelect, DefaultInput, DownloadFileBlock, DropZone},

  mixins: [FBMMixinsHelper],

  props: {
    FBM: Object,
    UVPrintingCustomOptions: Array,
  },
  
  watch: {
    isWarehouseLodz() {
      if(this.isWarehouseLodz && this.FBM.data.UVPrinting.data.customization) {
        this.changeCustomization(null)
      }
    },
  },

  methods: {

    changeCustomization(val) {
      let oldCustomization = this.FBM.data.UVPrinting.data.customization
      this.FBM.data.UVPrinting.data.customization = val;
      this.calculateUVPrintingPrice();
      this.$emit('changeCustomization', oldCustomization, val, this.FBM.data.UVPrinting.data.count)
    },

    removeTenths() {
      // this.FBM.data.engravingLength = ('' + this.FBM.data.engravingLength).replace(',', '.').replace(/[^0-9.]/g, '')
      // this.FBM.data.engravingWidth = ('' + this.FBM.data.engravingWidth).replace(',', '.').replace(/[^0-9.]/g, '')
      // this.FBM.data.engravingCount = ('' + this.FBM.data.engravingCount).replace(',', '.').replace(/[^0-9.]/g, '')

      // console.log(this.FBM.data.engravingLength);
      if(('' + this.FBM.data.UVPrinting.data.Dimensions.data.dimensionLength).indexOf('.') > -1) this.FBM.data.UVPrinting.data.Dimensions.data.dimensionLength = this.FBM.data.UVPrinting.data.Dimensions.data.dimensionLength.split('.')[0]
      if(('' + this.FBM.data.UVPrinting.data.Dimensions.data.width).indexOf('.') > -1) this.FBM.data.UVPrinting.data.Dimensions.data.width = this.FBM.data.UVPrinting.data.Dimensions.data.width.split('.')[0]
      if(('' + this.FBM.data.UVPrinting.data.count).indexOf('.') > -1) this.FBM.data.UVPrinting.data.count = this.FBM.data.UVPrinting.data.count.split('.')[0]
    },

    changeEngravingPrice(event, type = false) {

      if(type) {
        switch (type) {
          case 'length':
            this.FBM.data.UVPrinting.data.Dimensions.data.dimensionLength = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
            break
          case 'width':
            this.FBM.data.UVPrinting.data.Dimensions.data.width = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
            break
          case 'count':
            this.FBM.data.UVPrinting.data.count = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
            break
          default:
            break
        }

        /**
         * Remove Tenths
         */
        this.removeTenths()
      }
    },

    calculateUVPrintingPrice() {
      if(
          (!this.FBM.data.UVPrinting.data.Dimensions.data.dimensionLength || this.FBM.data.UVPrinting.data.Dimensions.data.dimensionLength.length === 0) ||
          (!this.FBM.data.UVPrinting.data.Dimensions.data.width || this.FBM.data.UVPrinting.data.Dimensions.data.width.length === 0) ||
          (!this.FBM.data.UVPrinting.data.count || this.FBM.data.UVPrinting.data.count.length === 0)
      ) {
        return
      }

      let data = {
        offer: this.FBM.data.UVPrinting.data.customization ? this.FBM.data.UVPrinting.data.customization?.id : null,
        length: this.FBM.data.UVPrinting.data.Dimensions.data.dimensionLength,
        width: this.FBM.data.UVPrinting.data.Dimensions.data.width,
        quantity: this.FBM.data.UVPrinting.data.count,
      }

      this.$store.dispatch('getUVPrintingCalcResultInOrder', data).then(response => {
        if(this.getRespData(response)?.rate) {
          this.FBM.data.UVPrinting.data.resultCost = parseFloat(this.getRespData(response)?.rate).toFixed(2)
        }
      })
    },

    changeFile(files, maxSizeError) {
      this.FBM.data.UVPrinting.data.File.setFiles(files)

      this.FBM.data.UVPrinting.data.File.setFilesMaxSizeError(maxSizeError)

      this.calculateUVPrintingPrice()
    },

    removeFile(id, index){

      let text = {
        title: 'common_AreYouSure',
        txt: '',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('removeUserDocument', id).then(() => {

          this.FBM.data.UVPrinting.data.File.removeDownloadFile(index)
          // this.FBM.data.EngravingFile.setDownloadFiles([])

          this.calculateUVPrintingPrice()
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)


    },
  },

}
</script>

<style scoped>

</style>