import {
  CAMBRIDGE_WAREHOUSE,
  FBM_ORDER_TYPES,
  LODZ_WAREHOUSE,
  ROYAL_MAIL_COMPANY
} from "../../../../staticData/staticVariables";
import {OrderFBM} from "./OrderFBM";
import _ from "lodash";

OrderFBM.prototype.getValidationProformProductsByCount = function (isHasSelectedProduct, isHasSelectedProform) {
  /**
   * Validation "ProformProducts"
   * if order don't has selected "Products" or "ProformProducts" has selected item
   */
  return !(isHasSelectedProduct && !isHasSelectedProform)
}

OrderFBM.prototype.getValidationProductsByCount = function (isHasSelectedProform, isHasSelectedProduct) {
  /**
   * Validation "Products"
   * if order don't has selected "ProformProducts" or "Products" has selected item
   */
  return !(isHasSelectedProform && !isHasSelectedProduct)
}

OrderFBM.prototype.vatNumberValidation = function () {

  if(!this.getVisibleVatNumber()) return true

  if(this.data.Order.data.warehouse.id === LODZ_WAREHOUSE.storage_id ||
    this.data.Order.data.warehouse.id === CAMBRIDGE_WAREHOUSE.storage_id) return true


  let validationItems = {
    vatNumber: this.getVatNumber(),
  }

  let validationOptions = {
    vatNumber: {type: ['empty']}
  }

  return (this.checkValid(validationItems, validationOptions) && this.data.vatNumberFile.fileSizeValidation())
}


OrderFBM.prototype.FBMInfoValidate = function (
  {
    comment,
    lotNumber,
    insuranceAmount,
    insuranceCost,
    giftMassage,
    userId,
  }) {

  let userValidate = true

  if(userId){
    userValidate = this.data.User.userValidation({userId: true})
  }

  let validationItems = {
    comment: this.data.comment,
    lotNumber: this.data.lotNumber,
    insuranceAmount: this.data.insuranceAmount,
    insuranceCost: this.data.insuranceCost,
    giftMassage: this.data.giftMassage,
  }

  let validationOptions = {
    comment: {type: comment ? ['latin', 'empty'] : []},
    lotNumber: {type: lotNumber ? ['latin', 'empty'] : []},
    insuranceAmount: {type: insuranceAmount ? ['not-zero'] : []},
    insuranceCost: {type: insuranceCost ? ['empty'] : []},
    giftMassage: {type: giftMassage ? ['empty'] : []},
  }


  return (this.checkValid(validationItems, validationOptions) && userValidate)
}


OrderFBM.prototype.FBMSendFromValidate = function (adminEdit = false) {

  if(adminEdit) return true

  let validationItems = {
    sendFrom: this.data.sendFrom,
  }

  let validationOptions = {
    sendFrom: {type: ['empty']},
  }

  let defaultValid = this.checkValid(validationItems, validationOptions)

  if(!defaultValid) {
    return defaultValid
  }

  let valid = true
  if(
    this.data.sendFrom?.first_name.length === 0 ||
    this.data.sendFrom?.last_name.length === 0 ||
    this.data.sendFrom?.company_name.length === 0
  ) {
    this.validation.sendFrom = true
    this.validationTranslate.sendFrom = 'error_required'
    valid = false
  } else {
    this.validation.sendFrom = false
    this.validationTranslate.sendFrom = ''
  }

  return defaultValid && valid
}


OrderFBM.prototype.FBMDeliveryValidate = function () {
  let fbm = this.data
  if(fbm.transactionAddress !== fbm.transactionAddressTypes.etsy){
    return fbm.DeliveryPayPal.deliveryInfoValidate()
  }
  return fbm.DeliveryEtsy.deliveryInfoValidate()
}

OrderFBM.prototype.FBMPersonalValidate = function () {
  let fbm = this.data
  if(fbm.transactionAddress !== fbm.transactionAddressTypes.etsy){
    return fbm.Personal.personalInfoPartsValidate({
      personalName: true,
      // personalPhone: true,
      emailOptional: true,
    })
  }
  return fbm.PersonalEtsy.personalInfoPartsValidate({
    personalName: true,
    // personalPhone: true,
    emailOptional: true,
  })
}

OrderFBM.prototype.insuranceValidate = function () {
  if(this.data.isInsurance){
    return this.FBMInfoValidate({insuranceAmount: true})
  }
  return true
}

OrderFBM.prototype.FBMPhoneValidate = function () {
  if(!this.getShippingPartner()?.required_phone) {
    return true
  }
  let fbm = this.data
  if(fbm.transactionAddress !== fbm.transactionAddressTypes.etsy){
    return fbm.Personal.personalInfoPartsValidate({
      personalPhone: true,
    })
  }
  return fbm.PersonalEtsy.personalInfoPartsValidate({
    personalPhone: true,
  })
}




OrderFBM.prototype.FBMFirstStepValidate = function (adminEdit = false) {
  return (this.FBMSendFromValidate(adminEdit) && this.FBMPersonalValidate() && this.FBMDeliveryValidate())
}




OrderFBM.prototype.FBMProformAndProductValidate = function (isAdminManage, isUSACountry = true) {
  let valid = true
  let isHasSelectedProform = this.isHasSelectedProformProducts()
  let isHasSelectedProduct = this.isHasSelectedProducts()


  if(this.getValidationProformProductsByCount(isHasSelectedProduct, isHasSelectedProform)){
    /**
     * Proform Products Only For ORDER CONSOLIDATION & ORDER LABEL
     */
    if(this.data.Order.getType().id !== FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.id){
      console.log(11111111111, this.data.visibleHSCode);
      this.data.ProformProducts.map(item => {
        if(!item.productProformValidation({
          proformCategory: true,
          itemsQuantity: true,
          price: true,
          HSCode: this.data.visibleHSCode,
          proformMaterial: true
        }))
          valid = false
      })
    }
  }

  if(this.getValidationProductsByCount(isHasSelectedProform, isHasSelectedProduct)){
    /**
     * Proform Products Only For ORDER CONSOLIDATION & ORDER WAREHOUSE
     */
    if(this.data.Order.getType().id !== FBM_ORDER_TYPES.FBM_ORDER_LABEL.id) {
      this.data.productsForCellArray.map(item => {
        if (!item.data.Product.productValidate({productObject: true, count: true, description: !isUSACountry, price: !isUSACountry, hsCode: !isUSACountry})){
          valid = false
        }
      })
    }
  }

  return valid
}




OrderFBM.prototype.FBMSecondStepValidate = function (isAdminManage, isUSACountry = true) {

  let valid = true
  let isHasSelectedProform = this.isHasSelectedProformProducts()
  let isHasSelectedProduct = this.isHasSelectedProducts()


  if(this.getValidationProformProductsByCount(isHasSelectedProduct, isHasSelectedProform)){
    /**
     * Proform Products Only For ORDER CONSOLIDATION & ORDER LABEL
     */
    if(this.data.Order.getType().id !== FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.id){
      console.log(11111111111, this.data.visibleHSCode);
      this.data.ProformProducts.map(item => {
        if(!item.productProformValidation({
          proformCategory: true,
          itemsQuantity: true,
          price: true,
          HSCode: this.data.visibleHSCode,
          proformMaterial: true
        }))
          valid = false
      })
    }
  }

  /**
   * Dimensions
   */

  // if(this.data.Order.getType().id !== FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.id) {
    if(isAdminManage){
      if (!this.data.AdminDimensions.dimensionsPartialValidate({
        // height: true,
        // width: true,
        weightLB: true,
        weightOZ: true,
      })){
        valid = false
      }
    } else {
      if (!this.data.Dimensions.dimensionsPartialValidate({
        // height: true,
        // width: true,
        weightLB: true,
        weightOZ: true,
      })){
        valid = false
      }
    // }

  }

  if(this.getValidationProductsByCount(isHasSelectedProform, isHasSelectedProduct)){
    /**
     * Proform Products Only For ORDER CONSOLIDATION & ORDER WAREHOUSE
     */
    if(this.data.Order.getType().id !== FBM_ORDER_TYPES.FBM_ORDER_LABEL.id) {
      this.data.productsForCellArray.map(item => {
        if (!item.data.Product.productValidate({productObject: true, count: true, description: !isUSACountry, price: !isUSACountry, hsCode: !isUSACountry})){
          valid = false
        }
      })
    }
  }

  //orders must have at least one product, validation below
  if(this.data.Order.getType().id === FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.id && !this.validateProductsForCellArray()) {
    return valid = false
  }
  if(this.data.Order.getType().id === FBM_ORDER_TYPES.FBM_ORDER_LABEL.id && !this.validateProformProducts()) {
    return valid = false
  }
  if(this.data.Order.getType().id === FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.id && !isHasSelectedProform && !isHasSelectedProduct &&
    (!this.validateProductsForCellArray() && !this.validateProformProducts())) {
    valid = false
  } else {
    this.validation.productsForCellArray = false
    this.validation.ProformProducts = false
  }


  if(this.data.isEngraving && !this.validationEngraving()) {
    valid = false
  }

  if(this.data.TShirtPrinting.data.isTShirtPrinting && (!this.data.TShirtPrinting.dataValidation() || !this.data.TShirtPrinting.data.File.firstValidation())) {
    valid = false
  }

  if(this.data.Embroidery.data.isEmbroidery && (!this.data.Embroidery.dataValidation() || !this.data.Embroidery.data.File.firstValidation())) {
    valid = false
  }

  if(this.data.LeatherStamp.data.isLeatherStamp && (!this.data.LeatherStamp.dataValidation() || !this.data.LeatherStamp.data.File.firstValidation())) {
    valid = false
  }
  if(this.data.LeatherStamp.data.isLeatherStamp && this.data.LeatherStamp.data.stampingLine.replaceAll(' ', '').length > 10) {
    this.data.LeatherStamp.validationTranslate.stampingLine = 'calculatorEngraving_maxLength'
    this.data.LeatherStamp.validation.stampingLine = true
    valid = false
  } else if(this.data.LeatherStamp.dataValidation()){
    this.data.LeatherStamp.validationTranslate.stampingLine = ''
    this.data.LeatherStamp.validation.stampingLine = false
  }

  if(this.data.PhotoPrint.data.isPhotoPrint && (!this.data.PhotoPrint.dataValidation() || !this.data.PhotoPrint.data.File.firstValidation())) {
    valid = false
  }

  if(this.data.UVPrinting.data.isUVPrinting && (!this.data.UVPrinting.dataValidation() || !this.data.UVPrinting.data.File.firstValidation())) {
    valid = false
  }


  // if(!this.dataInfoValidate({lotNumber: true}))
  //   valid = false



  // console.log(valid);

  return valid
}

// OrderFBM.prototype.OrderFBMProductValidation = function (item) {
//   return item.productValidate({productObject: true, count: true})
// }

OrderFBM.prototype.productsDefaultValidation = function (isNotUSACountry) {
  let valid = true
  this.data.Products.map(item => {
    if (!item.productValidate({productObject: true, count: true, description: isNotUSACountry, price: isNotUSACountry}))
      valid = false
  })
  return valid
}


OrderFBM.prototype.FBMThirdStepValidate = function () {
  let valid = true
  let validationItems = {
    giftMassage: this.data.giftMassage,
    IOSS: this.data.IOSS?.number ? this.data.IOSS.number : '',
    transactionAmount: this.data.transactionAmount,
  }

  let validationOptions = {}

  if(!(this.data.giftPaper === '' || this.data.giftPaper === null)){
    validationOptions['giftMassage'] = {type: ['empty']}
  }

  // if(this.checkIsMarketplaceCompany(this.data.shippingPartner.id)){
  if(this.data.shippingPartner?.need_paid_vat && !this.data.visibleVatNumber){
    if(!this.getVisibleVatNumber()){
      validationOptions['transactionAmount'] = {type: ['not-zero', 'empty']}
    }
  } else {
    if(this.checkIsMarketplaceCompany(this.data.shippingPartner.id)){
      validationOptions['IOSS'] = {type: ['empty']}
      console.log(777);
    }
    else if(!this.vatNumberValidation())
      valid = false
  }

  // vat file validation
  if(this.data.Order.getType().id !== FBM_ORDER_TYPES.FBM_ORDER_LABEL.id &&
    (this.getVisibleVatNumber() || this.checkIsMarketplaceCompany(this.data.shippingPartner.id)) &&
    !this.data.vatNumberFile.firstValidation() &&
    (this.data.Order.data.warehouse.id !== LODZ_WAREHOUSE.storage_id &&
      this.data.Order.data.warehouse.id !== CAMBRIDGE_WAREHOUSE.storage_id)
  ) {
    valid = false
  }

  if(!this.data.vatNumberChoose && this.data.shippingPartner?.id === ROYAL_MAIL_COMPANY.id) {
    this.validation.vatNumberChoose = true
    this.validationTranslate.vatNumberChoose = 'fbm_chooseVatType'
    valid = false
  } else {
    this.validation.vatNumberChoose = false
    this.validationTranslate.vatNumberChoose = ''
  }

  console.log(validationItems);
  console.log(validationOptions);
  console.log(this.checkValid(validationItems, validationOptions));

  return (this.checkValid(validationItems, validationOptions) && this.insuranceValidate({insuranceAmount: true}) && valid)
}

OrderFBM.prototype.FBMAdminValidation = function (isAdminManage, isUSACountry) {

  if(!this.FBMProformAndProductValidate(isAdminManage, isUSACountry)) return {productDataForCountry: false}

  return this.FBMInfoValidate({userId: true}) &&
    this.FBMFirstStepValidate(true) &&
    this.FBMSecondStepValidate(isAdminManage, isUSACountry) &&
    this.FBMThirdStepValidate()
}

OrderFBM.prototype.FBMCellCountsValidate = function (isAdminManage) {
  if(!isAdminManage) return true

  let valid = true
  this.data.productsForCellArray.map(product => {
    if(product.data.productCount !== product.data.scannedCount) {
      valid = false
    }
  })

  return valid
}

OrderFBM.prototype.FBMScannedInKievValidate = function () {

  let valid = true
  this.data.productsForCellArray.map(product => {
    if(product.data.scannedInKievCount === 0) {
      valid = false
    }
  })

  return valid
}

OrderFBM.prototype.FBMAdminValidationPackingPrice = function () {
  if(this.getPackagingAdminType()){
    return _.has(this.getPackagingAdminPrice(), 'id')
  }
  return true
}

OrderFBM.prototype.FBMAdminValidationShippingAPIPrice = function () {

  return true
}

OrderFBM.prototype.validateProductsForCellArray = function () {
  if(this.data.productsForCellArray.length > 0) {
    this.validation.productsForCellArray = false
    this.validationTranslate.productsForCellArray = ''
  }
  else {
    this.validation.productsForCellArray = true
    this.validationTranslate.productsForCellArray = 'error_required'
    return false
  }

  return true
}

OrderFBM.prototype.validateEngravingTime = function () {

  let validationItems = {
    engravingTime: this.data.engravingTime,
  }

  console.log(this.data.engravingTime);

  let validationOptions = {
    engravingTime: {type: ['numeric']},
  }

  return this.checkValid(validationItems, validationOptions)
}

OrderFBM.prototype.validationEngraving = function () {
  let valid = true
  this.data.Engravings.map(item => {
    if(!item.dataFBMEngravingValidation() || !item.data.EngravingFile.firstValidation()) {
      valid = false
    }
  })
  return valid

  // let validationItems = {
  //   engravingLength: this.data.engravingLength,
  //   engravingWidth: this.data.engravingWidth,
  //   engravingCount: this.data.engravingCount,
  // }
  //
  // let validationOptions = {
  //   engravingLength: {type: ['numeric', 'empty']},
  //   engravingWidth: {type: ['numeric', 'empty']},
  //   engravingCount: {type: ['numeric', 'empty']},
  // }
  //
  // return this.checkValid(validationItems, validationOptions)
}


OrderFBM.prototype.validateProformProducts = function () {
  if(this.data.ProformProducts.length > 0) {
    this.validation.ProformProducts = false
    this.validationTranslate.ProformProducts = ''
  }
  else {
    this.validation.ProformProducts = true
    this.validationTranslate.ProformProducts = 'error_required'
    return false
  }

  return true
}