<template>
  <div class="order-planner-btn">

    <DatePickerDefault class="hideDate"
                       @click.native="open = true">
      <template slot="body">
        <date-picker
            class="table-date"
            v-model="date"
            valueType="format"
            @change="selectDate"
            :open="open"
            :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
        >
          <template slot="footer">
            <div class="date-picker__footer d-flex align-items-center">
                  <span class="site-link mr-3"
                        @click="cancelFunc">
                     {{ $t('common_cancel.localization_value.value') }}
                  </span>
              <MainButton
                      :value="$t('common_SaveDate.localization_value.value')"
                      @click.native="datePickerBtn"
              />
            </div>
          </template>

        </date-picker>
      </template>
    </DatePickerDefault>

  </div>

</template>

<script>

  import DatePickerDefault from "../../inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker';
  import MainButton from "../MainButton/MainButton";

  export default {
    name: "OrderPlannerButton",
    components: {
      DatePickerDefault,
      DatePicker,
      MainButton,
    },

    data() {
      return {
        date: this.value,
        open: this.isOpen,
      }
    },

    props: ['value', 'isOpen'],

    watch: {
      value(newVal) {
        this.date = newVal
      },
      isOpen(newVal) {
        this.open = newVal
      },
    },

    methods: {
      selectDate(date) {
        this.$emit('changeDate', date)
      },

      datePickerBtn(){
        this.$emit('datePickerBtn')

        this.open = false
      },

      cancelFunc() {
        this.open = false
        this.date = ''
        this.$emit('cancel')
      },
    }



  }
</script>

<style lang="scss">
  @import "../../../../scss/colors";
  @import "../../../../scss/mixins/mixins";

  .table-date{
    .mx-input{
      border: 0 !important;
      padding: 0 !important;
      padding-right: 10px !important;
      box-shadow: none;
      height: auto !important;
    }

    .mx-icon-calendar{
      display: none;
    }
  }

  .order-planner-btn{
    padding: 0!important;

    &:before{
      display: none!important;
    }
    .content-date__filter-label{
      cursor: pointer;
    }

    &:before{
      top: calc(50% - 6px);
      transform: translateY(-50%);
    }

    .hideDate{
      width: 0 !important;
      overflow: hidden;
    }
  }


</style>
