<template>

  <div class="order-create__row custom-row">
    <div class="order-create__col custom-col custom-col--sm-50 custom-col--wfc"
         v-if="isAdmin"
    >
      <RadioDefault
              :label="'None'"
              name="fedexRadioList23"
              :value="FBM.data.packagingType === ''"
              @input="changePackageType('')"
      />
    </div>
    <div class="order-create__col custom-col custom-col--sm-50 custom-col--wfc d-flex"
         v-for="(item, index) in $store.getters.getPackagingTypes"
         :key="index"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editPopup(item)"></div>
      <RadioDefault
              class="wfc"
              :label="item.currentTranslate.name"
              name="fedexRadioList23"
              :value="checkActiveCheckbox(item)"
              @input="changePackageType(item)"
      />

      <v-popover
              class="site-tooltip site-tooltip--radio-after"
              :disabled="!tooltipAddProductActive"
              offset="5"
              placement="top-left"
              trigger="hover"
      >

        <TooltipBtn/>

        <template slot="popover">
          <div class="box-tooltip">
            <div class="box-tooltip__img">
              <img style="max-width: 56px"
                   v-if="Object.keys(item.file_entity).length" :src="`data:${item.file_entity.mime_type};base64,${item.file_entity.fileContentBase64}`"
                   alt="img">
              <img v-else src="/img/common/box-img.png" alt="img">
            </div>
            <div class="box-tooltip__content">
              <p>
                <b>{{item.currentTranslate.name}}</b>
              </p>
              <p>
                {{item.currentTranslate.description}}
              </p>
            </div>
          </div>

        </template>
      </v-popover>
    </div>

    <PackingTypeEditPopup
            v-if="isActiveEditPopup"
            :item="itemEdit"
            @close="closeEditPopup"
    />

    <!--<div class="order-create__col custom-col custom-col&#45;&#45;sm-50 custom-col&#45;&#45;wfc d-flex">-->
      <!--<RadioDefault-->
              <!--class="wfc"-->
              <!--:label="'Bubble Envelope'"-->
              <!--name="fedexRadioList2"-->
      <!--/>-->

      <!--<v-popover-->
              <!--class="site-tooltip site-tooltip&#45;&#45;radio-after"-->
              <!--:disabled="!tooltipAddProductActive"-->
              <!--offset="5"-->
              <!--placement="top"-->
              <!--trigger="hover"-->

      <!--&gt;-->

        <!--<TooltipBtn/>-->

        <!--<template slot="popover">-->
          <!--<div class="box-tooltip">-->
            <!--<div class="box-tooltip__img">-->
              <!--<img src="/img/common/box-img.png" alt="img">-->
            <!--</div>-->
            <!--<div class="box-tooltip__content">-->
              <!--<p>-->
                <!--<b>Box</b>-->
              <!--</p>-->
              <!--<p>-->
                <!--There package depends on the dimensions of your order. We Show just and example-->
              <!--</p>-->
            <!--</div>-->
          <!--</div>-->

        <!--</template>-->
      <!--</v-popover>-->
    <!--</div>-->
    <!--<div class="order-create__col custom-col custom-col&#45;&#45;sm-50 custom-col&#45;&#45;wfc d-flex">-->
      <!--<RadioDefault-->
              <!--class="wfc"-->
              <!--:label="'Poly Envelope'"-->
              <!--name="fedexRadioList2"-->
      <!--/>-->

      <!--<v-popover-->
              <!--class="site-tooltip site-tooltip&#45;&#45;radio-after"-->
              <!--:disabled="!tooltipAddProductActive"-->
              <!--offset="5"-->
              <!--placement="top"-->
              <!--trigger="hover"-->

      <!--&gt;-->

        <!--<TooltipBtn/>-->

        <!--<template slot="popover">-->
          <!--<p>-->
            <!--<b>Shipping Partner</b>-->
          <!--</p>-->
          <!--<p>-->
            <!--Random text in tooltip for example-->
          <!--</p>-->

        <!--</template>-->
      <!--</v-popover>-->
    <!--</div>-->
    <!--<div class="order-create__col custom-col custom-col&#45;&#45;sm-50 custom-col&#45;&#45;wfc d-flex">-->
      <!--<RadioDefault-->
              <!--class="wfc"-->
              <!--:label="'Gift Envelope'"-->
              <!--name="fedexRadioList2"-->
      <!--/>-->

      <!--<v-popover-->
              <!--class="site-tooltip site-tooltip&#45;&#45;radio-after"-->
              <!--:disabled="!tooltipAddProductActive"-->
              <!--offset="5"-->
              <!--placement="top"-->
              <!--trigger="hover"-->

      <!--&gt;-->

        <!--<TooltipBtn/>-->

        <!--<template slot="popover">-->
          <!--<p>-->
            <!--<b>Shipping Partner</b>-->
          <!--</p>-->
          <!--<p>-->
            <!--Random text in tooltip for example-->
          <!--</p>-->

        <!--</template>-->
      <!--</v-popover>-->
    <!--</div>-->
  </div>
</template>

<script>
  import RadioDefault from "../../../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import TooltipBtn from "../../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import PackingTypeEditPopup
    from "../../../../../../../coreComponents/Popups/PackingTypeEditPopup/PackingTypeEditPopup";
  export default {
    name: "Packaging",
    components: {PackingTypeEditPopup, TooltipBtn, RadioDefault},

    props: {
      FBM: Object,
    },

    data() {
      return {
        tooltipAddProductActive: true,

        isActiveEditPopup: false,
        itemEdit: {}
      }
    },

    mounted() {
      this.$store.dispatch('getDeliveryPackingType').then((response) => {
        let data = this.getRespPaginateData(response)
        if(!this.FBM.data.packagingType?.id){
          this.changePackageType(data[0])
        }
      })
    },

    methods: {
      changePackageType(item) {
        this.FBM.setPackagingType(item)
      },

      checkActiveCheckbox(item) {
        return this.FBM.data.packagingType?.id === item.id
      },

      editPopup(item){
        this.itemEdit = item
        this.isActiveEditPopup = true
      },

      closeEditPopup(){
        this.itemEdit = {}
        this.isActiveEditPopup = false
        this.$store.dispatch('getDeliveryPackingType')
      }

    }

  }
</script>

<style scoped>

</style>
