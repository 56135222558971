<template>

  <div>
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fbm_GiftMessage','fbm_GiftMessageTooltip'])"></div>
      {{$t('fbm_GiftMessage.localization_value.value')}}

      <v-popover
              class="site-tooltip"
              :disabled="!tooltipAddProductActive"
              offset="5"
              placement="top"
              trigger="hover"
      >

        <TooltipBtn/>

        <template slot="popover">
          <p><b>{{$t('fbm_GiftMessage.localization_value.value')}}</b></p>
          <p>{{$t('fbm_GiftMessageTooltip.localization_value.value')}}</p>
        </template>
      </v-popover>
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--75 custom-col--md-100 ">
        <TextareaDefault
                :label="$t('fbm_GiftMessageLabel.localization_value.value')"
                v-bind:class="{'ui-no-valid': FBM.validation.giftMassage}"
                :errorTxt="$t(`${FBM.validationTranslate.giftMassage}.localization_value.value`)"
                :error="FBM.validation.giftMassage"
                v-model="FBM.data.giftMassage"
        />
      </div>
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--sm-50 custom-col--wfc"
           v-if="FBM.data.giftMassage.length === 0"
      >
        <RadioDefault
                :label="'None'"
                name="fedexRadioList2"
                :value="true"
                @input="changeGiftPaper('')"
        />
      </div>

      <!--{{FBM_GIFT_PAPERS}}-->
           <!--v-for="(item, index) in $store.getters.getPackagingTypes"-->
           <!--:key="index"-->
      <template
              v-for="(item, index) in giftPapers">
        <div class="order-create__col custom-col custom-col--sm-50 custom-col--wfc d-flex"
             v-if="hideForNotBlaine(item)"
             :key="index"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
<!--          <div class="admin-edit"-->
<!--               @click="editTranslate([_.find(FBM_GIFT_PAPERS, {name: item.name}).translationName, _.find(FBM_GIFT_PAPERS, {name: item.name}).translation, 'fbmGiftPrice'])"></div>-->

          <RadioDefault
              v-if="_.has(item, 'label')"
                  class="wfc"
                  name="fedexRadioList2"
                  :label="item.label"
                  :value="FBM.data.giftPaper && FBM.data.giftPaper.id === item.id"
                  @input="changeGiftPaper(item)"
          />

          <TooltipTextHelperPlugin
              v-if="_.has(item, 'label')">
            <template slot="text">
              <TooltipBtn/>
            </template>
            <template slot="paragraph">
              <div class="status-tooltip">
                <div class="box-tooltip box-tooltip--papers">
                  <div class="box-tooltip__img box-tooltip__img--papers">
                    <img v-if="item.small_image_file" :src="'data:' + item.small_image_file.mime_type + ';base64,' + item.imageBase64" alt="img">
                    <img v-else src="/img/common/box-birthday-img.png" alt="img">
                  </div>
                  <div class="box-tooltip__content box-tooltip__content--papers">
                    <p>
                      {{item.label}}
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </TooltipTextHelperPlugin>
        </div>
      </template>



      <!--<div class="order-create__col custom-col custom-col&#45;&#45;sm-50 custom-col&#45;&#45;wfc d-flex">-->
        <!--<RadioDefault-->
                <!--class="wfc"-->
                <!--:label="'Anniversary'"-->
                <!--name="fedexRadioList2"-->
        <!--/>-->

        <!--<v-popover-->
                <!--class="site-tooltip site-tooltip&#45;&#45;radio-after"-->
                <!--:disabled="!tooltipAddProductActive"-->
                <!--offset="5"-->
                <!--placement="top"-->
                <!--trigger="hover"-->

        <!--&gt;-->

          <!--<TooltipBtn/>-->

          <!--<template slot="popover">-->
            <!--<div class="box-tooltip">-->
              <!--<div class="box-tooltip__img">-->
                <!--<img src="/img/common/box-img.png" alt="img">-->
              <!--</div>-->
              <!--<div class="box-tooltip__content">-->
                <!--<p>-->
                  <!--<b>Anniversary</b>-->
                <!--</p>-->
                <!--<p>-->
                  <!--There package depends on the dimensions of your order. We Show just and example-->
                <!--</p>-->
              <!--</div>-->
            <!--</div>-->

          <!--</template>-->
        <!--</v-popover>-->
      <!--</div>-->

    </div>
  </div>
</template>

<script>
  import RadioDefault from "../../../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import TooltipBtn from "../../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import TextareaDefault from "../../../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import {FBM_GIFT_PAPERS} from "../../../../../../../../staticData/staticVariables";
  import TooltipTextHelperPlugin from "@/components/coreComponents/TooltipTextHelperPlugin/TooltipTextHelperPlugin";
  import {FBMMixins} from "@/mixins/FBMMixins/FBMMixins";

  export default {
    name: "GiftMassage",
    components: {TooltipTextHelperPlugin, TextareaDefault, TooltipBtn, RadioDefault},

    mixins: [FBMMixins],

    props: {
      FBM: Object,
      giftPapers: Array,
    },

    computed: {
      checkGiftMessage() {
        return this.FBM.data.giftMassage
      }
    },

    watch: {
      checkGiftMessage() {
        if(this.FBM.data.giftMassage.length > 0 && !this.FBM.data.giftPaper?.id) {
          this.changeGiftPaper(this._.first(this.giftPapers))
        }
        if(this.FBM.data.giftMassage.length === 0 && this.FBM.data.giftPaper?.id) {
          this.changeGiftPaper('')
        }
      },
    },

    data() {
      return {
        tooltipAddProductActive: true,
        FBM_GIFT_PAPERS: FBM_GIFT_PAPERS,
      }
    },

    mounted() {
      this.$store.dispatch('getDeliveryPackingType')
    },

    methods: {

      hideForNotBlaine(item) {
        if(this.isWarehouseLodz || this.isWarehouseCambridge) {
          if(
              this.FBM_GIFT_PAPERS.ENGRAVING.id === item.id ||
              this.FBM_GIFT_PAPERS.SANTA_EXPRESS.id === item.id ||
              this.FBM_GIFT_PAPERS.CHRISTMAS_TREE.id === item.id ||
              this.FBM_GIFT_PAPERS.CHRISTMAS_GIFT_TAG.id === item.id
          )
            return false
        }

        return true
      },

      changeGiftPaper(val) {
        // console.log(item);
        this.FBM.setGiftPaper(val)
      },
    }

  }
</script>

<style scoped>
  .box-tooltip__img--papers {
    max-width: 309px;
    height: 294px;
  }

  /*.box-tooltip__content--papers {*/
  /*  width: 100%;*/
  /*}*/

  .box-tooltip--papers {
    flex-direction: column-reverse;
  }
</style>
