<template>
  <div class="fragment pt-4">
    <div class="custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_status'])"></div>
        <DefaultSelect
            :label="$t('fbm_status.localization_value.value')"
            :options="optionsStatus"
            :otherValue="'translation'"
            v-bind:class="{'ui-no-valid': FBM.data.Order.validation.status}"
            :errorTxt="$t(`${FBM.data.Order.validationTranslate.status}.localization_value.value`)"
            :error="FBM.data.Order.validation.status"
            :selected="FBM.data.Order.data.status"
            :disabled="setInProgressStatus"
            @change="(val) => FBM.data.Order.setStatus(val)"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_WasSentAt', ])"></div>
        <DatePickerDefault
            :label="$t('fbm_WasSentAt.localization_value.value')"
            :value="FBM.data.receivedAt"
            :placeholder="true"
            v-bind:class="{'ui-no-valid': FBM.validation.receivedAt}"
            :errorTxt="$t(`${FBM.validationTranslate.receivedAt}.localization_value.value`)"
            :error="FBM.validation.receivedAt"
        >
          <template slot="body">
            <date-picker
                v-model="FBM.data.receivedAt"
                valueType="format"
                :placeholder="'YYYY/MM/DD'"
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :readonly="true"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100 d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_TrackingNumber'])"></div>
        <DefaultInput
            class="w-100"
            :label="$t('fbm_TrackingNumber.localization_value.value')"
            :type="'text'"
            :autocompleteOff="true"
            v-bind:class="{'ui-no-valid': FBM.data.Order.validation.trackingNum}"
            :errorTxt="$t(`${FBM.data.Order.validationTranslate.trackingNum}.localization_value.value`)"
            :error="FBM.data.Order.validation.trackingNum"
            v-model="FBM.data.Order.data.trackingNum"
        />



      </div>

    </div>
  </div>
</template>

<script>
import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
import DatePickerDefault from "@/components/UI/inputs/DatePickerDefault/DatePickerDefault";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import {FBM_STATUSES_ARRAY} from "@/staticData/staticVariables";
import DatePicker from "vue2-datepicker";
import {FBMMixinsHelper} from "@/mixins/FBMMixins/FBMMixins";

export default {
  name: "FBMAdminFields",
  components: {DefaultInput, DatePickerDefault, DefaultSelect, DatePicker},

  mixins: [FBMMixinsHelper],

  props: {
    FBM: Object,
    setInProgressStatus: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      optionsStatus: FBM_STATUSES_ARRAY,
    }
  },

  mounted() {
    if(this.setInProgressStatus) {
      this.FBM.data.Order.setStatus(this._.first(this.optionsStatus))
    }
  },

  methods: {

  },

}
</script>

<style scoped>

</style>